import React from "react";
import classes from "./auth.module.css";
import loginPageImage from "../../Assets/Images/loginBackground.png";
import { List, ListItem, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const DefaultLayout = ({ children }) => {
  const { error, ln } = useSelector((state) => state.userLogin);
  return (
    <div className={classes.loginScreenMainDiv}>
      <div style={{ position: "relative" }} className={classes.left_part}>
        <img
          src={loginPageImage}
          alt="LoginPageImage"
          className={classes.loginPageImage}
        />
        <div className={classes.defaultPageTextContainer}>
          <Typography className={classes.defaultPageHeaderText}>
            {ln && ln["things_you_can_do_with_gazam_account"]
              ? ln["things_you_can_do_with_gazam_account"]
              : "Things you Can Do with Gazam Account"}
          </Typography>
          <ul className={classes.defaultPageList}>
            <li className={classes.defaultListItem}>
              {ln && ln["post_property_for_free_for_one_year"]
                ? ln["post_property_for_free_for_one_year"]
                : "Post Property for FREE for one year"}
            </li>
            <li className={classes.defaultListItem}>
              {ln && ln["set_property_alerts_for_your_requirement"]
                ? ln["set_property_alerts_for_your_requirement"]
                : "Set property alerts for your requirement"}
            </li>
            <li className={classes.defaultListItem}>
              {ln && ln["get_accessed_by_over_1_lakh_buyers"]
                ? ln["get_accessed_by_over_1_lakh_buyers"]
                : "Get accessed by over 1 Lakh buyers"}
            </li>
            <li className={classes.defaultListItem}>
              {ln && ln["showcase_your_property_as_rental_or_for_sale"]
                ? ln["showcase_your_property_as_rental_or_for_sale"]
                : "Showcase your property as Rental or for Sale"}
            </li>
            <li className={classes.defaultListItem}>
              {ln && ln["get_instant_queries_over_phone_email_and_sms"]
                ? ln["get_instant_queries_over_phone_email_and_sms"]
                : "Get instant queries over Phone, Email and SMS"}
            </li>
            <li className={classes.defaultListItem}>
              {ln && ln["performance_in_search_track_responses_views_online"]
                ? ln["performance_in_search_track_responses_views_online"]
                : "Performance in search & Track responses & views online"}
            </li>
            <li className={classes.defaultListItem}>
              {ln &&
              ln[
                "add_detailed_property_information_multiple_photos_per_listing"
              ]
                ? ln[
                    "add_detailed_property_information_multiple_photos_per_listing"
                  ]
                : "Add detailed property information & multiple photos per listing"}
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.loginDefaultLayoutChildContainer}>{children}</div>
    </div>
  );
};

export default DefaultLayout;
