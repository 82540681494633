import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Radio,
  InputAdornment,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import "./popup.css";
import { CloseIconGreen, ContactIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import classes from "../property/property.module.css";
import classesTwo from "./popups.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { addFeedback } from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";
import { country_codes } from "../../Utilities/commonConstants";
import { Numeric } from "../../Utilities/commonFunctions";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

let _feedbacks = [
  "I want to report a problem",
  "I have a suggestion",
  "I want to compliment the app",
  "Other",
];

function AddFieldPop(props) {
  const { open, handleClose,handleChangeFun,selectedField,inputs } = props;

  
  const [input, setInput] = useState("");
  const [fields, setFields] = useState(["5","6","7","8","9"]);


  function handleAdd() {
    if (!["1","2","3","4","5","6","7","8","9"].includes(input)) {
        setFields([...fields,input]);
    } 
    handleChangeFun(selectedField,input);
    setInput("");
  }

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="addfield-pop"
      >
        <Box>
          <Box px={2} className="d-flex-between">
            <Typography
              fontSize={"21px"}
              fontWeight={"600"}
              sx={{ color: "var(--border-colour)" }}
              textTransform={"capitalize"}
            >
              {selectedField?.replaceAll("_"," ")}
            </Typography>
            <Typography
              sx={{ cursor: "pointer" }}
              textAlign={"end"}
              onClick={handleClose}
            >
              <CloseIconGreen />
            </Typography>
          </Box>
            <ul className="add-fields-ul" >
                {
                    fields?.map((datas,i)=>(
                <li>
                <FormControlLabel
                className="d-flex-row-reverse"
                  control={
                    <Checkbox
                      sx={{
                        color: "rgba(0,0,0,0.4)",
                        "&.Mui-checked": {
                          color: "var(--default-background-color) ",
                        },
                      }}
                      name={datas}
                        onChange={()=>handleChangeFun(selectedField,datas)}
                        checked={inputs[selectedField] === datas}
                    />
                  }
                  label={datas}
                />
              </li>
                    ))
                }
            </ul>
            <Box px={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth    
                    id="emailInput"
                    placeholder={`Enter ${selectedField?.replaceAll("_"," ")}`}
                    className={`${classes.formInputFeild} my-field-three`}
                    name="name"
                    value={input}
                    size="normal"
                    onChange={(e)=>setInput(e.target.value)}
                    validators={["required"]} // Add this line
                    errorMessages={["Name is required"]}
                    onKeyPress={(e) => {
                        Numeric(e);
                      }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                <Button disabled={input === ""} className="add-more-btn" size="small" style={{marginRight:"5px"}} onClick={handleAdd} >
                        Add
                      </Button>
                </Grid>
            </Grid>
                  
                </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default AddFieldPop;
