import { Box, Button, Container, Grid, InputAdornment, MenuItem, Typography } from "@mui/material";
import classes from "./others.module.css";
import {
  ContactFbIcon,
  ContactInstaIcon,
  ContactLinkedIcon,
  ContactLocationIcon,
  ContactMailIcon,
  ContactPhoneIcon,
  ContactTwitterIcon,
  WhiteDownArrowIcon,
} from "../../Assets/Icons/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect, useRef, useState } from "react";
import Dropdown from "../Home/Dropdown";
import { addContactUs } from "../../Redux/Actions/userActions";
import AlertBox from "../../Components/AlertBox";
import { country_codes } from "../../Utilities/commonConstants";
import { Numeric } from "../../Utilities/commonFunctions";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

function ConnectWithUs() {
  const [contactSuccess, setContactSuccess] = useState(false);
  const [contactFailure, setContactFailure] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    message: "",
    email: "",
    name: "",
    phone: "",
  });
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const { ln } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);

  const handleKeyPress = (e, ref) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      ref?.current.focus();
    }
  };

  function handleChange(e) {
    const { value, name } = e.target;
    setContactInfo({ ...contactInfo, [name]: value });
  };

  function handleAddContact() {
    if (contactInfo?.phone.length === 0) {
      setIsValid(false);
    }
    else if (!isValid) {
      setIsValid(false);
    } else {
      let body = { ...contactInfo,country_code: countryCode};
      dispatch(addContactUs(body, setContactSuccess,setContactFailure,"form"));
    }
  }

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData.iso2);
    setContactInfo({...contactInfo,phone:""});
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setContactInfo({...contactInfo,phone:value});
  };

  useEffect(() => {
    if (contactSuccess) {
      setContactSuccess(false);
      setContactFailure(false);
      setContactInfo({
        message: "",
        email: "",
        name: "",
        phone: "",
      });
      setCountryCode("91")
    }
  }, [contactSuccess]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <Box bgcolor={"#f8f8f8"} pb={4}>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
         <Dropdown />
      </Box>
      <Container>
        <Box bgcolor={"#fff"} className={classes.connect_us_part}>
          <Typography className={classes.connect_us}>
            Connect with Us
          </Typography>
          <Box py={6} width={"85%"} margin={"auto"}>
            <Grid container>
              <Grid item xs={12} sm={12} md={7}>
                <Box mb={4}>
                  <h5 style={{ fontWeight: "600" }}>Business  Hours:</h5>
                  <Typography mb={4}>
                    Our support team is available to assist you during the
                    following hours:
                  </Typography>
                  <Typography mb={1}>
                    Monday to Friday: 9:00 AM - 6:00 PM (Local Time)
                  </Typography>
                  <Typography mb={1}>
                    Saturday: 10:00 AM - 4:00 PM (Local Time)
                  </Typography>
                  <Typography mb={1}>Sunday: Closed</Typography>
                </Box>
                <Box mb={4}>
                  <h5 style={{ fontWeight: "600" }}>Contact information:</h5>
                  <Typography mb={1} mt={2}>
                    <ContactMailIcon /> support@yourapp.com
                  </Typography>
                  <Typography mb={1}>
                    <ContactPhoneIcon /> +1 (123) 456-7890
                  </Typography>
                  <Typography mb={1}>
                    <ContactLocationIcon /> 123 Main Street, City, State, Zip
                    Code, Country
                  </Typography>
                </Box>
                <Box>
                  <h5 style={{ fontWeight: "600" }}>Social media</h5>
                  <Typography mb={2}>
                    Stay connected with us on social media for updates, tips,
                    and special offers:
                  </Typography>
                  <Typography>
                    <ContactTwitterIcon />
                    <ContactInstaIcon />
                    <ContactFbIcon />
                    <ContactLinkedIcon />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box className={classes.connect_details} mt={{xs:5,sm:5,md:0}} >
                    <Typography className={classes.connect_details_title}>Get in Touch</Typography>
                    <Typography>We are here, How can we help?</Typography>
                    <ValidatorForm
                  useref="form"
                    onSubmit={handleAddContact}
                  className={classes.SignUpForm}
                >
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter your name"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"name"}
                    value={contactInfo.name}
                    size="normal"
                    onChange={handleChange}
                    style={{ margin: "12px 0" }}
                    validators={["required"]} // Add this line
                    errorMessages={["Your name is required"]}
                    inputRef={input1}
                    onKeyPress={(e) => handleKeyPress(e, input2)}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter your email (optional)"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"email"}
                    value={contactInfo.email}
                    size="normal"
                    onChange={handleChange}
                    style={{ margin: "12px 0" }}
                    inputRef={input2}
                    onKeyPress={(e) => handleKeyPress(e, input3)}
                  />
                  {selectedCountry && <div className={`${classes.new_countrycode_field} ${!isValid && classes.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={contactInfo?.phone}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
                   {/* <TextValidator
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Enter your phone"
                  className={`${classes.formInputFeild} my-field-two`}
                  name="phone"
                    value={contactInfo.phone}
                  size="normal"
                  onKeyPress={(e) => {
                    Numeric(e);
                  }}
                  onChange={handleChange}
                  style={{ margin: "12px 0" }}
                  validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                    InputProps={{
                      startAdornment:  ( // Conditionally render input adornment
                        <InputAdornment position="start" style={{width:"80px"}} >
                          <TextValidator
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    select
                                    id="emailInput"
                                    placeholder="Expected by"
                                    className={` country-code`}
                                    name="expected_time_of_possession"
                                    value={countryCode}
                                    size="normal"
                                  >
                                    {country_codes.map((item, index) => {
                                      return (
                                        <MenuItem
                                          value={item}
                                          key={index}
                                          onClick={(e) =>
                                            setCountryCode(item)
                                          }
                                          style={{ textTransform: "capitalize" }}
                                          className={`${classes.code_list} all-codes`}
                                        >
                                          +{item}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextValidator>
                        </InputAdornment>
                      ),
                    }}
                /> */}
                  <TextValidator
                  variant="outlined"
                  multiline
                  rows={2}
                  autoComplete="off"
                  fullWidth
                  id="emailInput"
                  placeholder="Type your message here"
                  className={`${classes.formInputFeild} my-field-two`}
                  name="message"
                    value={contactInfo.message}
                  size="normal"
                  onChange={handleChange}
                  style={{ margin:isValid ? "12px 0" :"28px 0 12px 0" }}
                  validators={["required"]} // Add this line
                  errorMessages={["Details is required"]}
                  inputRef={input3}
                />
                  <Box mt={2}>
                    <Button
                      fullWidth
                      className={classes.register_btn}
                      type="submit"
                    //   onClick={handleContactOwner}
                    //   disabled={!isOwnerChecked}
                    >
                      Submit
                    </Button>
                  </Box>
                </ValidatorForm>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      {contactFailure && (
          <AlertBox type={"error"} message={"Something went wrong. Try again."} stateName={()=>setContactFailure(false)} />
        )}
    </Box>
  );
}

export default ConnectWithUs;
