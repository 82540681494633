import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { ExpandMoreIcon } from "../../Assets/Icons/icons";
import { useState } from "react";

function FaqList({ allFaqs }) {

  const [expanded, setExpanded] = useState("");

  const handleAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Box>
        <Typography fontWeight={"800"} fontSize={"25px"}>
          FAQ
        </Typography>
        {Array.isArray(allFaqs) &&
          allFaqs.map((datas,i) => (
            <Accordion
              expanded={expanded === `panel${i+1}`}
              onChange={handleAccordian(`panel${i+1}`)}
              style={{margin:"10px 0",padding:"0 25px"}}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${i+1}d-content`}
                id={`panel${i+1}d-header`}
                style={{
                  minHeight: "40px",
                  height: "56px",
                  padding: "0px",
                }}
              >
                <Typography>
                 {datas.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                {datas?.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
    </>
  );
}

export default FaqList;
