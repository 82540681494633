import { Box, Container, Grid, Typography } from "@mui/material";
import SingleNewsImg from "../../Assets/Images/single-news.png";
import NewsOne from "../../Assets/Images/news-one.png";
import NewsTwo from "../../Assets/Images/news-two.png";
import NewsThree from "../../Assets/Images/news-three.png";
import NewsFour from "../../Assets/Images/news-four.png";
import NewsFive from "../../Assets/Images/news-five.png";
import NewsSix from "../../Assets/Images/news-six.png";
import NewsSeven from "../../Assets/Images/news-seven.png";
import classes from "./others.module.css";
import { useSelector } from "react-redux";
import { WhiteDownArrowIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Dropdown from "../Home/Dropdown";

let first_news = [
  {
    img: NewsFive,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis.",
  },
  {
    img: NewsSix,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.",
  },
  {
    img: NewsSeven,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  },
  {
    img: NewsFive,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis.",
  },
  {
    img: NewsSix,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.",
  },
  {
    img: NewsSeven,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  },
];

function SingleNews() {
  const { ln } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Box>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
        <Dropdown />
      </Box>
      <Container>
        <Box
          height={{ xs: "150px", sm: "200px", md: "350px" }}
          position={"relative"}
        >
          <img src={SingleNewsImg} alt="image" width={"100%"} height={"100%"} />
        </Box>
        <Box bgcolor={"#f8f8f8"} py={3}>
          <Box className={classes.single_news_details} >
            <Typography>
              Lorem ipsum dolor sit amet consectetur. Quam feugiat nec
              suspendisse lectus amet pretium. Urna etiam adipiscing interdum
              augue amet sagittis. Ullamcorper libero et natoque id non duis
              nunc aliquam sed. Eget nibh orci mattis varius auctor diam
              pellentesque.Lorem ipsum dolor sit amet consectetur. Quam feugiat nec
              suspendisse lectus amet pretium. Urna etiam adipiscing interdum
              augue amet sagittis. Ullamcorper libero et natoque id non duis
              nunc aliquam sed.
            </Typography>
            <br />
            <span>
              Lorem ipsum dolor sit amet consectetur. Elementum nulla id dictum
              dictumst vitae lectus. Sapien id auctor id sed gravida fames eros
              porttitor. Tincidunt fames maecenas massa lacinia in pretium
              neque. Sit ipsum odio non neque feugiat eu condimentum dictum.
              Ultricies sit pharetra aliquam enim pellentesque. Mauris fames
              commodo ullamcorper quis praesent proin et mauris donec. Id
              aliquam scelerisque sapien pellentesque dignissim elementum mi.
              Platea orci felis egestas eget sit.<br /><br /> Non tellus viverra nec sodales
              rhoncus. Porttitor arcu consequat auctor diam congue scelerisque
              dui eget morbi. Non malesuada integer neque nam. Neque rhoncus
              penatibus viverra amet mi at. Purus fringilla gravida sit
              sollicitudin et ac pulvinar elit. Ac nunc risus ac est potenti.
              Cras convallis gravida integer aliquet convallis orci. Sagittis
              molestie ut maecenas diam nisl donec id velit. Rhoncus gravida
              ultricies et malesuada eget leo vitae. Potenti et ultricies vitae
              fusce mauris. Convallis luctus aenean purus urna. <br /><br /> Sapien elementum
              donec neque mauris. Diam id laoreet praesent diam nisi sed sed
              egestas lectus. Senectus viverra in turpis felis. Nulla pharetra
              consectetur rhoncus sed. Feugiat diam nullam tempor lectus.
              Venenatis porttitor mauris lacus cursus ultrices ultrices
              fringilla consequat. Amet mi ut diam morbi orci at. Quisque sit in
              tristique diam dictumst ullamcorper. Arcu volutpat velit mattis
              eros commodo diam faucibus non at. Sagittis est mauris quam in
              curabitur habitasse auctor. Tincidunt dui elementum porta id sed
              netus gravida massa erat. Egestas donec sagittis aliquet mattis
              tortor neque. Et habitant nisl laoreet platea donec volutpat
              scelerisque orci ut. Nisl varius rutrum massa euismod laoreet at
              consequat. Dignissim quam orci pretium id diam cras commodo
              consequat lectus. Ultrices sapien condimentum rhoncus leo sed. Id
              turpis cursus porttitor morbi sagittis.<br /><br /> A at feugiat sed orci
              tellus adipiscing dui massa. Sit pellentesque laoreet eget commodo
              suspendisse. Quis leo suspendisse quam gravida phasellus quis ut
              tempor. Tempus pharetra mattis mattis sed massa. Arcu auctor
              ultrices dictumst et at nam massa.{" "}
            </span>
          </Box>

          <Box mt={4}>
            <h4
              style={{
                fontWeight: "800",
                color: "black",
                marginBottom: "15px",
              }}
            >
              Related News
            </h4>
            <Grid container spacing={2}>
              {first_news.map((datas) => (
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.short_news}>
                    <Box
                      className={`${classes.news_part}`}
                      gap={"15px"}
                    >
                      <img src={datas.img} alt="image" />
                      <Box>
                        <Typography>{datas.title}</Typography>
                        <span>{datas.desc}</span>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default SingleNews;
