import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Container,
} from "@mui/material";
import "./popup.css";
import { ColoredNotificationIcon, ContactIcon } from "../../Assets/Icons/icons";
import NoNotification from "../../Assets/Images/no-notifications.jpg";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoIosRemoveCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateUserNotifications } from "../../Redux/Actions/userActions";

let _dummy = [1];

function NotificationPop(props) {
  const { open, handleClose, notifications, handleUpdateNotification ,handleUserNotificatios} = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDateCalculation = (created) => {
    const createdAt = new Date(created);
    const currentDate = new Date();
    const ONE_DAY = 1000 * 60 * 60 * 24;
    const differenceMs = currentDate - createdAt;
    let days_count = Math.round(differenceMs / ONE_DAY);

    let displayText = "";

    if (days_count === 0) {
      displayText = `Today`;
    } else if (days_count === 1) {
      displayText = "Yesterday";
    } else if (days_count > 1) {
      displayText = `${days_count} days ago`;
    }

    return displayText;
  };

  function handleNotificationClicked(data) {
    dispatch(updateUserNotifications({seen:true},data,handleNotificationNavigate,handleUserNotificatios))
  }

  function handleNotificationNavigate(ids) {
    handleClose();
    navigate(`/singleproperty/${ids?.property_id}`)
  }

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="notification-pop"
      >
        
        <Box
            className="d-flex-between popup-header"
            flexWrap={"wrap"}
          >
            <Typography fontSize={"20px"}>Notifications</Typography>
            <IoIosCloseCircleOutline
              className={"contact-close"}
              onClick={handleClose}
            />
          </Box>
          <Box p={3}>
            {Array.isArray(notifications) && notifications.length > 0 ? (
              notifications.map((datas) => (
                <Box
                  className={`notification-box ${!datas?.seen && "notification-active"} `}
                  
                >
                  <Typography>
                    <ColoredNotificationIcon />
                  </Typography>
                  <Box onClick={()=>{
                      handleNotificationClicked(datas)
                  }} >
                    <Typography>
                      <b>{datas.subject}</b>
                    </Typography>
                    <span>{datas.message}</span>
                  </Box>
                  <Typography className="notification-time">
                    {handleDateCalculation(datas.createdAt)}
                  </Typography>
                  <IoIosRemoveCircle onClick={() => handleUpdateNotification(datas._id)} className="hover-onclose" />
                </Box>
              ))
            ) : (
             <Box textAlign={"center"}>
              <Box width={{xs:"100%",sm:"70%",md:"40%"}} height={"200px"} margin={"auto"}>
              <img src={NoNotification} alt="image" width={"100%"} height={"100%"} style={{opacity:"0.7"}} />
                 </Box>
               <Typography fontSize={"20px"} fontWeight={"600"} textAlign={"center"}>
                No notification
              </Typography>
              <span>
                When you have notification <br />
                You'll see them here
              </span>
              </Box>
            )}
          </Box>
      </Dialog>
    </>
  );
}

export default NotificationPop;
