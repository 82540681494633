import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { BackIcon, WhatsAppIcon } from "../../Assets/Icons/icons";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { generateUniqueId, getDecryptedCookie, Numeric } from "../../Utilities/commonFunctions";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../Redux/Actions/userActions";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function PropertyRegister() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const RegisterDiv = () => {
    const [inputs, setInputs] = useState({
      name: "",
      email: "",
      phone: "",
    });
    const [isChecked, setIsChecked] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [countryCode, setCountryCode] = useState("91");
    const [selectedCountry, setSelectedCountry] = useState("in");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function handleNext() {
      if (inputs?.phone.length === 0) {
        setIsValid(false);
      }  else if (!isValid) {
        setIsValid(false);
      } else{
        const decryptedData = getDecryptedCookie('device-infos', process.env.REACT_APP_DEVICE_KEY);

        if (decryptedData?.phone == inputs.phone) {
          let body = {
            phone: inputs.phone,
            name: inputs.name,
            email: inputs.email,
            country_code: countryCode,
            device_id:decryptedData?.device_id
          };
          dispatch(registerUser(body,navigate));
        } else {
          let new_deviceId = await generateUniqueId();
          let body = {
            phone: inputs.phone,
            name: inputs.name,
            email: inputs.email,
            country_code: countryCode,
            device_id:new_deviceId
          };
          dispatch(registerUser(body,navigate));
        }


      }
    };

    const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
      setSelectedCountry(countryData.iso2);
      setInputs({...inputs,phone:""});
    }
  
    const handlePhoneNumberChange = (isValidNum, value, country) => {
      setIsValid(isValidNum);
      setCountryCode(country?.dialCode);
      setSelectedCountry(country?.iso2);
      setInputs({...inputs,phone:value});
    };

    function handleChange(e) {
      const { name, value } = e.target;
      if (name === "phone") {
        setInputs({ ...inputs, [name]: value.slice(0, 10) });
      } else {
        setInputs({ ...inputs, [name]: value });
      }
    }

    function handleCheckBox(e) {
      const { checked } = e.target;
      if (checked) {
        setIsChecked(true);
      } else {
        setIsChecked(false);
      }
    }

    function handleFilled() {
      let data_filled = false;

      if (
        inputs.name === "" ||
        inputs.email === "" ||
        inputs.phone.length != 10
      ) {
        data_filled = true;
      } else {
        data_filled = false;
      }

      return data_filled;
    }

    return (
      <>
        <Box className={classes.register_box}>
          <Typography className={classes.role_box_header} mb={"15px"}>
            <span onClick={() => navigate(-1)}>
              <BackIcon />
            </span>
          </Typography>
          <Typography className={classes.role_box_header_register}>
            Looks like you are new to GAZAM, Continue by creating a new
            account....
          </Typography>
          <Box mt={3}>
            <ValidatorForm
              useref="form"
              onSubmit={handleNext}
              className={classes.SignUpForm}
            >
              <TextValidator
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Your full name"
                className={`${classes.formInputFeild} my-field`}
                name={"name"}
                value={inputs.name}
                size="normal"
                onChange={handleChange}
                style={{ margin: "8px 0" }}
                validators={["required"]} // Add this line
                errorMessages={["Your name is required"]}
              />
              <TextValidator
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Email address"
                className={`${classes.formInputFeild} my-field`}
                name={"email"}
                value={inputs.email}
                size="normal"
                onChange={handleChange}
                style={{ margin: "8px 0" }}
                validators={["required", "isEmail"]} // Add this line
                errorMessages={["Email is required", "Enter Valid email ID"]}
              />
              {/* <TextValidator
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Mobile number"
                className={`${classes.formInputFeild} my-field`}
                name={"phone"}
                value={inputs.phone}
                size="normal"
                onChange={handleChange}
                style={{ margin: "8px 0" }}
                onKeyPress={(e) => {
                  Numeric(e);
                }}
                validators={["required", "matchRegexp:[0-9]{10}"]} 
                errorMessages={[
                  "Mobile Number is required",
                  "Enter 10 digit valid Mobile number",
                ]}
              /> */}
              {selectedCountry && <div className={`${classes.new_countrycode_field} ${!isValid && classes.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={inputs?.phone}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
              <Box display={"flex"} alignItems={"start"} mt={1}>
                <Checkbox
                  {...label}
                  onChange={handleCheckBox}
                  checked={isChecked}
                  className={classes.checkbox}
                />
                <Typography className={classes.checkbox_content}>
                  I agree to be contacted by GAZAM and others for similar or
                  related services via <WhatsAppIcon />
                  Whatsapp, phone sms, e-mail, etc. I can put out at anytime.
                </Typography>
              </Box>
              <Box mt={6}>
                <Typography sx={{ color: "var(--secondary-text-color)" }}>
                  By clicking bellow you agreed the <b>Terms & Condition</b>{" "}
                </Typography>
                <Button
                  fullWidth
                  className={classes.register_btn}
                  type="submit"
                  disabled={!isChecked}
                >
                  Register
                </Button>
                <Typography sx={{ color: "var(--secondary-text-color)" }} mt={1}>
                Already Have an Account? <b style={{cursor:"pointer"}} onClick={()=>navigate("/login")} >Login</b>{" "}
                </Typography>
              </Box>
            </ValidatorForm>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<RegisterDiv />} stepper={0} />
    </>
  );
}

export default PropertyRegister;
