import { useDispatch, useSelector } from "react-redux";
import { LiteGreenCheck } from "../../Assets/Icons/icons";
import PropertyHeader from "./PropertyHeader";
import classes from "./property.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import { clearPropertyData } from "../../Redux/Actions/userActions";
import { useEffect } from "react";

function PropertyCreated() {
  const dispatch = useDispatch();
  const { propertyData,ln } = useSelector((state) => state.userLogin);

  useEffect(() => {
    sessionStorage.clear();
    dispatch(clearPropertyData());
  }, []);

  return (
    <Box>
      <PropertyHeader />
      <Container>
        <Box className={classes.created_box}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6}>
              <Box display={"flex"} alignItems={"start"} gap={"20px"}>
                <LiteGreenCheck />
                <Box marginTop={"-6px"}>
                  <Typography>
                    {" "}
                    {ln && ln["congratulations"]
                      ? ln["congratulations"]
                      : "Congratulations"}{" "}
                  </Typography>
                  <span>
                    {ln &&
                    ln[
                      "you_have_successfully_posted_your_property_it_will_be_live_with_in_12_hrs"
                    ]
                      ? ln[
                          "you_have_successfully_posted_your_property_it_will_be_live_with_in_12_hrs"
                        ]
                      : "You have successfully posted your property.It will be live with in 12 hrs"}
                  </span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3}></Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Link to="/alllistings">
                <Button>
                  {ln && ln["edit_listing"]
                    ? ln["edit_listing"]
                    : "Edit listing"}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Typography className={classes.property_plan}>Free Plan</Typography>
        </Box>
      </Container>
    </Box>
  );
}
 
export default PropertyCreated;
