import {
  BackIcon,
  DeleteIconBlack,
  WhatsAppIcon,
} from "../../Assets/Icons/icons";
import AddImage from "../../Assets/Images/add-image.png";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import classes1 from "../Auth/auth.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Radio,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  addPhoto,
  createDraftProperty,
  createProperty,
  errorAlertAction,
  postPropertyDetails,
  updateProperty,
} from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";

let tab_fields = [
  { name: "Exterior View", val: "exterior_val" },
  { name: "Living Room", val: "living_room" },
  { name: "Bedroom", val: "bedrooms" },
  { name: "Bathroom", val: "bathrooms" },
  { name: "Kitchen", val: "kitchen" },
];

function PropertyUploadedImgs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const UploadedPhotosDiv = () => {
    const [isWatsApp, setIsWatsApp] = useState(false);
    const [isUploadLater, setIsUploadLater] = useState(false);
    // const [coverImg, setCoverImg] = useState(false);
    const [tabValue, setTabValue] = useState(0);
    const [myPhotos, setMyPhotos] = useState([1, 2, 3, 4, 5]);
    const [images, setImages] = useState([]);
    const [payloadImgs, setPayloadImgs] = useState([]);
    const [existingImgs, setExistingImgs] = useState([]);
    const [videos, setVideos] = useState([]);
    const [uploadedImgs, setUploadedImgs] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    let _edit = sessionStorage.getItem("edit");
    const { propertyData, ln } = useSelector((state) => state.userLogin);
    let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
    let _docs = JSON.parse(sessionStorage.getItem("converted_doc_imgs"));
    let my_res = JSON.parse(sessionStorage.getItem("property_info"));
    let coverImg = sessionStorage.getItem("coverImg");

    console.log("isUploadLater =",isUploadLater)
    console.log("isWatsApp =",isWatsApp)

    function handleDelete(i, field, image_status) {
      
      if (field === "existing") {
        let new_images = [...existingImgs];
        new_images.splice(i, 1);
        setExistingImgs(new_images);
      } else if (field === "video") {
        let new_videos = [...videos];
        new_videos.splice(i, 1);
        setVideos(new_videos);
      } else {
        let new_images = [...images];
        let new_payloadImgs = [...payloadImgs];
        new_images.splice(i, 1);
        new_payloadImgs.splice(i, 1);
        setImages(new_images);
        setPayloadImgs(new_payloadImgs);
      }

      if (image_status) {
        // setCoverImg(false);
        sessionStorage.removeItem("coverImg")
      }
    }

    function handleCoverImage(index, tabs) {
      // setCoverImg(true);
      sessionStorage.setItem("coverImg",true);
      if (tabs === "existing") {
        let current_images = [...images];
        let existing_images = [...existingImgs];
        current_images.forEach((val) => {
          val.is_cover_image = false;
        });
        existing_images.forEach((val) => {
          val.is_cover_image = false;
        });
        existing_images[index].is_cover_image = true;
        setImages(current_images);
        setExistingImgs(existing_images);
      } else {
        let current_images = [...images];
        let existing_images = [...existingImgs];
        current_images.forEach((val) => {
          val.is_cover_image = false;
        });
        current_images[index].is_cover_image = true;

        existing_images.forEach((val) => {
          val.is_cover_image = false;
        });
        
        setImages(current_images);
        setExistingImgs(existing_images);
      }
    }

    function handleBack() {
      const body = {
        property_images: {
          previews: images,
          payload: payloadImgs,
          existingImgs: existingImgs,
          videos: videos,
        },
      };

      let new_body = {
        photos: existingImgs,
      };

      let my_res = JSON.parse(sessionStorage.getItem("property_info"));

      sessionStorage.setItem(
        "property_info",
        // JSON.stringify({ ...my_res, ...body })
        JSON.stringify({ ...my_res, ...new_body })
      );
      let route = "/propertyadddetails";
      dispatch(postPropertyDetails(body, navigate, route));
    }

    function handleNext() {
      let _total = images.length + existingImgs.length;
    
      if (_total < 1 && !isUploadLater) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Add atleast 1 photo"));
      } else if (!coverImg && !isUploadLater) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select cover image"));
      } else {
        const body = {
          property_images: {
            previews: images,
            payload: payloadImgs,
            existingImgs: existingImgs,
            videos: videos,
          },
          update_image_later:isUploadLater,
        };

        let new_body = {
          photos: existingImgs,
          update_image_later:isUploadLater
        };

        
        let my_res = JSON.parse(sessionStorage.getItem("property_info"));

        sessionStorage.setItem(
          "property_info",
          // JSON.stringify({ ...my_res, ...body })
          JSON.stringify({ ...my_res ,...new_body})
        );
        let route = "/propertypricingdetails";
        dispatch(postPropertyDetails(body, navigate, route));
      }
    }

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    function handleDraft() {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let my_selected_imgs = images;
      let my_selected_videos = videos;
      let fresh_arr = [...my_selected_imgs, ...my_selected_videos];

      // property_images: {
      //   previews: images,
      //   payload: payloadImgs,
      //   existingImgs: existingImgs,
      //   videos: videos,
      // },

      function dataURLtoFile(dataURL, filename) {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }

      const convertImages = fresh_arr?.map((image) => {
        let up_file = dataURLtoFile(image.image, "image.jpg");
        let my_file = new FormData();
        my_file.append("file", up_file);
        return dispatch(
          addPhoto(
            my_file,
            setUploadedImgs,
            uploadedImgs,
            image.type,
            image.is_cover_image
          )
        );
      });

      Promise.all(convertImages)
        .then(() => {
          handleSaveDraft();
        })
        .catch((error) => {
          console.error("Error uploading images:", error);
        });
    }

    function handleSaveDraft() {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let actual_imgs = sessionStorage.getItem("converted_imgs");

      let body = {
        ...my_res,
        update_image_later:isUploadLater
      };

      if (body["expected_time_of_possession"] === "-1") {
        body["expected_time_of_possession"] = "";
      }

      let newly_converted = JSON.parse(actual_imgs);
      let old_converted = existingImgs;
      if (newly_converted) {
        body["photos"] = [...old_converted, ...newly_converted];
      } else {
        body["photos"] = [...old_converted];
      }

      delete body.property_images;

      let split_photos = body["photos"];

      let splited_photos = [];
      let splited_videos = [];
      split_photos.forEach((val) => {
        if (val.mime_type?.includes("image")) {
          splited_photos.push(val);
        } else {
          let video_val = val;
          video_val["video_name"] = val.image;
          delete val.image;
          splited_videos.push(video_val);
        }
      });
      body["photos"] = splited_photos;
      body["video"] = splited_videos;

      if (body["user_type"] === "661cc2f5267fb2f57d21fb8d") {
        body["owner_profile"] = _docs?.photo
          ? _docs?.photo
          : body["owner_profile"];
        body["owner_document"] = _docs?.doc
          ? _docs?.doc
          : body["owner_document"];
      }

      body["property_status"] = "Drafted";

      if (_edit) {
        delete body._id;
        delete body.__v;
        dispatch(updateProperty(body, _token, navigate, my_res?._id));
      } else {
        dispatch(createDraftProperty(body, _token, navigate));
      }
    }

    function handleImage(e) {
      let my_image = e?.target?.files[0];
      let file_name = my_image?.name?.split(".")[1].toLowerCase();

      if (my_image?.type.includes("video")) {
        if (my_image?.size < 10000000) {
          const reader = new FileReader();
          reader.onload = () => {
            let obj2 = {
              image: reader.result,
              type: tab_fields[tabValue].name,
            };
            setVideos([...videos, obj2]);
          };
          reader.readAsDataURL(my_image);
        } else {
          localStorage.setItem("error", "2");
          dispatch(errorAlertAction("Please upload video with 10 mb"));
        }
      } else {
        if (
          ["png", "jpg", "jpeg", "mp4"].includes(file_name) &&
          my_image?.size < 10000000
        ) {
          let obj = {
            image: my_image,
            type: tab_fields[tabValue].name,
          };
          setPayloadImgs([...payloadImgs, obj]);
          // Preview the selected image
          const reader = new FileReader();
          reader.onload = () => {
            let obj2 = {
              image: reader.result,
              type: tab_fields[tabValue].name,
              is_cover_image: false,
            };
            if (images.length === 0 && existingImgs.length === 0) {
              obj2["is_cover_image"] = true;
              // setCoverImg(true);
              sessionStorage.setItem("coverImg",true)
            }
            setImages([...images, obj2]);
          };
          reader.readAsDataURL(my_image);
          let my_file = new FormData();
          my_file.append("file", my_image);
          // dispatch(addPhoto(my_file))
        } else {
          localStorage.setItem("error", "2");
          dispatch(
            errorAlertAction(
              "File accepts only jpg, jpeg and png with max 10mb"
            )
          );
        }
      }
    }

    function handleImage2(e) {
      let my_image = e?.target?.files[0];
      let my_image_2 = e?.target?.files;
      let demoImage = [];
      let demoPayload = [];
      let a = false;
      let promises = [];

      Object.keys(my_image_2).forEach((val) => {
        let file_name = my_image_2[val]?.name?.split(".")[1].toLowerCase();
        if (
          ["png", "jpg", "jpeg", "mp4"].includes(file_name) &&
          my_image_2[val]?.size < 10000000
        ) {
          let obj = {
            image: my_image_2[val],
            type: tab_fields[tabValue].name,
          };
          demoPayload.push(obj);

          const promise = new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              let obj2 = {
                image: reader.result,
                type: tab_fields[tabValue].name,
              };

              demoImage.push(obj2); // Push into demoImage array
              resolve(); // Resolve the promise after pushing data
            };
            reader.readAsDataURL(my_image_2[val]);
          });

          promises.push(promise);
        }
      });

      Promise.all(promises).then(() => {
        setImages([...images, ...demoImage]);
      });

      setPayloadImgs([...payloadImgs, ...demoPayload]);
    }

    useEffect(() => {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));

      if (propertyData) {
        setImages(propertyData?.property_images?.previews ?? []);
        setPayloadImgs(propertyData?.property_images?.payload ?? []);
        setVideos(propertyData?.property_images?.videos ?? []);
      }
      if (my_res) {
        let my_photos = my_res?.photos ?? [];
        let my_videos = my_res?.video ?? [];
        let all_images = [...my_photos, ...my_videos];

        let removed_dup = [];

        let final_res = all_images?.reduce((acc,el)=>{
          if (el.mime_type?.includes("video")) {
            let a = acc.find((li)=>li.video_name === el.video_name );
            if (!a) {
              acc.push(el)
            }
          } else {
            acc.push(el)
          }


          return acc;
        },removed_dup);

       
        setExistingImgs(final_res ?? []);
        setIsUploadLater(my_res?.update_image_later ?? false)
      }

      if (_edit) {
        // setCoverImg(true);
        sessionStorage.setItem("coverImg",true);
      }
    }, []);

    return (
      <>
        <Box className={classes.photos_box}>
          <Typography className={classes.role_box_header} mb={"25px"}>
            <span onClick={handleBack}>
              <BackIcon />
            </span>
            {_edit
              ? ln && ln["edit"]
                ? ln["edit"]
                : "Edit"
              : ln && ln["add"]
              ? ln["add"]
              : "Add"}{" "}
          Property photos and videos
          </Typography>
          <Box>
            <Typography className={classes.my_role}>
            Property photos and videos
            </Typography>
            <Typography
              bgcolor={"#c6ee90"}
              padding={"5px 10px"}
              borderRadius={"4px"}
            >
              <b>Note: </b>
              Properties with relevant images and videos tend to get more views
              and enquiries from potential buyers
            </Typography>
            <Box className="single-property-tabs" mb={2}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="wrapped label tabs example"
              >
                {tab_fields.map((datas, i) => (
                  <Tab value={i} label={datas.name} />
                ))}
              </Tabs>
            </Box>
            {images.length > 0 ||
            (Array.isArray(existingImgs) && existingImgs.length > 0) ||
            videos.length > 0 ? (
              <Grid container spacing={2}>
                {existingImgs.map(
                  (datas, i) =>
                    datas.type === tab_fields[tabValue].name && (
                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.upload_images}>
                          {datas.mime_type?.split("/")[1]?.toLowerCase() ===
                          "mp4" ? (
                            <>
                              <video
                                src={`${process.env.REACT_APP_API_URL}/${datas.video_name}`}
                                controls
                                width={"100%"}
                                height={"55%"}
                              ></video>
                              <span
                                onClick={() =>
                                  handleDelete(i, "existing", false)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <DeleteIconBlack />
                              </span>
                            </>
                          ) : (
                            <img
                              src={`${process.env.REACT_APP_API_URL}/${datas.image}`}
                              alt="building"
                              width={"100%"}
                              height={"100%"}
                            />
                          )}
                          {datas.mime_type?.split("/")[1]?.toLowerCase() !==
                            "mp4" && (
                            <>
                              <Box
                                className={`${classes.delete_part} delete-part`}
                                // onClick={() => handleCoverImage(i, "existing")}
                              >
                                <Radio
                                  style={{
                                    position: "absolute",
                                    top: "4px",
                                    left: "5px",
                                  }}
                                  onChange={() =>
                                    handleCoverImage(i, "existing")
                                  }
                                  checked={datas?.is_cover_image}
                                />
                                {datas?.is_cover_image && (
                                  <Typography className={classes.cover_image}>
                                    Cover Image
                                  </Typography>
                                )}
                                <Typography
                                  onClick={() =>
                                    handleDelete(
                                      i,
                                      "existing",
                                      datas?.is_cover_image
                                    )
                                  }
                                >
                                  <DeleteIconBlack />
                                </Typography>
                              </Box>
                              {/* {datas?.is_cover_image && <Typography className={classes.cover_image}>Cover Image</Typography>} */}
                            </>
                          )}
                        </Box>
                      </Grid>
                    )
                )}
                {images.map(
                  (datas, i) =>
                    datas.type === tab_fields[tabValue].name && (
                      <Grid item xs={12} sm={12} md={6}>
                        <Box className={classes.upload_images}>
                          <img
                            src={datas.image}
                            width={"100%"}
                            height={"100%"}
                          />
                          <Box
                            className={`${classes.delete_part} delete-part`}
                            // onClick={() => handleCoverImage(i, "images")}
                          >
                            <Radio
                              style={{
                                position: "absolute",
                                top: "4px",
                                left: "5px",
                              }}
                              onChange={() => handleCoverImage(i, "images")}
                              checked={datas?.is_cover_image}
                            />
                            {datas?.is_cover_image && (
                              <Typography className={classes.cover_image}>
                                Cover Image
                              </Typography>
                            )}
                            <Typography
                              onClick={() =>
                                handleDelete(i, "new", datas?.is_cover_image)
                              }
                            >
                              <DeleteIconBlack />
                            </Typography>
                            {/* {datas?.is_cover_image && <Typography className={classes.cover_image}>Cover Image</Typography>} */}
                          </Box>
                        </Box>
                      </Grid>
                    )
                )}
                {videos.map(
                  (datas, i) =>
                    datas.type === tab_fields[tabValue].name && (
                      <Grid item xs={12} sm={12} md={6}>
                        <video
                          src={datas.image}
                          width={"100%"}
                          controls
                        ></video>
                        <Typography
                          onClick={() => handleDelete(i, "video", false)}
                          sx={{ cursor: "pointer" }}
                        >
                          <DeleteIconBlack />
                        </Typography>
                      </Grid>
                    )
                )}
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.upload_photo_wrapper}>
                    <img src={AddImage} alt="AddImage" width={"50px"} />
                    <Typography
                      sx={{ color: "var(--border-colour)", margin: "4px 0" }}
                    >
                      Add more photos
                    </Typography>
                    <Typography
                      className={classes.SecondsText}
                      textAlign={"center"}
                      mb={1}
                    >
                      {ln && ln["upload_photos_with_format"]
                        ? ln["upload_photos_with_format"]
                        : "Upload 50 Photos with max size 10 mb in format jpg, jpeg and png"}
                    </Typography>
                    <input
                      type="file"
                      id="actual-btn"
                      onChange={(e) => handleImage(e)}
                      hidden
                      multiple={true}
                    />
                    <label for="actual-btn" className={classes.upload_btn}>
                      {ln && ln["choose_file"]
                        ? ln["choose_file"]
                        : "Choose File"}
                    </label>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Container>
                <Box className={classes.add_photo_wrapper}>
                  <img src={AddImage} alt="AddImage" />
                  <Typography
                    sx={{ color: "var(--border-colour)", margin: "7px 0" }}
                  >
                    +{" "}
                    {ln && ln["add_atleast_photos"]
                      ? ln["add_atleast_photos"]
                      : "Add atleast 5 photos"}
                  </Typography>
                  <Typography
                    className={classes1.SecondsText}
                    textAlign={"center"}
                    mb={4}
                  >
                    {ln && ln["add_atleast_photos"]
                      ? ln["add_atleast_photos"]
                      : "click from camera or browse to upload"}{" "}
                    <br />
                    {ln && ln["upload_photos_with_format"]
                      ? ln["upload_photos_with_format"]
                      : "Upload 50 Photos with max size 10 mb in format jpg, jpeg and png"}
                  </Typography>
                  <input
                    type="file"
                    id="actual-btn"
                    onChange={(e) => handleImage(e)}
                    hidden
                    multiple={true}
                  />
                  <label for="actual-btn" className={classes.upload_btn}>
                    {ln && ln["choose_file"]
                      ? ln["choose_file"]
                      : "Choose File"}
                  </label>
                  {/* <Button  >Upload Photos</Button> */}
                </Box>
                <Box className={classes.radio_wrapper}>
                  <Radio
                    checked={isUploadLater && !isWatsApp}
                    onClick={() => {
                      setIsWatsApp(false)
                      setIsUploadLater(!isUploadLater)
                    }}
                    //   value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                    sx={{
                      color: '#093F40',
                      '&.Mui-checked': {
                        color: '#093F40',
                      },
                    }}
                  />
                    I will upload Image later
                </Box>
                <Box className={classes.radio_wrapper}>
                  <Radio
                    checked={isWatsApp && !isUploadLater }
                    onClick={() =>{
                      setIsUploadLater(false);
                      setIsWatsApp(!isWatsApp)
                    }}
                    //   value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                    sx={{
                      color: '#093F40',
                      '&.Mui-checked': {
                        color: '#093F40',
                      },
                    }}
                  />
                  {ln && ln["choose_files"]
                    ? ln["choose_file"]
                    : "I will send photo over whatsapp"}{" "}
                  <WhatsAppIcon />
                </Box>
              </Container>
            )}

            <Box marginTop="40px" className="d-flex" gap="20px">
              {my_res?.property_status === "Drafted" && (
                <Button
                  fullWidth
                  className={classes.draft_btn}
                  onClick={handleDraft}
                >
                  {ln && ln["save_as_draft"]
                    ? ln["save_as_draft"]
                    : "Save as draft"}
                </Button>
              )}
              <Button
                fullWidth
                className={classes.continue}
                onClick={handleNext}
              >
                {ln && ln["next"] ? ln["next"] : "Next"}
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<UploadedPhotosDiv />} stepper={2} />
    </>
  );
}

export default PropertyUploadedImgs;
