import { styled } from "@emotion/react";
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

export const Numeric = (event) => {
  var x = event.charCode || event.keyCode;
  if (x > 47 && x < 58) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};
export const Alphabetic = (event) => {
  var x = event.charCode || event.keyCode;
  if (
    (x > 96 && x < 123) ||
    (x > 64 && x < 91) ||
    x === 32 ||
    x === 46 || // dot "."
    x === 45
  ) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const Convertdate = (my_date) => {
  const date = new Date(my_date);

  const options = { month: "long", day: "2-digit", year: "numeric" };
  const formattedDate = date?.toLocaleString("en-US", options);

  return formattedDate;
};

export const formatNumber = (num) => {
  if (num >= 10000000) {
    return (num / 10000000).toFixed(2) + " Cr";
  }
  if (num >= 100000) {
    return (num / 100000).toFixed(2) + " lakh";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(2) + " K";
  }
  return num;
};

export const countDaysFromDateToNow = (dateString) => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifference = currentDate?.getTime() - givenDate?.getTime();

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
};

export const splitFirstLetter = (name) => {
  let new_name = name
    ?.split(" ")
    .map((val) => val?.at(0)?.toUpperCase())
    .join("");
  return new_name;
};

export const convertPhone = (num) => {
  let my_num = num?.slice(6);
  let result = my_num?.padStart(10, "*");
  return result;
};

export const numberWithCommas = (number) => {
  let converted = number?.toString();
  // return converted?.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");


  let reversedString = converted?.split("")?.reverse();
  let storingNewVal = "";
  let commanInsert = 2;
  reversedString?.forEach((val, i) => {
    storingNewVal += val;
    if (commanInsert === i && (i < ( reversedString.length - 1))) {
      commanInsert += 2;
      storingNewVal += ",";
    } else {
    }
  });
  let result = storingNewVal?.split("")?.reverse()?.join("");
  return result;
};


export const isValidEmail = (email)=> {
  const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
}

export const generateUniqueId = ()=> {
  const prefix = 'gu';
  let digits = '';

  for (let i = 0; i < 10; i++) {
    digits += Math.floor(Math.random() * 10);
  }

  const uniqueId = prefix + digits;

  return uniqueId;
}

// Function to encrypt the data
export const encryptData = (data, secretKey) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

// Function to set the cookie
export const setEncryptedCookie = (name, data, secretKey) => {
  const encryptedData = encryptData(data, secretKey);
  const expires = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  Cookies.set(name, encryptedData, { expires });
};

// Function to decrypt the data
export const decryptData = (encryptedData, secretKey) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Function to get the cookie and decrypt it
export const getDecryptedCookie = (name, secretKey) => {
  const encryptedData = Cookies.get(name);
  if (encryptedData) {
    return decryptData(encryptedData, secretKey);
  }
  return null;
};

export const compareDatesWithin24Hours = (date1)=> {
  const d1 = new Date(date1);
  const d2 = new Date();

  const diffInHours = Math.abs((d2 - d1) / (1000 * 60 * 60));

  if (diffInHours <= 24) {
    return true; 
  } else {
    return false; 
  }
}