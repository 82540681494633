import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

function SnackBarPop(props) {
  const { open, handleClose, snackMsg } = props;
  const [snackBar, setSnackBar] = useState({
    vertical: "top",
    horizontal: "right",
  });

  const { vertical, horizontal } = snackBar;
  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleClose}
          severity="success"
          style={{backgroundColor:"#4eb52c"}}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackMsg}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SnackBarPop;
