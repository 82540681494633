import { Box, Container, Grid, Typography } from "@mui/material";
import News from "../../Assets/Images/news.png";
import NewsOne from "../../Assets/Images/news-one.png";
import NewsTwo from "../../Assets/Images/news-two.png";
import NewsThree from "../../Assets/Images/news-three.png";
import NewsFour from "../../Assets/Images/news-four.png";
import NewsFive from "../../Assets/Images/news-five.png";
import NewsSix from "../../Assets/Images/news-six.png";
import NewsSeven from "../../Assets/Images/news-seven.png";
import classes from "./others.module.css";
import { useSelector } from "react-redux";
import { WhiteDownArrowIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Dropdown from "../Home/Dropdown";

let first_news = [
  {
    img: NewsOne,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Proin sit amet felis a mi fermentum fermentum. Vivamus congue enim justo, non fermentum lectus tincidunt ac. Suspendisse potenti...",
  },
  {
    img: NewsTwo,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Proin sit amet felis a mi fermentum fermentum. Vivamus congue enim justo, non fermentum lectus tincidunt ac. Suspendisse potenti...",
  },
  {
    img: NewsThree,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Proin sit amet felis a mi fermentum fermentum. Vivamus congue enim justo, non fermentum lectus tincidunt ac. Suspendisse potenti...",
  },
];

let second_news = [
  {
    img: NewsFive,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Proin sit amet felis a mi fermentum fermentum.",
  },
  {
    img: NewsSix,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Proin",
  },
  {
    img: NewsSeven,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  },
];

let third_news = [
  {
    img: NewsFive,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis.",
  },
  {
    img: NewsSix,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.",
  },
  {
    img: NewsSeven,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  },
  {
    img: NewsFive,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis.",
  },
  {
    img: NewsSix,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames.",
  },
  {
    img: NewsSeven,
    title:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci .",
    desc: "Duis hendrerit vitae velit eget cursus. In hac habitasse platea dictumst. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
  },
];

function NewsPage() {
  const { ln } = useSelector((state) => state.userLogin);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Box>
      <Box
        className={classes.sub_menus}
        display={{ xs: "none", sm: "flex", md: "flex" }}
      >
         <Dropdown />
      </Box>
      <Box
        height={{ xs: "150px", sm: "200px", md: "300px" }}
        position={"relative"}
      >
        <img
          src={News}
          alt="image"
          width={"100%"}
          height={"100%"}
          style={{ filter: "brightness(0.3)" }}
        />
        <Typography className={classes.news_art}>News & Articles</Typography>
      </Box>
      <Box bgcolor={"#f8f8f8"} py={3}>
        <Container>
          <Box>
            <h4
              style={{
                fontWeight: "800",
                color: "black",
                marginBottom: "15px",
              }}
            >
              Latest News
            </h4>
            <Grid container spacing={2}>
              {first_news.map((datas) => (
                <Grid item xs={12} sm={12} md={4}>
                  <Box className={classes.news_part}>
                    <img src={datas.img} alt="image" width={"100%"} />
                    <Typography>{datas.title}</Typography>
                    <span>{datas.desc}</span>
                    <h5 onClick={() => navigate("/single-news")}>Read more</h5>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={4}>
            <h4
              style={{
                fontWeight: "800",
                color: "black",
                marginBottom: "15px",
              }}
            >
              Popular and Trending
            </h4>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={5}>
                <Box className={classes.news_part}>
                  <img src={NewsFour} alt="image" width={"100%"} />
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Vestibulum ante ipsum primis in faucibus orci .
                  </Typography>
                  <span>
                    Duis hendrerit vitae velit eget cursus. In hac habitasse
                    platea dictumst. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas.Proin
                    sit amet felis a mi fermentum fermentum. Vivamus congue enim
                    justo, non fermentum lectus tincidunt ac. Suspendisse
                    potenti...
                  </span>
                  <h5 onClick={() => navigate("/single-news")}>Read more</h5>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <Box className={classes.short_news}>
                  {second_news.map((datas) => (
                    <Box
                      className={`${classes.news_part} `}
                    >
                      <img src={datas.img} alt="image" />
                      <Box>
                        <Typography>{datas.title}</Typography>
                        <span>{datas.desc}</span>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box mt={4}>
            <h4
              style={{
                fontWeight: "800",
                color: "black",
                marginBottom: "15px",
              }}
            >
              What’s new
            </h4>
            <Grid container spacing={2}>
              {third_news.map((datas) => (
                <Grid item xs={12} sm={12} md={6}>
                  <Box className={classes.short_news}>
                    <Box
                      className={`${classes.news_part}`}
                    >
                      <img src={datas.img} alt="image" />
                      <Box>
                        <Typography>{datas.title}</Typography>
                        <span>{datas.desc}</span>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default NewsPage;
