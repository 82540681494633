import { Box, Dialog, Typography, Button, Grid, MenuItem, Checkbox, InputAdornment } from "@mui/material";
import "./popup.css";
import { CloseIconGreen, ContactIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { Numeric, convertPhone, splitFirstLetter } from "../../Utilities/commonFunctions";
import classes from '../property/property.module.css';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useState } from "react";


const label = { inputProps: { "aria-label": "Checkbox demo" } };

function ContactOwnerPop(props) {
  const { open, handleClose, contactPopupDetail,handleGetContact,getContact } = props;

  const [isOwnerChecked, setIsOwnerChecked] = useState(false);

  function handleCheckBox(e) {
    if (e?.target?.checked) {
      setIsOwnerChecked(true);
    } else {
      setIsOwnerChecked(false);
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="contact-pop"
      >
        <Box>
          <Box
            className="d-flex-between popup-header"
          >
            <Typography fontSize={"20px"}>Contact the Owner</Typography>
            <span onClick={handleClose}>
            <CloseIconGreen
              className={"contact-close"}
            />
            </span>
          </Box>

          <Box p={3}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                  <Typography className={classes.name_avatar}>
                    {splitFirstLetter(
                      contactPopupDetail?.name
                    )}
                  </Typography>
                  <Box>
                    <Typography className={classes.contact_owner}>
                      {contactPopupDetail?.name}
                    </Typography>
                    <Typography className={classes.contact_owner}>
                      {" "}
                      {convertPhone(
                        contactPopupDetail?.phone
                      )}{" "}
                    </Typography>
                  </Box>
                </Box>
                <Typography mt={2}>Please Share Your Contact</Typography>
                <ValidatorForm
                  useref="form"
                  //   onSubmit={handleNext}
                  className={classes.SignUpForm}
                >
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter your name"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"name"}
                    value={getContact.name}
                    size="normal"
                    onChange={handleGetContact}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    style={{ margin: "8px 0" }}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    validators={["required"]} // Add this line
                    errorMessages={["Your name is required"]}
                  />

                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter your mobile number"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"phone"}
                    value={getContact.phone}
                    size="normal"
                    onChange={handleGetContact}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <span style={{marginLeft:"12px",color:"black"}} >+91</span>
                        </InputAdornment>
                      ),
                    }}
                    style={{ margin: "8px 0" }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter your email"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"email"}
                    value={getContact.email}
                    size="normal"
                    onChange={handleGetContact}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    style={{ margin: "8px 0" }}
                    validators={["required", "isEmail"]} // Add this line
                    errorMessages={[
                      "Email is required",
                      "Enter Valid email ID",
                    ]}
                  />
                  <Box display={"flex"} alignItems={"start"} mt={1}>
                    <Checkbox
                      {...label}
                      onChange={(e) => handleCheckBox(e)}
                      checked={isOwnerChecked}
                      className={classes.checkbox}
                    />
                    <Typography className={classes.checkbox_content}>
                      I agree to be contacted by Housing and other agents
                      via WhatsApp, SMS, phone, email etc
                    </Typography>
                  </Box>
                  <Box mt={2}>
                    <Button
                      fullWidth
                      className={classes.register_btn}
                      type="submit"
                    //   onClick={handleContactOwner}
                      disabled={!isOwnerChecked}
                    >
                      Get Contact Details
                    </Button>
                  </Box>
                </ValidatorForm>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ContactOwnerPop;
