import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Popover,
  Tabs,
  Tab,
  Grid,
  Chip,
  Fab,
  useScrollTrigger,
  Fade,
  Divider,
} from "@mui/material";
import classes from "./home.module.css";
import {
  CallNowIcon,
  EmailIcon,
  WhiteFbIcon,
  WhiteInstaIcon,
  WhiteYoutubeIcon,
} from "../../Assets/Icons/icons";
import PlayStore from "../../Assets/Images/Play-store.png";
import AddServicePop from "../popup/AddServicePop";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function FooterSection() {
  const [servicePop, setServicePop] = useState(false);
  const [inputs, setInputs] = useState({
    service: "",
    phone: "",
    email: "",
    details: "",
  });
  const navigate = useNavigate();
  const { ln ,servicesList} = useSelector((state) => state.userLogin);

  function handleClose() {
    setServicePop(false);
    setInputs({ service: "", phone: "", email: "", details: "" });
  }

  function handleChange(e) {
    const { name, value } = e.target;

    setInputs({ ...inputs, [name]: value });
  }

  function handleAddService() {}

  function handleGetYear() {
    let current_date = new Date();

    return current_date.getFullYear();
  }
  
  return (
    <Box bgcolor={"var(--text-color-1)"} py={4}>
      <Container>
        <Box>
          <h3>
            <b style={{ color: "#fff" }}>
              {ln && ln["gazam"] ? ln["gazam"] : "Gazam"}
            </b>
          </h3>
          <Typography sx={{ color: "#ffffffba" }}>
            {ln && ln["footer"]
              ? ln["footer"]
              : "Lorem ipsum dolor sit amet consectetur. Nibh nec feugiat et nisl.Sed malesuada pretium elit a sit fames aliquet ultrices. Odio sedsed quis volutpat. Purus tempus amet id habitant mauris euismodtincidunt facilisi."}
          </Typography>
        </Box>
        <Box mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <Box className={classes.footer_part}>
                <h5 style={{ color: "#fff" }}>Services</h5>
                {Array.isArray(servicesList) &&
                servicesList?.map((datas)=>(
                  <span
                  onClick={() => {
                    // setInputs({ ...inputs, service: "Land Survey" });
                    // setServicePop(true);
                    navigate(`/nri-services/${datas?._id}`);
                  }}
                >
                  {datas?.name}
                </span>
                ))
                }
                {/* <span
                  onClick={() => {
                    setInputs({ ...inputs, service: "Legal Opinion" });
                    setServicePop(true);
                  }}
                >
                  {ln && ln["legal_opinion"]
                    ? ln["legal_opinion"]
                    : "Legal Opinion"}
                </span>
                <span
                  onClick={() => {
                    setInputs({ ...inputs, service: "Land Survey" });
                    setServicePop(true);
                  }}
                >
                  {ln && ln["land_survey"] ? ln["land_survey"] : "Land Survey"}
                </span>
                <span
                  onClick={() => {
                    setInputs({ ...inputs, service: "Building Permission" });
                    setServicePop(true);
                  }}
                >
                  {ln && ln["building_permission"]
                    ? ln["building_permission"]
                    : "Building Permission"}
                </span>
                <span
                  onClick={() => {
                    setInputs({
                      ...inputs,
                      service: "Land Monitoring Services",
                    });
                    setServicePop(true);
                  }}
                >
                  {ln && ln["land_monitoring_services"]
                    ? ln["land_monitoring_services"]
                    : "Land Monitoring Services"}
                </span>
                <span
                  onClick={() => {
                    setInputs({ ...inputs, service: "Layout Approvals" });
                    setServicePop(true);
                  }}
                >
                  {" "}
                  {ln && ln["layout_approvals"]
                    ? ln["layout_approvals"]
                    : "Layout Approvals"}
                </span>
                <span
                  onClick={() => {
                    setInputs({
                      ...inputs,
                      service: "Land Development services",
                    });
                    setServicePop(true);
                  }}
                >
                  {" "}
                  {ln && ln["land_development_services"]
                    ? ln["land_development_services"]
                    : "Land Development services"}
                </span>
                <span
                  onClick={() => {
                    setInputs({
                      ...inputs,
                      service: "Relationship Manager (NRI)",
                    });
                    setServicePop(true);
                  }}
                >
                  {" "}
                  {ln && ln["relationship_manager"]
                    ? ln["relationship_manager"]
                    : "Relationship Manager (NRI)"}
                </span> */}
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Box className={classes.footer_part}>
                <h5 style={{ color: "#fff" }}>
                  {ln && ln["plans"] ? ln["plans"] : "Plans"}
                </h5>
                <span>
                  {ln && ln["residential"] ? ln["residential"] : "Residential"}
                </span>
                <span>
                  {ln && ln["commercial"] ? ln["commercial"] : "Commercial"}
                </span>
                <span>
                  {ln && ln["plots_and_lands"]
                    ? ln["plots_and_lands"]
                    : "Plots and Lands"}
                </span>
                <span>
                  {/* {ln && ln["firm_land"] ? ln["firm_land"] : "Farm Land"} */}
                  Farm Land
                </span>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Box className={classes.footer_part}>
                <h5 style={{ color: "#fff" }}>
                  {ln && ln["company"] ? ln["company"] : "Company"}
                </h5>
                {/* <Link to="/about"> */}
                  <span onClick={()=>navigate("/about")} >
                  {ln && ln["about_us"] ? ln["about_us"] : "About us"}
                </span>
                {/* </Link> */}
                <Link to="/connect-withus">
                <span>
                  {ln && ln["contact_us"] ? ln["contact_us"] : "Contact Us"}
                </span>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Box className={`${classes.footer_part} ${classes.footer_icons}`}>
                <h5 style={{ color: "#fff" }}>
                  {" "}
                  {ln && ln["connect_with_us"]
                    ? ln["connect_with_us"]
                    : "Connect with us"}
                </h5>
                <WhiteInstaIcon />
                <WhiteFbIcon />
                <WhiteYoutubeIcon />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={5}>
              <Box className={classes.footer_part}>
                <h5 style={{ color: "#fff" }}>
                  {" "}
                  <CallNowIcon />{" "}
                  {ln && ln["phone_number"]
                    ? ln["phone_number"]
                    : "Phone number"}
                </h5>
                <span>+91-1800 2544 24</span>
                <span>
                  {" "}
                  {ln && ln["monday_saturday"]
                    ? ln["monday_saturday"]
                    : "Monday - Saturday (9:00AM to 11:00PM IST)"}
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Box className={classes.footer_part}>
                <h5 style={{ color: "#fff" }}>
                  {" "}
                  <EmailIcon /> {ln && ln["email"] ? ln["email"] : "Email ID"}
                </h5>
                <span>
                  {ln && ln["general"] ? ln["general"] : "General"} - feedback@
                  .gazamcom
                </span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Box className={classes.footer_part}>
                <h5 style={{ color: "#fff" }}>
                  {" "}
                  {ln && ln["download_app"]
                    ? ln["download_app"]
                    : "Download App"}
                </h5>
                <img src={PlayStore} alt="image" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Divider
          sx={{ height: "3px", backgroundColor: "white", margin: "15px 0" }}
        />
        <Grid container>
          <Grid item xs={12} sm={12} md={9}>
            <Box className="d-flex" gap={"50px"}>
            <Link to="/terms-of-use">
              <span style={{ color: "#fff" }}>
                {ln && ln["terms_conditions"]
                  ? ln["terms_conditions"]
                  : "Term and condition"}
              </span>
              </Link>
              <Link to="/privacy-policy">
              <span style={{ color: "#fff" }}>
                {ln && ln["privacy_and_policies"]
                  ? ln["privacy_and_policies"]
                  : "Privacy and Policies "}
              </span>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            {" "}
            <span style={{ color: "#fff" }}>
              {" "}
              © {handleGetYear()} |{" "}
              {ln && ln["all_rights_reserved"]
                ? ln["all_rights_reserved"]
                : "All Rights Reserved"}
            </span>
          </Grid>
        </Grid>
      </Container>
      <AddServicePop
        open={servicePop}
        inputs={inputs}
        handleAddService={handleAddService}
        handleClose={handleClose}
        handleChange={handleChange}
      />
    </Box>
  );
}

export default FooterSection;
