import React, { useState } from "react";
import classes from "./auth.module.css";
import { Button, Card, Typography, InputAdornment, MenuItem } from "@mui/material";
import { useNavigate } from "react-router";
import { CloseIcon, DownChevorletIcon } from "../../Assets/Icons/icons";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DefaultLayout from "./defaultLayout";
import { StyledInput } from "../../Components/commonInputFeild";
import { Numeric } from "../../Utilities/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, pageLoader } from "../../Redux/Actions/userActions";
import AlertBox from "../../Components/AlertBox";
import { country_codes } from "../../Utilities/commonConstants";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const Login = () => {
  const LoginDiv = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorAlert, setErrorAlert] = useState();
    const { ln, error } = useSelector((state) => state.userLogin);
    const [phone, setPhone] = useState("");
    const [isInputFocused, setInputFocused] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [countryCode, setCountryCode] = useState("91");
    const [selectedCountry, setSelectedCountry] = useState("in");
    
    const handleFocus = () => {
      setInputFocused(true);
    };

    const closeAlert = () => {
      setErrorAlert(false);
    };

    const handleBlur = () => {
      if (phone === "") {
        setInputFocused(false);
      }
    };

    const handleChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue.length <= 10) {
        setPhone(inputValue);
      }
    };

    const handleSubmit = () => {
      if (phone?.length === 0) {
        setIsValid(false);
      }  else if (!isValid) {
        setIsValid(false);
      } else{
        const userEntry = {
          phone: phone,
          country_code:countryCode
        };
        dispatch(pageLoader(true));
        dispatch(loginUser(userEntry, navigate, setErrorAlert));
      }
    };

    const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
      console.log("countryData =",countryData)
      setCountryCode(countryData?.dialCode);
      setSelectedCountry(countryData.iso2);
      setPhone("");
    }
  
    const handlePhoneNumberChange = (isValidNum, value, country) => {
      setIsValid(isValidNum);
      setCountryCode(country?.dialCode);
      setSelectedCountry(country?.iso2);
      setPhone(value);
    };

    return (
      <Card className={classes.registerCard}>
        <Typography variant="h5" className={classes.registerHeader}>
          {ln && ln["login"] ? ln["login"] : "Login"}
        </Typography>
        <Button
          className={classes.closeButton}
          variant="text"
          onClick={() => navigate(-1)}
        >
          <CloseIcon />
        </Button>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={classes.SignUpForm}
        >
          {/* <StyledInput
            label={
              ln && ln["enter_mobile_number"]
                ? ln["enter_mobile_number"]
                : "Enter Mobile Number"
            }
            variant="standard"
            fullWidth
            id="emailInput"
            placeholder=""
            className={`${classes.formInputFeild} ${
              isInputFocused && classes.FocusedClass
            }`}
            value={phone}
            size="normal"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{
              startAdornment:  ( // Conditionally render input adornment
                <InputAdornment position="start">
                  <TextValidator
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            select
                            id="emailInput"
                            placeholder="Expected by"
                            className={` country-code`}
                            name="expected_time_of_possession"
                            value={countryCode}
                            size="normal"
                          >
                            {country_codes.map((item, index) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={index}
                                  onClick={(e) =>
                                    setCountryCode(item)
                                  }
                                  style={{ textTransform: "capitalize" }}
                                  className={`${classes.code_list} all-codes`}
                                >
                                  +{item}
                                </MenuItem>
                              );
                            })}
                          </TextValidator>
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              Numeric(e);
            }}
            validators={["required"]} 
            errorMessages={["Mobile Number is required"]}
          /> */}
          {selectedCountry && <div className={`${classes.new_countrycode_field} ${!isValid && classes.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={phone}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
          <Button
            type="submit"
            variant="contained"
            disabled={phone.length == 0 || !isValid}
            fullWidth
            className={`mt-4 ${classes.continueBtn} ${
              (phone.length == 0 || !isValid) && classes.disabledBtn
            }`}
            // className={`mt-4 ${classes.continueBtn}`}
          >
            {ln && ln["send_otp"] ? ln["send_otp"] : "Send OTP"}
          </Button>
        </ValidatorForm>
        <Typography className={classes.NoteText}>
          {ln && ln["didnt_have_account"]
            ? ln["didnt_have_account"]
            : "Didn't Have an Account?"}{" "}
          <span onClick={() => navigate("/register")}>
            {ln && ln["register"] ? ln["register"] : "register"}
          </span>
        </Typography>
        {errorAlert && error && (
          <AlertBox type={"error"} message={error} stateName={closeAlert} />
        )}
      </Card>
    );
  };

  return <DefaultLayout children={<LoginDiv />} />;
};

export default Login;
