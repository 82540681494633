import { Typography } from "@mui/material";
import React, { useState } from "react";
import classes from "./layout.module.css";
import logo from "../Assets/Images/gazamLogo.png";
import { useNavigate } from "react-router";
import ConfirmModal from "../Components/confirmModel";

const GuestHeaders = () => {
  const navigate = useNavigate();
  const [logoutModal, setLogoutModal] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const closeModal = () => {
    setLogoutModal(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    closeModal();
    navigate("/login");
  };

  return (
    <div className={classes.guestHeaderMainDiv}>
      <img src={logo} alt="logo" className={classes.guestHeaderLogo}                 style={{cursor:"pointer"}}
                onClick={() => navigate("/homepage")} />
      {userInfo && (
        <Typography
          className={classes.guestHeaderLogout}
          onClick={() => setLogoutModal(true)}
        >
          Logout
        </Typography>
      )}
      {logoutModal && (
        <ConfirmModal
          modalClose={closeModal}
          heading={"Logout Confirmation"}
          subHeading={`Are you sure want to Logout`}
          callback={handleLogout}
        />
      )}
    </div>
  );
};

export default GuestHeaders;
