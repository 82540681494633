import { useEffect, useState } from "react";
import {
  ActiveIcon,
  InActiveIcon,
  WhatsAppIcon,
} from "../../Assets/Icons/icons";
import classes from "./property.module.css";
import { Box, Container, Typography, Button, Grid, Radio } from "@mui/material";
import PropertyLayout from "./index";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addDocumentPhoto,
  errorAlertAction,
  getAllValues,
  postPropertyDetails,
} from "../../Redux/Actions/userActions";
import { FiUpload } from "react-icons/fi";
import { IoCamera } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import WebcamPop from "../popup/WebcamPop";
import GoogleMapPop from "../popup/GoogleMapPop";
import classes1 from "../Auth/auth.module.css";
import AddImage from "../../Assets/Images/add-image.png";

function PropertyLandingPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const ParentDiv = () => {
    const [isWatsApp, setIsWatsApp] = useState(false);
    const [webcamPopup, setWebcamPopup] = useState(false);
    const [url, setUrl] = useState(null);
    const [selectedUser, setSelectedUser] = useState("");
    const [userValues, setUserValues] = useState({});
    const [profileImage, setProfileImage] = useState("");
    const [editProfileImage, setEditProfileImage] = useState("");
    const [documentImage, setDocumentImage] = useState({
      file: "",
      preview: "",
      doc_name: "",
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { propertyData, ln } = useSelector((state) => state.userLogin);
    let _edit = sessionStorage.getItem("edit");
    let _docs = JSON.parse(sessionStorage.getItem("converted_doc_imgs"));
    let my_res = JSON.parse(sessionStorage.getItem("property_info"));

    function handleClose() {
      setWebcamPopup(false);
      setUrl(null);
    }

    function handleAddPhoto() {}

    function handleClick(name) {
      if (selectedUser === name) {
        setSelectedUser("");
      } else {
        setSelectedUser(name);
      }
    }
    
    function handleNext() {
      console.log("profileImage =",profileImage);
      console.log("editProfileImage =",editProfileImage);
      console.log("documentImage =",documentImage);
      if (selectedUser === "") {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select User type"));
      } else if (
        selectedUser === "661cc2f5267fb2f57d21fb8d" &&
        (!profileImage || profileImage === "") &&
        ( !editProfileImage || editProfileImage === "")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please add your photo"));
      } else if (
        selectedUser === "661cc2f5267fb2f57d21fb8d" &&
        (!documentImage.preview || documentImage.preview === "") &&
        (!documentImage.doc_name || documentImage.doc_name === "")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please upload your property document"));
      } else {
        let my_res = JSON.parse(sessionStorage.getItem("property_info"));
        let body = {
          user_type: selectedUser,
        };
        if (selectedUser === "661cc2f5267fb2f57d21fb8d") {
          body["owner_profile"] = _docs?.photo
            ? _docs?.photo
            : my_res?.owner_profile;
          body["owner_document"] = _docs?.doc
            ? _docs?.doc
            : my_res?.owner_document;
        }
        let route = "/propertybasicdetails";

        if (my_res) {
          sessionStorage.setItem(
            "property_info",
            JSON.stringify({ ...my_res, ...body })
          );
        } else {
          sessionStorage.setItem("property_info", JSON.stringify(body));
        }

        dispatch(postPropertyDetails(body, navigate, route));
      }
    }

    function handleGetValues() {
      dispatch(getAllValues(setUserValues));
    }

    function handleDocumentImage(e) {
      let my_image = e?.target?.files[0];
      let file_name = my_image?.name?.split(".")[1].toLowerCase();
      let uploaded_file_name = my_image?.name;

      if (["pdf", "doc", "docx"].includes(file_name)) {
        const reader = new FileReader();
        reader.onload = () => {
          setDocumentImage({
            preview: reader.result,
            file: my_image,
            doc_name: "",
          });
        };
        reader.readAsDataURL(my_image);
        let my_file = new FormData();
        my_file.append("file", my_image);
        dispatch(
          addDocumentPhoto(my_file, "doc", "document", uploaded_file_name)
        );
      } else {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("File accepts only pdf"));
      }
    }

    function handleImage(e) {
      let my_image = e?.target?.files[0];
      let file_name = my_image?.name?.split(".")[1].toLowerCase();
      let uploaded_file_name = my_image?.name;
      if (
        ["png", "jpg", "jpeg"].includes(file_name) &&
        my_image?.size < 10000000
      ) {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(reader.result);
        };
        reader.readAsDataURL(my_image);
        let my_file = new FormData();
        my_file.append("file", my_image);
        dispatch(
          addDocumentPhoto(my_file, "photo", "image", uploaded_file_name)
        );
      } else {
        localStorage.setItem("error", "2");
        dispatch(
          errorAlertAction("File accepts only jpg, jpeg and png with max 10mb")
        );
      }
    }

    function handleOpenPdf() {
      // Create a link element
      // let pdfUrl = "${process.env.REACT_APP_API_URL}/uploads/e3d2eec0565e20cc581e4d07790dfd83";
      // const link = document.createElement('a');
      // link.href = pdfUrl;
      // link.setAttribute('download', 'document.pdf'); // Set the filename for the downloaded file
      // document.body.appendChild(link);
      // // Trigger the download
      // link.click();
      // // Clean up
      // document.body.removeChild(link);
      // // Open the downloaded PDF in a new tab after a short delay
      // setTimeout(() => {
      //   window.open(encodeURIComponent(pdfUrl), '_blank');
      // }, 1000); // Adjust the delay as needed
      // const reader = new FileReader();
      // reader.onload = () => {
      //   const pdfDataUrl = reader.result;
      //   const newWindow = window.open();
      //   newWindow.document.write('<iframe width="100%" height="100%" src="' + pdfDataUrl + '"></iframe>');
      // };
      // reader.onerror = () => {
      //   console.error('Error reading the file');
      // };
      // reader.readAsDataURL(documentImage.file);
    }

    function handleOpenPdf2() {
      const reader = new FileReader();
      reader.onload = () => {
        const pdfDataUrl = reader.result;
        const newWindow = window.open();
        newWindow.document.write(
          '<iframe width="100%" height="100%" src="' +
            "${process.env.REACT_APP_API_URL}/uploads/47aea5041c5075affd72dad52780740a" +
            '"></iframe>'
        );
      };
      reader.onerror = () => {
        console.error("Error reading the file");
      };
      // reader.readAsDataURL(documentImage.file);
    }

    useEffect(() => {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let _values = JSON.parse(sessionStorage.getItem("user_values"));
      setUserValues(_values);

      if (my_res) {
        setSelectedUser(my_res?.user_type ?? "");
        setEditProfileImage(
          _docs?.photo ? _docs?.photo?.image : my_res?.owner_profile?.image
        );
        setDocumentImage({
          ...documentImage,
          doc_name: _docs?.doc
            ? _docs?.doc?.original_name
            : my_res?.owner_document?.original_name,
        });
      }

      // if (propertyData) {
      //   setDocumentImage( {file:propertyData?.owner_document?.type1,preview:propertyData?.owner_document?.type} ?? {file:"",preview:""});
      // }

      handleGetValues();
    }, []);



    return (
      <Box className={classes.role_box}>
        <Typography className={classes.role_box_header} mb={"45px"}>
          {_edit
            ? ln && ln["edit_details"]
              ? ln["edit_details"]
              : "Edit your details"
            : ln && ln["lets_started"]
            ? ln["lets_started"]
            : "Let’s Get Started"}
        </Typography>
        <Typography className={classes.my_role}>
          {" "}
          {ln && ln["are_you_a"] ? ln["are_you_a"] : "Are you a"}
        </Typography>
        <Grid container spacing={3}>
          {userValues?.user_type?.map((datas, i) => {
            return (
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  className={
                    selectedUser === datas._id
                      ? classes.user_active
                      : classes.user
                  }
                  onClick={() => handleClick(datas._id)}
                >
                  {selectedUser === datas._id ? (
                    <ActiveIcon />
                  ) : (
                    <InActiveIcon />
                  )}
                </Box>
                <Typography
                  className={classes.title}
                  sx={{ opacity: selectedUser === datas._id ? 1 : 0.7 }}
                >
                  {ln && ln[datas.text.toLowerCase()]
                    ? ln[datas.text.toLowerCase()]
                    : datas.text}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        {/* {selectedUser === "661cc2f5267fb2f57d21fb8d" && (
          <>
            <Box className={classes.doc_photo_wrapper}>
              <img src={AddImage} alt="AddImage" />
              <Typography
                sx={{ color: "var(--border-colour)", margin: "7px 0" }}
              >
                Add Files
              </Typography>
              <Typography
                className={classes1.SecondsText}
                textAlign={"center"}
                mb={4}
              >
               
                Upload your property documents with max size 10 mb in format
                jpg, jpeg and png
              </Typography>
              <input
                type="file"
                id="actual-btn"
                onChange={(e) => handleImage(e)}
                hidden
                multiple={true}
              />
              <label for="actual-btn" className={classes.doc_upload_btn}>
                Upload Files
              </label>
              
            </Box>
          </>
        )} */}
        {selectedUser === "661cc2f5267fb2f57d21fb8d" && (
          <>
            <Box my={3}>
              <Typography className={classes.my_role}>Photo</Typography>
              <Box className={classes.profile_photo}>
                {!profileImage && !editProfileImage && (
                  <Box className={classes.upload_photo_wrapper}>
                  <img src={AddImage} alt="AddImage" width={"50px"} />
                  <Typography
                    sx={{ color: "var(--border-colour)", margin: "4px 0" }}
                  >
                    Add Photo
                  </Typography>
                  <Typography
                    className={classes.SecondsText}
                    textAlign={"center"}
                    mb={1}
                  >
                    {ln && ln["upload_photos_with_formats"]
                      ? ln["upload_photos_with_formats"]
                      : "Upload your photo with max size 10 mb in format jpg, jpeg and png"}
                  </Typography>
                  {/* <input
                    type="file"
                    id="actual-btn"
                    onChange={(e) => handleDocumentImage(e)}
                    hidden
                    multiple={true}
                  /> */}
                  <Box className="d-flex-center" gap={"8px"} >
                  <Box display={{ xs: "none", sm: "block", md: "block" }}>
                  <label for="actual-btn" onClick={() => setWebcamPopup(true)} className={classes.upload_btn}>
                    Take Photo
                  </label>
                  </Box>
                     <Box>
                       <input
                         type="file"
                         id="profile-btn"
                         onChange={(e) => handleImage(e)}
                         hidden
                         multiple={true}
                       />
                       <label
                         for="profile-btn"
                         className={classes.upload_btn}
                       >
                         Upload Photo
                       </label>
                     </Box>
                  </Box>
                </Box>
                  // <Box>
                  //   <Box display={{ xs: "none", sm: "block", md: "block" }}>
                  //     <Typography
                  //       className={classes.profile_upload_btn}
                  //       onClick={() => setWebcamPopup(true)}
                  //     >
                  //       <IoCamera /> Take a photo
                  //     </Typography>
                  //   </Box>
                  //   <Typography
                  //     sx={{
                  //       color: "var(--secondary-text-color)",
                  //       display: { xs: "none", sm: "block", md: "block" },
                  //     }}
                  //   >
                  //     (or)
                  //   </Typography>
                  //   <Box>
                  //     <input
                  //       type="file"
                  //       id="profile-btn"
                  //       onChange={(e) => handleImage(e)}
                  //       hidden
                  //       multiple={true}
                  //     />
                  //     <label
                  //       for="profile-btn"
                  //       className={classes.profile_upload_btn}
                  //     >
                  //       <FiUpload /> Upload a photo
                  //     </label>
                  //   </Box>
                  // </Box>
                )}
                {(profileImage || editProfileImage) && !webcamPopup && (
                  <Box width={"80%"} margin={"auto"} position={"relative"}>
                    <img
                      src={
                        profileImage ||
                        `${process.env.REACT_APP_API_URL}/${editProfileImage}`
                      }
                      alt="profile-photo"
                      width={"100%"}
                    />
                    <IoIosCloseCircleOutline
                      className={classes.photo_close}
                      onClick={() => {
                        setProfileImage("");
                        setEditProfileImage();
                      }}
                    />
                  </Box>
                )}
                
              </Box>
            </Box>
            <Box my={3}>
              <Typography className={classes.my_role}>
                Property Document
              </Typography>
              <Box className={classes.profile_photo}>
                {!documentImage.preview && !documentImage.doc_name && (
                  // <Box>
                  //   <Box>
                  //     <input
                  //       type="file"
                  //       id="document-btn"
                  //       onChange={(e) => handleDocumentImage(e)}
                  //       hidden
                  //       multiple={true}
                  //     />
                  //     <label
                  //       for="document-btn"
                  //       className={classes.profile_upload_btn}
                  //     >
                  //       <FiUpload /> Upload a document
                  //     </label>
                  //   </Box>
                  // </Box>
                  <Box className={classes.upload_photo_wrapper}>
                    <img src={AddImage} alt="AddImage" width={"50px"} />
                    <Typography
                      sx={{ color: "var(--border-colour)", margin: "4px 0" }}
                    >
                      Add Files
                    </Typography>
                    <Typography
                      className={classes.SecondsText}
                      textAlign={"center"}
                      mb={1}
                    >
                      {ln && ln["upload_photos_with_formats"]
                        ? ln["upload_photos_with_formats"]
                        : "Upload your property documents with max size 10 mb"}
                    </Typography>
                    <input
                      type="file"
                      id="document-btn"
                      onChange={(e) => handleDocumentImage(e)}
                      hidden
                      multiple={true}
                    />
                    <label for="document-btn" className={classes.upload_btn}>
                      {ln && ln["choose_file"]
                        ? ln["choose_file"]
                        : "Choose File"}
                    </label>
                  </Box>
                )}
                {(documentImage.preview || documentImage?.doc_name) && (
                  <Box width={"80%"} margin={"auto"} position={"relative"}>
                    <span onClick={handleOpenPdf} style={{ cursor: "pointer" }}>
                      {documentImage?.file?.name || documentImage?.doc_name}
                      {"  "}
                    </span>
                    <IoIosCloseCircleOutline
                      className={classes.document_close}
                      onClick={() =>
                        setDocumentImage({
                          file: "",
                          preview: "",
                          doc_name: "",
                        })
                      }
                    />
                  </Box>
                )}
                
              </Box>
            </Box>
          </>
        )}
        <Button
          fullWidth
          className={classes.continue}
          sx={{ marginTop: "40px" }}
          onClick={handleNext}
        >
          {ln && ln["continue"] ? ln["continue"] : "Continue"}
        </Button>

        <Typography className={classes.whatsapp}>
          <WhatsAppIcon />
          <b>
            {" "}
            {ln && ln["post_via_watsapp"]
              ? ln["post_via_watsapp"]
              : "Post Via Watsapp"}
          </b>
        </Typography>
        <WebcamPop
          open={webcamPopup}
          handleClose={handleClose}
          setProfileImage={setProfileImage}
          profileImage={profileImage}
          handleAddPhoto={handleAddPhoto}
        />
      </Box>
    );
  };
  return (
    <>
      <PropertyLayout children={<ParentDiv />} stepper={0} />
    </>
  );
}

export default PropertyLandingPage;
