import { Box, Dialog, Typography, Button, Grid, MenuItem } from "@mui/material";
import "./popup.css";
import { ContactIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";

function ContactPop(props) {
  const { open, handleClose, contactPopupDetail } = props;

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="contact-pop"
      >
        <Box>
          <Box
            className="d-flex-between popup-header"
          >
            <Typography fontSize={"20px"}>Contact Details</Typography>
            <IoIosCloseCircleOutline
              className={"contact-close"}
              onClick={handleClose}
            />
          </Box>

          <Box p={3}>
            <Typography fontSize={"19px"} my={1}>
              <b>Name :</b> {contactPopupDetail?.name}
            </Typography>
            <Typography fontSize={"19px"} my={1}>
              <b>Phone :</b> {contactPopupDetail?.phone}
            </Typography>
            <Typography fontSize={"19px"} my={1}>
              <b>Email :</b> {contactPopupDetail?.email}
            </Typography>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ContactPop;
