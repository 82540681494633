import React, { useEffect, useState } from "react";
import classes from "./auth.module.css";
import { Button, Card, Typography } from "@mui/material";
import { languagesList } from "../../Utilities/commonConstants";
import { GreenCheckedIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLanguageData } from "../../Redux/Actions/userActions";

const LanguageSelect = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState(languagesList[1]);
  const [errorAlert, setErrorAlert] = useState(false);
  let selected_lang = JSON.parse(localStorage.getItem("language"));

  const handleSubmit = () => {
    localStorage.setItem("language", JSON.stringify(selectedLanguage));
    dispatch(getLanguageData(selectedLanguage?.code ? selectedLanguage.code : "en", setErrorAlert));
    navigate("/homepage");
  };

  useEffect(()=>{
    if (selected_lang) {
      setSelectedLanguage(selected_lang);
      
    }
  },[])

  

  return (
    <div className={classes.languageScreenMainDiv}>
      <Card className={classes.languageCardMainDiv}>
        <Typography
          variant="h5"
          className={`mb-3 ${classes.languageCardMainHeading}`}
        >
          Select Language
        </Typography>
        <Typography
          variant="h6"
          className={`${classes.languageCardSubHeading}`}
        >
          Select your preferred language for a personalized experience
        </Typography>
        <div className={classes.languageCardSubDiv}>
          <div className={`mt-4 ${classes.LanguagesContainerDiv}`}>
            {languagesList.map((language, index) => {
              return (
                <Card
                  className={`${classes.languagesListCard} ${
                    selectedLanguage.name === language.name &&
                    classes.selectedLanguageCard
                  }`}
                  key={index}
                  onClick={() => setSelectedLanguage(language)}
                >
                  <Typography
                    variant="h6"
                    className={`${classes.languageSubCardSubHeading}`}
                  >
                    {language.name}
                  </Typography>
                  <Typography
                    variant="h6"
                    className={`${classes.languageSubCardMainHeading}`}
                  >
                    {language.text}
                  </Typography>
                  {selectedLanguage.name === language.name && (
                    <div className={classes.checkedIcon}>
                      <GreenCheckedIcon />
                    </div>
                  )}
                </Card>
              );
            })}
          </div>
          <Button
            variant="contained"
            className={classes.continueBtn}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default LanguageSelect;
