import { useEffect, useState } from "react";
import Navbar from "../Pages/Home/Navbar";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getUserStatus, successAlertAction } from "../Redux/Actions/userActions";
import SnackBarPop from "../Pages/popup/SnackBarPop";
import FooterSection from "../Pages/Home/FooterSection";
import BlockUserPopup from "../Pages/popup/BlockUserPopup";
import { generateUniqueId, getDecryptedCookie, setEncryptedCookie } from "../Utilities/commonFunctions";

function HomeLayout() {
  const [userStatus, setUserStatus] = useState("");
  const [blockUserPop, setBlockUserPop] = useState(false);
    const [snackBar, setSnackBar] = useState({
    snack: false,
    snackMsg: "",
    vertical: "top",
    horizontal: "right",
  });
  const { snack, snackMsg, vertical, horizontal } = snackBar;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let _id = JSON.parse(localStorage.getItem("userToken"))?.id;
  const { successAlert } = useSelector((state) => state.userLogin);
  let status = localStorage.getItem("success");

  const handleClose = () => {
    setSnackBar({
      snack: false,
      snackMsg: "",
      vertical: "top",
      horizontal: "right",
    });
  };

  async function handleCheckDeviceId() {
    let isDeviceIdExist =  JSON.parse(localStorage.getItem("device-id"));
    const decryptedData = getDecryptedCookie('device-infos', process.env.REACT_APP_DEVICE_KEY);
    if (decryptedData) {
      
    } else {
      let new_deviceId = await generateUniqueId();

      let obj = {
        device_id:new_deviceId
      };
      setEncryptedCookie("device-infos",obj,process.env.REACT_APP_DEVICE_KEY);
      // localStorage.setItem("device-id",JSON.stringify(obj));
    }
  }

  useEffect(() => {
    let user_path = window.location.pathname;

    if (
      !_token &&
      !user_path.includes("homepage") &&
      !user_path.includes("results") &&
      !user_path.includes("plan") &&
      !user_path.includes("privacy") &&
      !user_path.includes("connect") &&
      !user_path.includes("terms") &&
      !user_path.includes("services") &&
      !user_path.includes("news") &&
      !user_path.includes("singleproperty") &&
      !user_path.includes("news") &&
      !user_path.includes("about") 
    ) {
      navigate("/login");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (status === "1") {
      localStorage.removeItem("success");
      setSnackBar({ ...snackBar, snackMsg: successAlert, snack: true });
    }
  }, [status]);

  useEffect(() => {
    if (_id) {
      dispatch(getUserStatus(_id,setUserStatus));
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (_id && userStatus?.status === "Blocked") {
      setBlockUserPop(true);
    }
  }, [userStatus]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

    useEffect(() => {
      handleCheckDeviceId();
    }, []);
  return (
    <>
      <Navbar userStatus={userStatus} />
      <Outlet />
      <FooterSection />
      <SnackBarPop open={snack} handleClose={handleClose} snackMsg={snackMsg} />
      <BlockUserPopup 
        open={blockUserPop}
        handleClose={()=>setBlockUserPop(false)}
      />
    </>
  );
}

export default HomeLayout;
