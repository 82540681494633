import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Radio,
} from "@mui/material";
import "./popup.css";
import { CloseIconGreen, ContactIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useState,useEffect } from "react";
import classes from "../property/property.module.css";
import { languagesList } from "../../Utilities/commonConstants";
import { getLanguageData } from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";

function ChangeLanguagePop(props) {
  const { open, handleClose } = props;
  let selected_lang = JSON.parse(localStorage.getItem("language"));
  const [myLanguage, setMyLanguage] = useState("English");
  const [errorAlert, setErrorAlert] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    localStorage.setItem("language", JSON.stringify(myLanguage));
    const lan = JSON.parse(localStorage.getItem("language"));
    // dispatch(pageLoader(true));
    dispatch(getLanguageData(myLanguage?.code ? myLanguage.code : "en", setErrorAlert));
    handleClose();
  };


  useEffect(()=>{
    if (selected_lang) {
      setMyLanguage(selected_lang)
    } else{
      setMyLanguage(languagesList[1])
    }
  },[])
  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="language-pop"
      >
        <Box>
          <Typography sx={{ cursor: "pointer" }} textAlign={"end"} onClick={handleClose} >
            <CloseIconGreen />
          </Typography>
          <Box textAlign={"center"}>
            <Typography
              fontSize={"23px"}
              sx={{ color: "var(--border-colour)" }}
            >
              Change Language
            </Typography>
            <Typography px={3} mt={1}>
              Change your preferred language for a personalized experience
            </Typography>
          </Box>
          <Box px={2} mt={3} mb={6}>
            {languagesList.map((datas)=>(
                <Box
                mb={1}
                className={
                  myLanguage.name === datas.name ? "selected-language" : "d-flex-between"
                }
                >
                <Typography>{datas.name} {datas.name != "English" &&  `( ${datas.sub_title} )`}</Typography>
                <Radio
                  checked={myLanguage.name === datas.name}
                  onClick={() => setMyLanguage(datas)}
                />
                </Box>
            ))}
            {/* <Box
              mb={1}
              className={
                myLanguage === "Telugu" ? "selected-language" : "d-flex-between"
              }
            >
              <Typography>Telugu ( తెలుగు )</Typography>
              <Radio
                checked={myLanguage === "Telugu"}
                onClick={() => setMyLanguage("Telugu")}
              />
            </Box>
            <Box
              mb={1}
              className={
                myLanguage === "English"
                  ? "selected-language"
                  : "d-flex-between"
              }
            >
              <Typography>English</Typography>
              <Radio
                checked={myLanguage === "English"}
                onClick={() => setMyLanguage("English")}
              />
            </Box>
            <Box
              mb={1}
              className={
                myLanguage === "Hindi" ? "selected-language" : "d-flex-between"
              }
            >
              <Typography>Hindi ( हिंदी )</Typography>
              <Radio
                checked={myLanguage === "Hindi"}
                onClick={() => setMyLanguage("Hindi")}
              />
            </Box>
            <Box
              mb={1}
              className={
                myLanguage === "Kannada"
                  ? "selected-language"
                  : "d-flex-between"
              }
            >
              <Typography>Kannada ( ಕನ್ನಡ )</Typography>
              <Radio
                checked={myLanguage === "Kannada"}
                onClick={() => setMyLanguage("Kannada")}
              />
            </Box> */}
          </Box>
          <Button className={classes.register_btn} fullWidth onClick={handleSubmit} >
            Continue
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default ChangeLanguagePop;
