import { useEffect, useState } from "react";
import { BackIcon, GreenLockIcon } from "../../Assets/Icons/icons";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
} from "@mui/material";
import { Numeric } from "../../Utilities/commonFunctions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createProperty,
  errorAlert,
  errorAlertAction,
  postPropertyDetails,
} from "../../Redux/Actions/userActions";
import { StyledInput } from "../../Components/commonInputFeild";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

let looking = [
  { text: "Sell", value: "sell" },
  { text: "Rent/Lease", value: "rent_lease" },
];
let property = [
  { text: "Plots/lands", value: "plots_lands" },
  { text: "Residential", value: "residential" },
  { text: "Commercial", value: "commercial" },
  { text: "Agricultural/\nFarmlands", value: "agricultural_farmlands" },
];
let property_types = [
  { text: "Apartments", value: "apartments" },
  { text: "Independent House", value: "independent_house" },
  { text: "1 RK Studio Apartments", value: "1_rk_studio_apartments" },
  { text: "Villa", value: "villa" },
  { text: "Other", value: "other" },
];
let property_types_two = [
  { text: "Commercial Buildings", value: "commercial_buildings" },
  { text: "Shops/Retails", value: "shops_retails" },
  { text: "Other", value: "other" },
];
let property_types_three = [
  { text: "Residencial", value: "residencial" },
  { text: "Commercial", value: "commercial" },
];
let property_types_four = [
  { text: "Yes", value: "yes" },
  { text: "No", value: "no" },
];

function PropertyBasicDetails() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const BasicDetailDiv = () => {
    const [inputs, setInputs] = useState({
      looking: "",
      property: "",
      property_types: "",
      contact: "",
      are_you_builder: "",
    });
    const [isValid, setIsValid] = useState(true);
    const [countryCode, setCountryCode] = useState("91");
    const [selectedCountry, setSelectedCountry] = useState("in");
    const [count, setCount] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { propertyData,ln } = useSelector((state) => state.userLogin);
    const userValues = JSON.parse(sessionStorage.getItem("user_values"));
    let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
    let _edit = sessionStorage.getItem("edit");

    function handleBack() {
      let body = {
        offer_type: inputs.looking,
        kind_of_property: inputs.property,
        property_type: inputs.property_types,
        contact_details: inputs.contact,
      };

      let my_res = JSON.parse(sessionStorage.getItem("property_info"));

      sessionStorage.setItem(
        "property_info",
        JSON.stringify({ ...my_res, ...body })
      );
    }

    function handleNext() {
      if (inputs.looking === "") {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select what you are looking "));
      } else if (inputs.property === "") {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select kind of property "));
      } else if (
        inputs.property_types === "" &&
        (inputs.property != "661cc398267fb2f57d21fba0" &&  inputs.property !=  "662f64cf68e54862587dffe0")
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select property types "));
      } else if (
        inputs.are_you_builder === "" &&
        inputs.property == "661cc398267fb2f57d21fba0"
      ) {
        localStorage.setItem("error", "2");
        dispatch(errorAlertAction("Please select are you builder "));
      } else if (inputs?.contact?.length === 0) {
        setIsValid(false);
      }  else if (!isValid) {
        setIsValid(false);
      } else {
        let body = {
          offer_type: inputs.looking,
          kind_of_property: inputs.property,
          property_type: inputs.property_types,
          contact_details: inputs.contact,
          country_id:selectedCountry,
          country_code:countryCode,
          are_you_builder: inputs.are_you_builder,
        };
        if (!_edit) {
          body["property_status"] = "Drafted";
        }
        

        let my_res = JSON.parse(sessionStorage.getItem("property_info"));

        sessionStorage.setItem(
          "property_info",
          JSON.stringify({ ...my_res, ...body })
        );
        let user_details = JSON.parse(localStorage.getItem("userToken"))?.token;

        let route = "";
        if (user_details) {
          route = "/propertyadddetails";
        } else {
          route = "/propertyregister";
        }

        dispatch(postPropertyDetails(body, navigate, route));
      }
    }
    function handleChange(name, value) {
      if (name === "contact") {
        setInputs({ ...inputs, [name]: value.slice(0, 10) });
      } else if (name === "property") {
        if (inputs.property === value) {
          setInputs({ ...inputs, [name]: "", property_types: "" });
        } else {
          setInputs({ ...inputs, [name]: value, property_types: "" });
        }
      } else {
        if (inputs[name] === value) {
          setInputs({ ...inputs, [name]: "" });
        } else {
          setInputs({ ...inputs, [name]: value });
        }

      }
    };


    const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
      setCountryCode(countryData?.dialCode);
      setSelectedCountry(countryData.iso2);
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      if (count || selectedCountry == "in") {
        setInputs({...inputs,contact:""});
        setCount(true);
      } else{
        setCount(true);
      }
    }
  
    const handlePhoneNumberChange = (isValidNum, value, country) => {
      setIsValid(isValidNum);
      setCountryCode(country?.dialCode);
      setSelectedCountry(country?.iso2);
      setInputs({...inputs,contact:value});
    };

    function handleSaveDraft(){
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      let actual_imgs = sessionStorage.getItem("converted_imgs");
      let body = {
        ...my_res,
      };
      body["status"] = "Drafted";

      dispatch(createProperty(body, _token, navigate));
      

    };


    useEffect(() => {
      let my_res = JSON.parse(sessionStorage.getItem("property_info"));
      
      if (my_res) {
        setInputs({
          ...inputs,
          looking: my_res?.offer_type ?? "",
          property: my_res?.kind_of_property ?? "",
          property_types: my_res?.property_type ?? "",
          contact: my_res?.contact_details ?? "",
          are_you_builder: my_res?.are_you_builder ?? "",
        });
        setSelectedCountry(my_res?.country_id ?? "in");
        setCountryCode(my_res?.country_code ?? "91");
      }
      // else  if (_edit){
      //   setInputs({
      //     ...inputs,
      //     looking: _edit?.offer_type ?? "",
      //     property: _edit?.kind_of_property ?? "",
      //     property_types: _edit?.property_type ?? "",
      //     contact: _edit?.contact_details ?? "",
      //   });
      // }
    }, []);

    // useEffect(() => {
    //   setInputs({ ...inputs, property_types: "" });
    // }, [inputs.property]);

    return (
      <>
        <Box className={classes.role_box}>
          <Typography className={classes.role_box_header} mb={"25px"}>
            <span onClick={() => navigate("/postproperty")}>
              <BackIcon />
            </span>{" "}
            {_edit ? ln && ln["edit"] ? ln["edit"] : "Edit": ln && ln["add"] ? ln["add"] : "Add"} {ln && ln["basic_details"] ? ln["basic_details"] : "Basic Details"}
          </Typography>
          <Box mb={3}>
            <Typography className={classes.my_role}>
             {ln && ln["you_are_looking_to"] ? ln["you_are_looking_to"] + " ?" : "You are Looking To ?"}
            </Typography>
            <Box className={classes.wrapper}>
              {userValues?.offer_type.map((datas, i) => (
                <Typography
                  className={
                    inputs.looking === datas._id
                      ? classes.active_tab
                      : classes.inactive_tab
                  }
                  onClick={() => handleChange("looking", datas._id)}
                >
                  {ln && ln[datas.text.toLowerCase()] ? ln[datas.text.toLowerCase()] : datas.text}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box mb={3}>
            <Typography className={classes.my_role}>
                {ln && ln["what_kind_of_property"] ? ln["what_kind_of_property"] : "What kind of property"}
            </Typography>
            <Box className={classes.wrapper}>
              {userValues?.kind_of_property.map((datas) => (
                <Typography
                  className={
                    inputs.property === datas._id
                      ? classes.active_tab
                      : classes.inactive_tab
                  }
                  onClick={() => {
                    setInputs({ ...inputs, property_types: "" });
                    handleChange("property", datas._id);
                  }}
                >
                  {ln && ln[datas.text.toLowerCase()] ? ln[datas.text.toLowerCase()] : datas.text}
                </Typography>
              ))}
            </Box>
          </Box>
          {inputs.property !== "" &&
            inputs.property != "661cc398267fb2f57d21fba0" && inputs.property !=  "662f64cf68e54862587dffe0" && (
              <Box mb={3}>
                <Typography className={classes.my_role}>
                  {ln && ln["select_property_types"] ? ln["select_property_types"] : "Select property types"}
                </Typography>
                <Box className={classes.wrapper}>
                  {userValues?.property_type.map(
                    (datas) =>
                      datas.topic_type === inputs.property && (
                        <Typography
                          className={
                            inputs.property_types === datas._id
                              ? classes.active_tab
                              : classes.inactive_tab
                          }
                          onClick={() =>
                            handleChange("property_types", datas._id)
                          }
                        >
                          {ln && ln[datas.text.toLowerCase()] ? ln[datas.text.toLowerCase()] : datas.text}
                        </Typography>
                      )
                  )}
                  {/* {inputs.property === "commercial" &&
                property_types_two.map((datas) => (
                  <Typography
                    className={
                      inputs.property_types === datas.value
                        ? classes.active_tab
                        : classes.inactive_tab
                    }
                    onClick={() => handleChange("property_types", datas.value)}
                  >
                    {datas.text}
                  </Typography>
                ))}
              {inputs.property === "plots_lands" &&
                property_types_three.map((datas) => (
                  <Typography
                    className={
                      inputs.property_types === datas.value
                        ? classes.active_tab
                        : classes.inactive_tab
                    }
                    onClick={() => handleChange("property_types", datas.value)}
                  >
                    {datas.text}
                  </Typography>
                ))}
              {inputs.property === "agricultural_farmlands" &&
                property_types_four.map((datas) => (
                  <Typography
                    className={
                      inputs.property_types === datas.value
                        ? classes.active_tab
                        : classes.inactive_tab
                    }
                    onClick={() => handleChange("property_types", datas.value)}
                  >
                    {datas.text}
                  </Typography>
                ))} */}
                </Box>
              </Box>
            )}

          {inputs.property !== "" &&
            inputs.property == "661cc398267fb2f57d21fba0" && (
              <Box mb={3}>
                <Typography className={classes.my_role}>
                  {ln && ln["are_you_builder"] ? ln["are_you_builder"] : "Are you builder"}
                </Typography>
                <Box className={classes.wrapper}>
                  <Typography
                    className={
                      inputs.are_you_builder === "Yes"
                        ? classes.active_tab
                        : classes.inactive_tab
                    }
                    onClick={() => handleChange("are_you_builder", "Yes")}
                  >
                     {ln && ln["yes"] ? ln["yes"] : "Yes"}
                  </Typography>
                  <Typography
                    className={
                      inputs.are_you_builder === "No"
                        ? classes.active_tab
                        : classes.inactive_tab
                    }
                    onClick={() => handleChange("are_you_builder", "No")}
                  >
                     {ln && ln["no"] ? ln["no"] : "No"}
                  </Typography>
                </Box>
              </Box>
            )}

          <Box>
            <Typography className={classes.my_role}>
              
              {ln && ln["your_contact_details"] ? ln["your_contact_details"] : "Your contact details"}
            </Typography>
            {/* <ValidatorForm
              useref="form"
              onSubmit={handleNext}
              className={classes.SignUpForm}
            >
              <StyledInput
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="eg:9774040332"
                name="contact"
                className={`${classes.formInputFeild} my-field`}
                value={inputs.contact}
                size="normal"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{marginRight:"8px"}}>
                      <GreenLockIcon />
                    </InputAdornment>
                  ),
                  // startAdornment: (
                  //   <InputAdornment position="end" style={{marginRight:"4px"}}>
                  //    <Typography sx={{color:"rgba(0,0,0,0.6)"}} > +91</Typography>
                  //   </InputAdornment>
                  // ),
                }}
                onKeyPress={(e) => {
                  Numeric(e);
                }}
                validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                errorMessages={[
                  "Mobile Number is required",
                  "Enter 10 digit valid Mobile number",
                ]}
              />
              
            </ValidatorForm> */}
            {true && <div className={`${classes.new_countrycode_field} ${!isValid && classes.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={inputs.contact}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
            <Box marginTop="40px">
                <Button fullWidth className={classes.continue} onClick={handleNext}>
                   {ln && ln["next"] ? ln["next"] : "Next"}
                </Button>
              </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<BasicDetailDiv />} stepper={0} />
    </>
  );
}

export default PropertyBasicDetails;
