import { Box, Dialog, Typography, Button, Grid, MenuItem, InputAdornment } from "@mui/material";
import "./popup.css";
import { LuAlertOctagon } from "react-icons/lu";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import classes from "../property/property.module.css";
import classesTwo from "./popups.module.css";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { addServiceRequest } from "../../Redux/Actions/userActions";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";
import { CloseIconGreen } from "../../Assets/Icons/icons";
import { isValidEmail, Numeric } from "../../Utilities/commonFunctions";
import { country_codes } from "../../Utilities/commonConstants";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

let our_services = [
  "Legal Opinion",
  "Land Survey",
  "Building Permission",
  "Land Monitoring Services",
  "Layout Approvals",
  "Land Development services",
  "Relationship Manager (NRI)",
];

function AddServicePop(props) {
  const { open, handleClose, serviceName, serviceId } = props;
  const { servicesList } = useSelector((state) => state.userLogin);
  console.log("serviceName =",serviceName)
  const [value, setValue] = useState(null);
  const [autocompletes, setAutocomplete] = useState(null);
  const [serviceSuccess, setServiceSuccess] = useState(false);
  const [serviceFailure, setServiceFailure] = useState(false);
  const [locErr, setLocErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [inputs, setInputs] = useState({
    service: "",
    services_id: serviceId,
    name: "",
    phone: "",
    email: "",
    property_details: "",
    locality: "",
    location_info: {
      lat: "",
      lng: "",
    },
  });
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const dispatch = useDispatch();
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);

  const handleKeyPress = (e, ref) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      ref?.current.focus();
    }
  };

  function handlePopClose() {
    handleClose();
    setCountryCode("1")
    setInputs({
      service: serviceName,
      services_id: serviceId,
      name: "",
      phone: "",
      email: "",
      property_details: "",
      locality: "",
      location_info: {
        lat: "",
        lng: "",
      },
    });
    setServiceFailure(false);
    setServiceSuccess(false);
    setLocErr(false);
    setEmailErr(false);
    setValue(null);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  }

  const handleSelect = async (value) => {
    setLocErr(false);
    setValue(value);
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    setInputs({ ...inputs, location_info: latLng, locality: value?.label });
  };

  function handleClearLoc() {
    setInputs({ ...inputs, location_info: {lat: "",lng: ""}, locality: "" });
    setValue(null);
  }

  function handleAddService() {
    
    if (!value) {
      setLocErr(true);
    } else if (inputs?.phone.length === 0) {
      setIsValid(false);
    }  else if (!isValid) {
      setIsValid(false);
    } else if(inputs.email.length > 0 && !isValidEmail(inputs.email)){
      setEmailErr(true);
    } else {
      let body = {...inputs,country_code: countryCode};
      delete body.service;
      
      dispatch(addServiceRequest(body,setServiceSuccess,setServiceFailure))
    }
  };

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData.iso2);
    setInputs({...inputs,phone:""});
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setInputs({...inputs,phone:value});
  };

  useEffect(()=>{
    if (serviceName) {
      setInputs({...inputs,service:serviceName})
    }
  },[serviceName])

  useEffect(() => {
    if (serviceSuccess) {
      setTimeout(() => {
        handlePopClose();
      }, 2500);
    }
  }, [serviceSuccess]);

  useEffect(() => {
    if (serviceFailure) {
      setTimeout(() => {
        setServiceFailure(false)
      }, 2500);
    }
  }, [serviceFailure]);

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="add-services"
      >
        <Box>
          <ValidatorForm
            useref="form"
            onSubmit={handleAddService}
            className={classes.SignUpForm}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Box>
                  <Typography className={classes.my_role}>Services</Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    select
                    id="emailInput"
                    placeholder="Expected by"
                    className={`${classes.formInputFeild} my-field-three select-field`}
                    name="service"
                    value={inputs.service}
                    size="normal"
                    onChange={handleChange}
                  >
                    {Array.isArray(servicesList) &&
                      servicesList?.map((item, index) => {
                        return (
                          <MenuItem
                            value={item.name}
                            className="feedback-type-list"
                            // onClick={(e) =>
                            //   handleChange(
                            //     "expected_time_of_possession",
                            //     item._id
                            //   )
                            // }
                          >
                            <p className={`${classes.selectAge}`}>
                              {item.name}
                            </p>
                          </MenuItem>
                        );
                      })}
                  </TextValidator>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Typography className={classes.my_role}>Name</Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="eg: arun"
                    className={`${classes.formInputFeild} my-field-three`}
                    name="name"
                    value={inputs.name}
                    size="normal"
                    onChange={handleChange}
                    validators={["required"]} // Add this line
                    errorMessages={["Name is required"]}
                    inputRef={input1}
                    onKeyPress={(e) => handleKeyPress(e, input2)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Box className="d-flex-between">
                    <Typography className={classes.my_role}>
                      Location
                    </Typography>
                   {inputs.locality != "" &&
                    <Typography
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={handleClearLoc}
                    >
                      Clear
                    </Typography>}
                  </Box>
                  <Box width={"100%"}>
                    <GooglePlacesAutocomplete
                      selectProps={{
                        value,
                        onChange: handleSelect,
                        placeholder: "Search for a place...",
                      }}
                      
                    />
                    {locErr && (
                    <span className="feedback-type">
                      Please Select Location
                    </span>
                  )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Typography className={classes.my_role}>
                    Phone Number
                  </Typography>
                  {/* <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="eg: 8224556510"
                    className={`${classes.formInputFeild} my-field-three`}
                    name="phone"
                    value={inputs.phone}
                    size="normal"
                    onChange={handleChange}
                    InputProps={{
                      startAdornment:  ( // Conditionally render input adornment
                        <InputAdornment position="start" style={{width:"80px"}} >
                          <TextValidator
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    select
                                    id="emailInput"
                                    placeholder="Expected by"
                                    className={` country-code`}
                                    name="expected_time_of_possession"
                                    value={countryCode}
                                    size="normal"
                                  >
                                    {country_codes.map((item, index) => {
                                      return (
                                        <MenuItem
                                          value={item}
                                          key={index}
                                          onClick={(e) =>
                                            setCountryCode(item)
                                          }
                                          style={{ textTransform: "capitalize" }}
                                          className={`${classes.code_list} all-codes`}
                                        >
                                          +{item}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextValidator>
                        </InputAdornment>
                      ),
                    }}
                    validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                  /> */}
                  {selectedCountry && <div className={`${classesTwo.new_countrycode_field} ${!isValid && classesTwo.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={inputs?.phone}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classesTwo.err_text}>Enter Valid number</p>}
                  </div>}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box>
                  <Typography className={classes.my_role}>Email ID (optional)</Typography>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="eg: arun@gmail.com"
                    className={`${classes.formInputFeild} my-field-three`}
                    name="email"
                    value={inputs.email}
                    size="normal"
                    onChange={(e)=>{
                      setEmailErr(false);
                      handleChange(e)
                    }}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    inputRef={input2}
                    onKeyPress={(e) => handleKeyPress(e, input3)}
                  />
                  {emailErr && (
                    <span className="feedback-type">
                      Please enter valid email
                    </span>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box mb={2}>
                  <Typography className={classes.my_role}>
                    Enter details
                  </Typography>

                  <TextValidator
                    variant="outlined"
                    multiline
                    rows={2}
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Enter features/add some descriptions..."
                    className={`${classes.formInputFeild} my-field-three`}
                    name="property_details"
                    value={inputs.property_details}
                    size="normal"
                    onChange={handleChange}
                    // InputProps={{
                    //   startAdornment: (
                    //     <InputAdornment position="start">
                    //       <TextField select>
                    //         <MenuItem>+91</MenuItem>
                    //         <MenuItem>+91</MenuItem>
                    //       </TextField>
                    //     </InputAdornment>
                    //   ),
                    // }}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    validators={["required"]} // Add this line
                    errorMessages={["Details is required"]}
                    inputRef={input3}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box
              marginTop="10px"
              display={"flex"}
              alignItems={"center"}
              gap="20px"
            >
              <Button className={"add-services-cancel"} onClick={handlePopClose}>
                Cancel
              </Button>
              <Button className={"add-services-submit"} type="submit">
                Submit
              </Button>
            </Box>
            {serviceSuccess && (
                  <Typography
                    className="feedback-success d-flex-center"
                    gap={"8px"}
                  >
                    <FaCircleCheck /> Service Added Successfully
                  </Typography>
                )}
                {serviceFailure && (
                  <Typography
                    className="feedback-failure d-flex-center"
                    gap={"8px"}
                  >
                   <IoCloseCircle />  Something went wrong. Try again.
                  </Typography>
                )}
          </ValidatorForm>
        </Box>
        <Typography
            className="service-pop-close"
            onClick={handlePopClose}
          >
            <CloseIconGreen />
          </Typography>
      </Dialog>
    </>
  );
}

export default AddServicePop;
