import {
    Box,
    Dialog,
    Typography,
    Button
  } from "@mui/material";
  import "./popup.css";
  import { LuAlertOctagon } from "react-icons/lu";
  
  function DeleteConfirmation(props) {
    const { open, handleClose,handleDeleteProperty,selectedProperty } = props;
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete_popup"
        >
          <Box textAlign={"center"} >
            <LuAlertOctagon className='alert-icon' />
            <Typography className="content" >
               <b> Are you sure you want to {selectedProperty?.property_status === "Active" ? "deactivate" : "activate"} ? </b> <br />
               {/* <small>NOTE: This changes cannot be undo</small> */}
            </Typography>
            <Box className="d-flex" gap={"15px"} justifyContent={"center"} mt={6} >
                <Button className='no' onClick={handleClose} >
                    No
                </Button>
                <Button className='yes' onClick={handleDeleteProperty} >
                    Yes
                </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  }
  
  export default DeleteConfirmation;
  