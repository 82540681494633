import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import Router from "./routes";
import store from "./Redux/store";
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LoadScript } from "@react-google-maps/api";

const libraries = ["places"];

function App() {
  useEffect(()=>{
    AOS.init();
  },[])
  return (
    <div className="App">
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY} libraries={libraries}>
      <Provider store={store}>
        <RouterProvider router={Router} />
      </Provider>
      </LoadScript>
    </div>
  );
}

export default App;