import { Box, Dialog, Typography, Button, Tabs, Tab } from "@mui/material";
import "./popup.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Build from "../../Assets/Images/building-1.png";
import { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

let arr = [1, 2, 3, 4, 5];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

function ImageSlider(props) {
  const { open, handleClose, propertyDetails } = props;
  const [tabValue, setTabValue] = useState(0);
  const [myTabs, setMyTabs] = useState([]);
  const [sliderImgs, setSliderImgs] = useState([]);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  useEffect(() => {
    if (open) {
    let arr = [];
    propertyDetails?.allphotos?.forEach((val) => {
      if (!arr.includes(val.type)) {
        arr.push(val.type);
      }
    });
    setMyTabs(arr);
  }
  }, [open]);

  useEffect(() => {
    if (open) {
      
      let filtered = propertyDetails?.allphotos?.filter(
        (val) => val.type === myTabs[tabValue]
      );
      setSliderImgs(filtered);
    }
  }, [tabValue, open, myTabs]);

  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="image-slider"
      >
        <Box height={"100%"} pb={2} className="single-property-images">
        <Box
            className="d-flex-between popup-header"
            flexWrap={"wrap"}
          >
            <Typography fontSize={"20px"}>Property photos/videos</Typography>
            <IoIosCloseCircleOutline
              className={"contact-close"}
              onClick={handleClose}
            />
          </Box>
         <Box p={3}>
         <Tabs
            value={tabValue}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            sx={{ marginBottom: "8px" }}
          >
            {Array.isArray(myTabs) &&
              myTabs.map((element, i) => (
                <Tab
                  value={i}
                  label={
                    myTabs[tabValue] === element
                      ? `${element} (${sliderImgs?.length})`
                      : element
                  }
                />
              ))}
          </Tabs>
          <Carousel
            responsive={responsive}
            autoPlaySpeed={1500}
            infinite={true}
            arrows={true}
            className="carousel-wrapper"
          >
            {Array.isArray(sliderImgs) &&
              sliderImgs.map((datas, index) => (
                // propertyDetails?.photos[tabValue]?.type === datas.type &&
                <Box width={"90%"} height={"391px"} margin={"auto"}>
                  {datas.mime_type?.split("/")[1]?.toLowerCase() === "mp4" ? (
                    <video
                      src={`${process.env.REACT_APP_API_URL}/${datas.video_name}`}
                      controls
                      width={"100%"}
                      height={"100%"}
                    ></video>
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/${datas.image}`}
                      alt="building"
                      width={"100%"}
                      height={"100%"}
                      style={{objectFit:'contain'}}
                    />
                  )}
                </Box>
              ))}
          </Carousel>
         </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ImageSlider;
