import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Chip } from "@mui/material";
import classes from "./home.module.css";
import classesOne from "../property/property.module.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ChatIcon, ContactIcon, HeartIcon, WhatsappIcon } from "../../Assets/Icons/icons";
import "./home.css";
import { compareDatesWithin24Hours, formatNumber, getDecryptedCookie } from "../../Utilities/commonFunctions";
import { useNavigate } from "react-router-dom";
import ContactPop from "../popup/ContactPop";
import ContactOwnerPop from "../popup/ContactOwnerPop";
import { addWishlist, savePropertyCount } from "../../Redux/Actions/userActions";
import GuestUserPop from "../popup/GuestUserPop";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 850 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 850, min: 0 },
    items: 1,
  },
};

function PropertySlider(props) {
  const { properties, title,handleWishlist } = props;
  const [contactPopup, setContactPopup] = useState(false);
  const [whatsAppSuccess, setWhatsAppSuccess] = useState(false);
  const [guestPopup, setGuestPopup] = useState(false);
  const [guestVerified, setGuestVerified] = useState(false);
  const [media, setMedia] = useState("");
  const [whatsappURL, setWhatsappURL] = useState("");
  const [propertySingleStore, setPropertySingleStore] = useState(null);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const [getContact, setGetContact] = useState({
    name: "",
    phone: "",
    email: "",
  });
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  const decryptedData = getDecryptedCookie('device-infos', process.env.REACT_APP_DEVICE_KEY);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleSelectCover(photos) {
    let property_photos = photos;
    let cover_img = "";

    property_photos.forEach((val) => {
      if (val?.is_cover_image) {
        cover_img = val?.image;
      } else {
        if (cover_img === "") {
          cover_img = val?.image;
        }
      }
    });

    return `${process.env.REACT_APP_API_URL}/${cover_img}`;
  }

  const handleContactPopClose = () => {
    setContactPopup(false);
    setContactPopupDetails({ phone: "", email: "", name: "" });
  };

  const handleGetContact = (e) => {
    const { name, value } = e.target;
    setGetContact({ ...getContact, [name]: value });
  };

  const handleSmsClicked = () =>{
    // setGuestPopup(true);
  }

  function handleCheckHrs() {

    // Example dates
const saved_date = dayjs(decryptedData?.c_date);
const current_date = dayjs();

// Compare dates
if (compareDatesWithin24Hours(saved_date,current_date)) {
  console.log('within 24 hrs');
} else {
  console.log('morethan 24 hrs');
}

  }

  const handleCallClicked = (datas)=>{
    setPropertySingleStore(datas);
    setContactPopupDetails({
      phone: datas?.created_user?.phone,
      email: datas?.created_user?.email,
      name: datas?.created_user?.name,
    });
    if (_token) {
      
      let save_count_body ={
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      // setContactPopup(true);
      dispatch(savePropertyCount(save_count_body,setContactPopup))
    } else if( compareDatesWithin24Hours(decryptedData?.c_date) ) {
      let save_count_body ={
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      dispatch(savePropertyCount(save_count_body,setContactPopup))
    } 
    else {
      setMedia("call");
      setGuestPopup(true);
    }
  };

  async function handleWhatsAppClicked(user_detail) {
    setPropertySingleStore(user_detail);
    let _content = `Hai ${user_detail?.created_user?.name},`;
    const prefilledMessage = await encodeURIComponent(_content);
    setWhatsappURL(`https://wa.me/${user_detail?.created_user?.phone}?text=${prefilledMessage}`);
    if (_token) {
      
      let save_count_body ={
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      dispatch(savePropertyCount(save_count_body,setWhatsAppSuccess))



    }  else if( compareDatesWithin24Hours(decryptedData?.c_date) ) {
      let save_count_body ={
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      dispatch(savePropertyCount(save_count_body,setWhatsAppSuccess))
    } else {
      setMedia("whatsapp");
      setGuestPopup(true);
    }

  }

  useEffect(()=>{
    // console.log("decryptedData =",decryptedData)
  },[decryptedData])

  useEffect(()=>{
    if (whatsAppSuccess) {
      setWhatsAppSuccess(false);
      setTimeout(()=>{
        window.open(whatsappURL, '_blank');
      },1000)
    }
  },[whatsAppSuccess])

  useEffect(()=>{
    
    if (guestVerified && media == "call") {
      setGuestVerified(false);
        setMedia("");
        // setContactPopup(true);
        let save_count_body ={
          property_id: propertySingleStore?._id,
          device_id: decryptedData?.device_id,
          type: "contact"  //contact or view
        };

        dispatch(savePropertyCount(save_count_body,setContactPopup))
    } else if (guestVerified && media == "whatsapp") {
      setGuestVerified(false);
      setMedia("");

      let save_count_body ={
        property_id: propertySingleStore?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };

      dispatch(savePropertyCount(save_count_body,setWhatsAppSuccess))
      // window.open(whatsappURL, '_blank');
  } 
  },[guestVerified])

  useEffect(() => {
    let Con_details = JSON.parse(localStorage.getItem("userToken"));
    if (Con_details) {
      setGetContact({...getContact,name:Con_details?.name,phone:Con_details?.phone,email:Con_details?.email})
    }
  }, []);


  return (
    <>
      <Box my={3}>
        <Carousel
          responsive={responsive}
          autoPlaySpeed={1500}
          infinite={true}
          arrows={true}
          className="carousel-wrapper"
        >
          {Array.isArray(properties) &&
            properties.map(
              (datas, index) =>
                (title === datas.kind_of_property || title === "all") && (
                  <Box className={classes.single_property_view}>
                    <Box
                      className={classes.single_property_view_img}
                      onClick={() => navigate(`/singleproperty/${datas._id}`)}
                    >
                      <img
                        src={
                          datas?.photos[0]?.image
                            ? handleSelectCover(datas.photos)
                            : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                        }
                        alt="image"
                        height={"100%"}
                        width={"100%"}
                      />
                    </Box>
                    <Box p={2}>
                      <Typography className={classes.single_property_title}>
                        {datas.created_user?.name}
                      </Typography>
                      <Typography className={classes.single_property_location}>
                        {datas?.location || "n/a"}
                      </Typography>
                      <Typography
                        className={classes.single_property_highlights}
                        height={"30px"}
                      >
                        Highlights :
                        {datas.other_features.slice(0, 2).map((el) => (
                          <Chip label={el} />
                        ))}
                        <span className={classes.single_property_highlights}>
                          {datas.other_features.length - 2 > 0
                            ? `+${datas.other_features.length - 2}`
                            : ""}
                        </span>
                      </Typography>
                      <Typography className={classes.single_property_feet}>
                        {datas?.area_of_property} Sq.Ft.
                      </Typography>
                      <Box >
                        <Typography className={classes.single_property_price}>
                          ₹ {formatNumber(datas.price?.expected_price)}{" "}
                          <span>Onwards</span>
                        </Typography>
                        <Box className="d-flex-between" marginTop={"10px"} >
                        <Button 
                               className={classes.contact_btns}
                              //  href= {`https://wa.me/+91${datas?.created_user?.phone}`}
                              // url="https://web.whatsapp.com/"
                              // target="_blank"
                              onClick={()=>handleWhatsAppClicked(datas)}
                               >
                                <WhatsappIcon /> WhatsApp
                              </Button>
                              <Button className={classes.contact_btns} onClick={handleSmsClicked} >
                                <ChatIcon /> SMS
                              </Button>
                              <Button
                          className={classes.contact_btns}
                          onClick={() =>handleCallClicked(datas)}
                        >
                          <ContactIcon /> Call
                        </Button>
                        </Box>
                      
                      </Box>
                    </Box>
                    <Typography
                      className={`${datas?.likedByUser && classes.active_heart} ${classes.slider_heart_icon}`}
                      onClick={()=>handleWishlist(datas._id,title)}
                    >
                      <HeartIcon />
                    </Typography>
                  </Box>
                )
              // </Grid>
            )}
        </Carousel>
        {/* <ContactOwnerPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          handleGetContact={handleGetContact}
          getContact={getContact}
        /> */}
        <ContactPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          // handleGetContact={handleGetContact}
          // getContact={getContact}
        />
        {guestPopup && <GuestUserPop
          open={guestPopup}
          handleClose={()=>setGuestPopup(false)}
          media={media}
          setGuestVerified={setGuestVerified}
        />}
        
      </Box>
    </>
  );
}

export default PropertySlider;
