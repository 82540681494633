import React, { useState, useEffect } from "react";
import classes from "./auth.module.css";
import { Card, Typography, InputAdornment, Button, MenuItem } from "@mui/material";
import DefaultLayout from "./defaultLayout";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { generateUniqueId, getDecryptedCookie, Numeric } from "../../Utilities/commonFunctions";
import { CloseIcon, DownChevorletIcon } from "../../Assets/Icons/icons";
import { StyledInput } from "../../Components/commonInputFeild";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { pageLoader, userRegister } from "../../Redux/Actions/userActions";
import AlertBox from "../../Components/AlertBox";
import { country_codes } from "../../Utilities/commonConstants";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

const RegisterScreen = () => {
  const RegisterDiv = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorAlert, setErrorAlert] = useState(false);
    const [phone, setPhone] = useState("");
    const [isInputFocused, setInputFocused] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [countryCode, setCountryCode] = useState("91");
    const [selectedCountry, setSelectedCountry] = useState("in");
    const { error, ln } = useSelector((state) => state.userLogin);

    const handleFocus = () => {
      setInputFocused(true);
    };

    const closeAlert = () => {
      setErrorAlert(false);
    };

    const handleBlur = () => {
      if (phone === "") {
        setInputFocused(false);
      }
    };

    const handleChange = (e) => {
      const inputValue = e.target.value;
      if (inputValue.length <= 10) {
        setPhone(inputValue);
      }
    };

    const handleSubmit = async() => {
       if (phone?.length === 0) {
        setIsValid(false);
      }  else if (!isValid) {
        setIsValid(false);
      } else{
        let obj = {
          selectedCountry,
          countryCode,
          phone
        };
        sessionStorage.setItem("registerInfos",JSON.stringify(obj));
  
        if (countryCode == 91) {
          let deviceIdInfos = JSON.parse(localStorage.getItem("device-id"));
          const decryptedData = getDecryptedCookie('device-infos', process.env.REACT_APP_DEVICE_KEY);
          if (decryptedData?.phone == phone) {
            const userEntry = {
              phone: phone,
              country_code:countryCode,
              country_id:selectedCountry,
              device_id:decryptedData?.device_id
            };
            dispatch(pageLoader(true));
            dispatch(userRegister(setErrorAlert, userEntry, navigate));
            dispatch(pageLoader(false));
          } else {
            let new_deviceId = await generateUniqueId();
            const userEntry = {
              phone: phone,
              country_code:countryCode,
              device_id:new_deviceId,
              country_id:selectedCountry,
            };
            dispatch(pageLoader(true));
            dispatch(userRegister(setErrorAlert, userEntry, navigate));
            dispatch(pageLoader(false));
          }
  
        } else {
          navigate("/personal-details");
        }
      }
    };

    
  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    console.log("countryData =",countryData)
    setCountryCode(countryData?.dialCode);
    setSelectedCountry(countryData.iso2);
    setPhone("");
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setPhone(value);
  };

    
    return (
      <Card className={classes.registerCard}>
        <Typography variant="h5" className={classes.registerHeader}>
          {ln && ln["register"] ? ln["register"] : "Register"}
        </Typography>
        <Button
          className={classes.closeButton}
          variant="text"
          onClick={() => navigate(-1)}
        >
          <CloseIcon />
        </Button>
        <ValidatorForm
          useref="form"
          onSubmit={handleSubmit}
          className={classes.SignUpForm}
        >
          {/* <StyledInput
            label={
              ln && ln["enter_mobile_number"]
                ? ln["enter_mobile_number"]
                : "Enter Mobile Number"
            }
            variant="standard"
            fullWidth
            id="emailInput"
            placeholder=""
            className={`${classes.formInputFeild} ${
              isInputFocused && classes.FocusedClass
            }`}
            value={phone}
            size="normal"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{
              startAdornment:  ( // Conditionally render input adornment
                <InputAdornment position="start">
                  <TextValidator
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            select
                            id="emailInput"
                            placeholder="Expected by"
                            className={` country-code`}
                            name="expected_time_of_possession"
                            value={countryCode}
                            size="normal"
                          >
                            {country_codes.map((item, index) => {
                              return (
                                <MenuItem
                                  value={item}
                                  key={index}
                                  onClick={(e) =>
                                    setCountryCode(item)
                                  }
                                  style={{ textTransform: "capitalize" }}
                                  className={`${classes.code_list} all-codes`}
                                >
                                  +{item}
                                </MenuItem>
                              );
                            })}
                          </TextValidator>
                </InputAdornment>
              ),
            }}
            onKeyPress={(e) => {
              Numeric(e);
            }}
            validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
          /> */}
          {selectedCountry && <div className={`${classes.new_countrycode_field} ${!isValid && classes.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={phone}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classes.err_text}>Enter Valid number</p>}
                  </div>}
          {countryCode == 91 &&
            <Button
            type="submit"
            variant="contained"
            fullWidth
            // disabled={phone.length !== 10}
            // className={`mt-4 
            //   ${classes.continueBtn} 
            //   ${phone.length !== 10 && classes.disabledBtn }
            //   `}
              className={`mt-4 
                ${classes.continueBtn} 
                `}
          >
            {ln && ln["send_otp"] ? ln["send_otp"] : "Send OTP"}
          </Button>}
          {countryCode != 91 &&
          <div className="mt-4" >
            <Typography>Do you want to login as an NRI? </Typography>
         
            <Button
            type="submit"
            variant="contained"
            fullWidth
            // disabled={phone.length !== 10}
            // className={`mt-4 
            //   ${classes.continueBtn} 
            //   ${phone.length !== 10 && classes.disabledBtn }
            //   `}
              className={`mt-2 
                ${classes.continueBtn} 
                `}
          >
            Yes
          </Button>
          </div>
          }
        </ValidatorForm>
        <Typography className={classes.NoteText}>
          {ln && ln["already_have_account"]
            ? ln["already_have_account"]
            : "Already Have an Account?"}{" "}
          <span onClick={() => navigate("/login")}>
            {ln && ln["login"] ? ln["login"] : "Login"}
          </span>
        </Typography>
        {errorAlert && error && (
          <AlertBox type={"error"} message={error} stateName={closeAlert} />
        )}
      </Card>
    );
  };

  return <DefaultLayout children={<RegisterDiv />} />;
};

export default RegisterScreen;
