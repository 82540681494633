module.exports = {
  languagesList: [
    {
      name: "Telugu",
      code: "te",
      text: "అ",
      sub_title:"తెలుగు"
    },
    {
      name: "English",
      code: "en",
      text: "A",
      sub_title:""
    },
    {
      name: "Hindi",
      code: "hi",
      text: "अ",
      sub_title:"हिंदी"
    },
    {
      name: "Kannada",
      code: "kn",
      text: "అ",
      sub_title:"ಕನ್ನಡ"
    },
  ],
};

module.exports.country_codes = [
  "1", "1242", "1246", "1264", "1268", "1345", "1441", "1473", "1649", "1664", 
  "1758", "1787", "1809", "1868", "1869", "1876", "20", "212", "213", "216", 
  "218", "220", "221", "222", "223", "224", "226", "227", "228", "229", "231", 
  "232", "233", "234", "236", "237", "238", "239", "240", "241", "242", "244", 
  "245", "248", "249", "250", "251", "252", "253", "254", "256", "257", "258", 
  "260", "261", "262", "263", "264", "265", "266", "267", "268", "269", "27", 
  "290", "291", "297", "298", "299", "30", "31", "32", "33", "34", "350", 
  "351", "352", "353", "354", "356", "357", "358", "359", "36", "370", "371", 
  "372", "373", "374", "375", "376", "377", "378", "379", "380", "381", "385", 
  "386", "387", "389", "39", "40", "41", "417", "42", "421", "43", "44", "45", 
  "46", "47", "48", "49", "500", "501", "502", "503", "504", "505", "506", 
  "507", "509", "51", "52", "53", "54", "55", "56", "57", "58", "590", "591", 
  "592", "593", "594", "595", "596", "597", "598", "60", "61", "62", "63", 
  "64", "65", "66", "670", "671", "672", "673", "674", "675", "676", "677", 
  "678", "679", "680", "681", "682", "683", "686", "687", "688", "689", "691", 
  "692", "7", "7880", "81", "82", "84", "850", "852", "853", "855", "856", 
  "86", "880", "886", "90", "90392", "91", "94", "95", "960", "961", "962", 
  "963", "964", "965", "966", "967", "968", "969", "971", "972", "973", "974", 
  "975", "976", "977", "98", "993", "994", "996"
];
