import { Navigate, createBrowserRouter } from "react-router-dom";
import GuestLayout from "./Layout/guestLayout";
import Login from "./Pages/Auth/login";
import Home from "./Pages/Home/home";
import DefaultLayout from "./Layout/defaultLayout";
import LanguageSelect from "./Pages/Auth/languageSelect";
import RegisterScreen from "./Pages/Auth/registerScreen";
import OtpVerification from "./Pages/Auth/otpVerification";
import PersonalDetails from "./Pages/Auth/perdonalDetails";
import HomePage from "./Pages/Home/home";
import HomeLayout from "./Layout/homeLayout";
import PropertyLayout from "./Layout/propertyLayout";
import PropertyLandingPage from "./Pages/property/PropertyLandingPage";
import PropertyBasicDetails from "./Pages/property/PropertyBasicDetails";
import PropertyRegister from "./Pages/property/PropertyRegister";
import PropertyRegisterVerify from "./Pages/property/PropertyRegisterVerify";
import PropertyAddDetails from "./Pages/property/PropertyAddDetails";
import PropertyAddPhotos from "./Pages/property/PropertyAddPhotos";
import PropertyUploadedImgs from "./Pages/property/PropertyUploadedImgs";
import PropertyPricingDetails from "./Pages/property/PropertyPricingDetails";
import PropertyAddAmenities from "./Pages/property/PropertyAddAmenities";
import PropertyCreated from "./Pages/property/PropertyCreated";
import PropertyListing from "./Pages/property/PropertyListing";
import SinglePropertyDetails from "./Pages/property/SinglePropertyDetails";
import SearchResults from "./Pages/Home/SearchResults";
import SavedProperty from "./Pages/Home/SavedProperty";
import Plans from "./Pages/Home/Plans";
import NriServices from "./Pages/others/NriServices";
import TermsOfUse from "./Pages/others/TermsOfUse";
import PrivacyPolicy from "./Pages/others/PrivacyPolicy";
import ConnectWithUs from "./Pages/others/ConnectWithUs";
import AboutUs from "./Pages/others/AboutUs";
import NewsPage from "./Pages/others/NewsPage";
import SingleNews from "./Pages/others/SingleNews";


const Router = createBrowserRouter([
    {
        path: "/",
        element: <GuestLayout />,
        children: [
            {
                path: "/",
                element: <Navigate to="/login" />,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/login-otp-verification",
                element: <OtpVerification />,
            },
            {
                path: "/register",
                element: <RegisterScreen />,
            },
            {
                path: "/register-otp-verification",
                element: <OtpVerification />,
            },
            {
                path: "/personal-details",
                element: <PersonalDetails />,
            },
            {
                path: "/language-select",
                element: <LanguageSelect />,
            },
        ],
    },
    {
        path: "/",
        element: <DefaultLayout />,
        children: [
            {
                path: "/",
                element: <Navigate to="/home" />,
            },
            {
                path: "/home",
                element: <Home />,
            },
        ],
    },
    {
        path: "/",
        element: <HomeLayout />,
        children: [
            {
                path: "/homepage",
                element: <HomePage /> ,
            },
            {
                path: "/alllistings",
                element: <PropertyListing /> ,
            },
            {
                path: "/singleproperty/:id",
                element: <SinglePropertyDetails /> ,
            },
            {
                path: "/search-results/:id",
                element: <SearchResults /> ,
            },
            {
                path: "/savedproperty",
                element: <SavedProperty /> ,
            },
            {
                path: "/plans",
                element: <Plans /> ,
            },
            {
                path: "/nri-services/:id",
                element: <NriServices /> ,
            },
            {
                path: "/terms-of-use",
                element: <TermsOfUse /> ,
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicy /> ,
            },
            {
                path: "/connect-withus",
                element: <ConnectWithUs /> ,
            },
            {
                path: "/about",
                element: <AboutUs /> ,
            },
            {
                path: "/news",
                element: <NewsPage /> ,
            },
            {
                path: "/single-news",
                element: <SingleNews /> ,
            },
        ]
    },
    {
        path: "/",
        element: <PropertyLayout />,
        children: [
            {
                path: "/postproperty",
                element: <PropertyLandingPage /> ,
            },
            {
                path: "/propertybasicdetails",
                element: <PropertyBasicDetails /> ,
            },
            {
                path: "/propertyregister",
                element: <PropertyRegister /> ,
            },
            {
                path: "/propertyregisterverify",
                element: <PropertyRegisterVerify /> ,
            },
            {
                path: "/propertyadddetails",
                element: <PropertyAddDetails /> ,
            },
            {
                path: "/propertyaddphotos",
                element: <PropertyAddPhotos /> ,
            },
            {
                path: "/propertyuploadphotos",
                element: <PropertyUploadedImgs /> ,
            },
            {
                path: "/propertypricingdetails",
                element: <PropertyPricingDetails /> ,
            },
            {
                path: "/propertyaddamenities",
                element: <PropertyAddAmenities /> ,
            },
        ]
    },
    {
        path: "/created",
        element: <PropertyCreated />
    },
]);

export default Router;