import { Typography } from "@mui/material";
import { WhiteDownArrowIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import "./dropdown.css";
import { useSelector } from "react-redux";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { useState } from "react";
import AlertBox from "../../Components/AlertBox";

function Dropdown() {
  const [nriErr,setNriErr] = useState(false);
  const navigate = useNavigate();
  const { allProperties, ln, servicesList } = useSelector(
    (state) => state.userLogin
  );
  const _token = JSON.parse(localStorage.getItem("userToken"));

  function handleNriServices() { 
    if (_token?.country_code == 91 || _token?.country_code === "+91") {
      setNriErr(true);
    } else {
      navigate(`/nri-services/669209c719f9e6aaaeffc742`);
    }
  }
  return (
    <>
      <Typography className="dropdown dropdown-8">
        {ln && ln["services"] ? ln["services"] : "Services"}{" "}
        <span>
          <WhiteDownArrowIcon />
        </span>{" "}
        <ul className="dropdown_menu dropdown_menu--animated dropdown_menu-8">
          {Array.isArray(servicesList) &&
            servicesList?.map((datas) => (
              <li
                className="dp-list"
                onClick={() => {
                  navigate(`/nri-services/${datas?._id}`);
                }}
              >
                <span className="one">
                  {datas?.name} <FaArrowRightLong className="arr" />
                </span>
              </li>
            ))}
          {/* <li className="dp-list">
            <span className="one">
              Legal Opinion <FaArrowRightLong className="arr" />
            </span>
          </li>
          <li className="dp-list">
            <span className="one">
              Land Survey <FaArrowRightLong className="arr" />
            </span>
          </li>
          <li className="dp-list">
            <span className="one">
              Building Permission <FaArrowRightLong className="arr" />
            </span>
          </li>
          <li className="dp-list">
            <span className="one">
              Land Monitoring Services <FaArrowRightLong className="arr" />
            </span>
          </li>
          <li className="dp-list">
            <span className="one">
              Layout Approvals <FaArrowRightLong className="arr" />
            </span>
          </li>
          <li className="dp-list">
            <span className="one">
              Land Development services <FaArrowRightLong className="arr" />
            </span>
          </li> */}
        </ul>
      </Typography>
      <Typography onClick={handleNriServices}>
        {ln && ln["nri_services"] ? ln["nri_services"] : "NRI Services"}
      </Typography>
      <Typography onClick={() => navigate("/plans")}>
        {ln && ln["plans"] ? ln["plans"] : "Plans"}
      </Typography>
      <Typography onClick={() => navigate("/savedproperty")}>
        {ln && ln["saved"] ? ln["saved"] : "Saved"}
      </Typography>

      {nriErr && (
          <AlertBox type={"error"} message={"NRI Services is allowed only for NRI Users"} stateName={()=>setNriErr(false)} />
        )}
    </>
  );
}

export default Dropdown;
