import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Radio,
  InputAdornment,
} from "@mui/material";
import "./popup.css";
import { CloseIconGreen, ContactIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useEffect, useRef, useState } from "react";
import classes from "../property/property.module.css";
import classesTwo from "./popups.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { addFeedback } from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";
import { country_codes } from "../../Utilities/commonConstants";
import { Numeric } from "../../Utilities/commonFunctions";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

let _feedbacks = [
  "I want to report a problem",
  "I have a suggestion",
  "I want to compliment the app",
  "Other",
];

function FeedbackPop(props) {
  const { open, handleClose } = props;

  const [feedTypeErr, setFeedTypeErr] = useState(false);
  const [feedSuccess, setFeedSuccess] = useState(false);
  const [feedFailure, setFeedFailure] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState({
    feedback_type: "-1",
    feedback_description: "",
    email: "",
    name: "",
    phone: "",
  });
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const dispatch = useDispatch();
  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);

  const handleKeyPress = (e, ref) => {
    if (e.key === 'Enter') {
      e.preventDefault(); 
      ref?.current.focus();
    }
  };

  function handlePopClose() {
    handleClose();
    setFeedbackInfo({
      feedback_type: "-1",
      feedback_description: "",
      email: "",
      name: "",
      phone: "",
    });
    setFeedFailure(false);
    setFeedSuccess(false);
    setFeedTypeErr(false);
    setCountryCode("1");
  }

  function handleChange(e) {
    const { value, name } = e.target;
    setFeedbackInfo({ ...feedbackInfo, [name]: value });
  }

  function handleAddFeedback() {
    if (feedbackInfo.feedback_type === "-1") {
      setFeedTypeErr(true);
    }else if (feedbackInfo?.phone.length === 0) {
      setIsValid(false);
    }  else if (!isValid) {
      setIsValid(false);
    }  else {
      let body = { ...feedbackInfo,country_code: countryCode };
      dispatch(addFeedback(body, setFeedSuccess,setFeedFailure));
    }
  }

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData.iso2);
    setFeedbackInfo({...feedbackInfo,phone:""});
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setFeedbackInfo({...feedbackInfo,phone:value});
  };

  useEffect(() => {
    if (feedSuccess) {
      setTimeout(() => {
        handlePopClose();
      }, 2500);
    }
  }, [feedSuccess]);

  useEffect(() => {
    if (feedFailure) {
      setTimeout(() => {
        setFeedFailure(false)
      }, 2500);
    }
  }, [feedFailure]);

  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="feedback-pop"
      >
        <Box>
          <Typography
            sx={{ cursor: "pointer",float:"right" }}
            onClick={handlePopClose}
          >
            <CloseIconGreen />
          </Typography>
          <Box px={2}>
            <Typography
              fontSize={"23px"}
              fontWeight={"600"}
              sx={{ color: "var(--border-colour)" }}
            >
              Feedbacks
            </Typography>
            <Typography>Share your feedback below</Typography>
            <Box mt={3}>
              <ValidatorForm
                useref="form"
                onSubmit={handleAddFeedback}
                className={classes.SignUpForm}
              >
                <Box mb={3}>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    select
                    id="emailInput"
                    placeholder="Expected by"
                    className={`${classes.formInputFeild} my-field-two select-field`}
                    name="feedback_type"
                    value={feedbackInfo.feedback_type}
                    size="normal"
                    onChange={(e) => {
                      setFeedTypeErr(false);
                      handleChange(e);
                    }}
                    validators={["required"]} // Add this line
                    errorMessages={[true && "Description is required"]}
                    // helperText={"ssss"}
                  >
                    <MenuItem value={"-1"} hidden>
                      <p style={{ color: "rgba(0,0,0,0.3)" }}>
                        Select Feedback
                      </p>
                    </MenuItem>
                    {_feedbacks.map((item, index) => {
                      return (
                        <MenuItem
                          value={item}
                          className="feedback-type-list"
                          // onClick={(e) =>
                          //   handleChange(
                          //     "expected_time_of_possession",
                          //     item._id
                          //   )
                          // }
                        >
                          <p className={`${classes.selectAge}`}>{item}</p>
                        </MenuItem>
                      );
                    })}
                  </TextValidator>
                  {feedTypeErr && (
                    <span className="feedback-type">
                      Feedback type is required
                    </span>
                  )}
                </Box>
                <Box mb={2}>
                  <TextValidator
                    variant="outlined"
                    multiline
                    rows={2}
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Write your feedback"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="feedback_description"
                    onChange={handleChange}
                    value={feedbackInfo.feedback_description}
                    size="normal"
                    validators={["required"]} // Add this line
                    errorMessages={["Description is required"]}
                    inputRef={input1}
                    onKeyPress={(e) => handleKeyPress(e, input2)}
                  />
                </Box>
                <Box mb={2}>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Your Name"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="name"
                    value={feedbackInfo.name}
                    size="normal"
                    onChange={handleChange}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    validators={["required"]} // Add this line
                    errorMessages={["Name is required"]}
                    inputRef={input2}
                    onKeyPress={(e) => handleKeyPress(e, input3)}
                  />
                </Box>
                <Box mb={2}>
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Your Email (optional)"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="email"
                    value={feedbackInfo.email}
                    size="normal"
                    onChange={handleChange}
                    inputRef={input3}
                    // onKeyPress={(e) => handleKeyPress(e, input4)}
                  />
                </Box>
                <Box mb={isValid?2:4}>
                  {/* <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Your Phone"
                    className={`${classes.formInputFeild} my-field-two`}
                    name="phone"
                    value={feedbackInfo.phone}
                    size="normal"
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                    InputProps={{
                      startAdornment:  ( // Conditionally render input adornment
                        <InputAdornment position="start" style={{width:"80px"}} >
                          <TextValidator
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    select
                                    id="emailInput"
                                    placeholder="Expected by"
                                    className={` country-code`}
                                    name="expected_time_of_possession"
                                    value={countryCode}
                                    size="normal"
                                  >
                                    {country_codes.map((item, index) => {
                                      return (
                                        <MenuItem
                                          value={item}
                                          key={index}
                                          onClick={(e) =>
                                            setCountryCode(item)
                                          }
                                          style={{ textTransform: "capitalize" }}
                                          className={`${classes.code_list} all-codes`}
                                        >
                                          +{item}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextValidator>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                  {selectedCountry && <div className={`${classesTwo.new_countrycode_field} ${!isValid && classesTwo.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={feedbackInfo?.phone}
                      defaultCountry={selectedCountry}
                      // ref={input4}
                      onPhoneNumberFocus={true}
                    // onKeyPress={(e) => handleKeyPress(e, input2)}
                    />
                    {!isValid && <p className={classesTwo.err_text}>Enter Valid number</p>}
                  </div>}
                </Box>

                <Box marginTop="20px">
                  <Button
                    fullWidth
                    className={"add-services-submit"}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
                {feedSuccess && (
                  <Typography
                    className="feedback-success d-flex-center"
                    gap={"8px"}
                  >
                    <FaCircleCheck /> Feedback Added Successfully
                  </Typography>
                )}
                {feedFailure && (
                  <Typography
                    className="feedback-failure d-flex-center"
                    gap={"8px"}
                  >
                   <IoCloseCircle />  Something went wrong. Try again.
                  </Typography>
                )}
              </ValidatorForm>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default FeedbackPop;
