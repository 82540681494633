import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Grid
} from "@mui/material";
import classes from "./layout.module.css";
import Logo from "../Assets/Images/gazamLogo.png";
import {Navigate, Outlet} from 'react-router-dom'
import { MenuIcon } from "../Assets/Icons/icons";
import { useEffect } from "react";

function PropertyLayout({children,stepper}) {


  return (
    <>
     <Outlet />
    </>
  );
}

export default PropertyLayout;
