import {
    Box,
    Dialog,
    Typography,
    Button
  } from "@mui/material";
  import "./popup.css";
  import { LuAlertOctagon } from "react-icons/lu";
import { CloseIconGreen } from "../../Assets/Icons/icons";
import { MdBlockFlipped } from "react-icons/md";
  
  function BlockUserPopup(props) {
    const { open, handleClose} = props;

    function handleClosePop() {
        localStorage.clear();
        handleClose();
    }
    return (
      <>
        <Dialog
          open={open}
          onClose={handleClosePop}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="block-pop"
        >
          <Box textAlign={"center"} >
          <Typography
              sx={{ cursor: "pointer" }}
              textAlign={"end"}
              onClick={handleClosePop}
            >
              <CloseIconGreen />
            </Typography>
            <LuAlertOctagon className='alert-icon' />
            <h3>Account Blocked</h3>
            <Typography >
                Its looks like you're currently blocked by admin. You'll need to contact our support team to unblock it.
            </Typography>
            <Box className="d-flex" gap={"15px"} justifyContent={"center"} mt={6}>
                <Button className='add-services-submit'>
                    Contact Support
                </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  }
  
  export default BlockUserPopup;
  