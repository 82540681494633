import React, { useState, useRef, useEffect } from "react";
import classes from "../Pages/Auth/auth.module.css";
import { Modal, Box, Typography, Button } from "@mui/material";
import { CloseIcon, SuccessGreenChecked } from "../Assets/Icons/icons";

const ConfirmModal = ({ modalClose, heading, subHeading, callback }) => {
  const rootRef = useRef(null);
  const [modalToggle, setModalToggle] = useState(true);

  //   useEffect(() => {
  //     setTimeout(() => {
  //       callback();
  //       modalClose(false);
  //     }, 5000);
  //   }, []);

  function closeModal(data) {
    modalClose(false);
    setModalToggle(false);
  }
  return (
    <div>
      <Modal
        open={modalToggle}
        onClose={() => closeModal()}
        aria-labelledby="server-modal-title"
        data-testid="register_modal"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          top: "10%",
          height: "fit-content",
          marginTop: 10,
          justifyContent: "center",
        }}
        container={() => rootRef.current}
      >
        <Box
          sx={{
            position: "relative",
            bgcolor: "background.paper",
            borderRadius: 1,
            boxShadow: (theme) => theme.shadows[5],
          }}
          style={{ minWidth: 475, height: 275, padding: "30px" }}
          className={"loginModalWidth"}
        >
          {/* <div className={classes.forgetPasswordmodalclose}>
            <Button
              variant="text"
              sx={{ minWidth: "unset" }}
              className={"closebtn"}
              onClick={() => closeModal()}
            >
              <CloseIcon />
            </Button>
          </div> */}
          <div className={classes.modalTextContainer}>
            {/* <SuccessGreenChecked /> */}
            <Typography
              variant="h3"
              className={`${classes.successModalMainHeading}`}
            >
              {heading}
            </Typography>
            <Typography
              variant="h6"
              className={classes.successModalSubHeading}
              sx={{ textAlign: "center" }}
            >
              {subHeading}
            </Typography>
            <div className={classes.confirmationModalBtnSection}>
              <Button
                variant="outlined"
                className={classes.cancelBtn}
                onClick={modalClose}
              >
                No, Cancel
              </Button>
              <Button
                variant="contained"
                className={classes.confirmBtn}
                onClick={callback}
              >
                Yes, Confirm
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
