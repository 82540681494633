import React, { useEffect, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import GuestHeaders from "./headers";
import classes from "./layout.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { getLanguageData, pageLoader } from "../Redux/Actions/userActions";

const DefaultLayout = () => {
  const { loading, ln } = useSelector((state) => state.userLogin);
  const [errorAlert, setErrorAlert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("userInfo")) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    dispatch(pageLoader(true));
    dispatch(getLanguageData("en", false));
  }, [!ln]);

  return (
    <div>
      <div className={classes.guestHeaderParentDiv}>
        <GuestHeaders />
      </div>
      <div className={classes.guestBodyParentDiv}>
        <Outlet />
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
    </div>
  );
};

export default DefaultLayout;
