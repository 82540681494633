import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { BackIcon, WhatsAppIcon, WhatsappIcon } from "../../Assets/Icons/icons";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import classes1 from "../Auth/auth.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Checkbox,
} from "@mui/material";
import { Numeric } from "../../Utilities/commonFunctions";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import { otpVerification, resendUserOtp } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import './property.css'

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function PropertyRegisterVerify() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const RegisterDiv = () => {
    const [otp, setOtp] = useState("");
    const [newOtp, setNewOtp] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [seconds, setSeconds] = useState(10);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const reg_details = location?.state?.otp_details;

    function handleNext() {
      let userEntry = {
        username: reg_details?.phone,
        otp: otp,
      }

      dispatch(otpVerification(userEntry, navigate, "/propertyadddetails"))
    };

    function handleResendOtp() {
      let userEntry = {
        username: reg_details?.phone
      };
      dispatch(resendUserOtp(userEntry,setSeconds,setNewOtp))
    }

    useEffect(() => {
      const countdown = setInterval(() => {
        if (seconds > 0) {
          setSeconds(prevSeconds => prevSeconds - 1);
        }
      }, 1000);
      return () => clearInterval(countdown);
    }, [seconds]);
    
    return (
      <>
        <Box className={classes.register_box}>
          <Typography className={classes.role_box_header} mb={"15px"}>
            <span onClick={() => navigate(-1)}>
              <BackIcon />
            </span>
          </Typography>
          <Typography className={classes.role_box_header_register}>
            Looks like you are new to GAZAM, Continue by creating a new
            account....
          </Typography>
          <Typography className={classes.verify_num}>
            Lets Verify your number +{reg_details?.c_code}-{reg_details?.phone}
          </Typography>
          <Box mb={3}>
            <Typography className={classes.enter_otp}>
              Enter your OTP{" "} - {newOtp || reg_details?.otp}
            </Typography>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              containerStyle={classes1.otpInputContainer}
              inputStyle={classes1.otpInput}
              renderSeparator={<span> </span>}
              renderInput={(props) => <input {...props} />}
            />
            {/* <Box className={`${classes1.otpResendDiv} otp-part`} mt={1}>
              <Typography variant="p" className={classes1.SecondsText}>
                waiting for OTP ....{seconds} sec
              </Typography>
              {seconds === 0 &&
                            <Button
                            variant="text"
                            className={`${classes1.resendOtpBtn}`}
                              onClick={handleResendOtp}
                          >
                            Resend OTP
                          </Button>
              }
            </Box> */}
          </Box>
          {/* <Box mt={3}>
            <ValidatorForm
              useref="form"
              //   onSubmit={handleSubmit}
              className={classes.SignUpForm}
            >
              <TextValidator
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Your full name"
                className={`${classes.formInputFeild} my-field`}
                // value={"777777"}
                size="normal"
                // onChange={handleChange}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <TextField select>
                //         <MenuItem>+91</MenuItem>
                //         <MenuItem>+91</MenuItem>
                //       </TextField>
                //     </InputAdornment>
                //   ),
                // }}
                style={{ margin: "8px 0" }}
                onKeyPress={(e) => {
                  Numeric(e);
                }}
                validators={["required"]} // Add this line
                errorMessages={["Mobile Number is required"]}
              />
              <TextValidator
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Email address"
                className={`${classes.formInputFeild} my-field`}
                // value={"777777"}
                size="normal"
                // onChange={handleChange}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <TextField select>
                //         <MenuItem>+91</MenuItem>
                //         <MenuItem>+91</MenuItem>
                //       </TextField>
                //     </InputAdornment>
                //   ),
                // }}
                onKeyPress={(e) => {
                  Numeric(e);
                }}
                style={{ margin: "8px 0" }}
                validators={["required"]} // Add this line
                errorMessages={["Mobile Number is required"]}
              />
              <TextValidator
                variant="outlined"
                autoComplete="off"
                fullWidth
                id="emailInput"
                placeholder="Mobile number"
                className={`${classes.formInputFeild} my-field`}
                // value={"777777"}
                size="normal"
                // onChange={handleChange}
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <TextField select>
                //         <MenuItem>+91</MenuItem>
                //         <MenuItem>+91</MenuItem>
                //       </TextField>
                //     </InputAdornment>
                //   ),
                // }}
                style={{ margin: "8px 0" }}
                onKeyPress={(e) => {
                  Numeric(e);
                }}
                validators={["required"]} // Add this line
                errorMessages={["Mobile Number is required"]}
              />
            </ValidatorForm>
          </Box>
          <Box display={"flex"} alignItems={"start"} mt={1}>
            <Checkbox {...label} className={classes.checkbox} />
            <Typography className={classes.checkbox_content}>
              I agree to be contacted by GAZAM and others for similar or related
              services via <WhatsAppIcon />
              Whatsapp, phone sms, e-mail, etc. I can put out at anytime.
            </Typography>
          </Box> */}
          <Button
            fullWidth
            className={classes.continue}
            disabled={otp.length < 6}
            onClick={handleNext}
          >
            Verify & Continue
          </Button>
          <Button
            variant="outlined"
            fullWidth
            className={`mt-2 ${classes1.whatsAppBtn}`}
          >
            <WhatsappIcon />
            <span>Resend On Whatsapp</span>
          </Button>
        </Box>
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<RegisterDiv />} stepper={0} />
    </>
  );
}

export default PropertyRegisterVerify;
