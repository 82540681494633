import { Box ,Container,Popover,Typography} from "@mui/material";
import Logo from "../../Assets/Images/gazamLogo.png";
import { MenuIcon } from "../../Assets/Icons/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ConfirmModal from "../../Components/confirmModel";
import "./property.css";

function PropertyHeader() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let _name = JSON.parse(localStorage.getItem("userToken"))?.name;
  const [logoutModal, setLogoutModal] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  function handleLogout() {
    handleClose();
    localStorage.clear();
    navigate("/login");
  }

  const closeModal = () => {
    setLogoutModal(false);
  };

  return (
    <>
      <Box py={1} boxShadow={"0 0 12px rgba(0,0,0,0.3)"} >
        <Container>
        <Box className="d-flex-between">
        <img src={Logo} alt="logo" width={"150px"} onClick={()=>navigate("/homepage")} />
        <Box ml={2} sx={{ cursor: "pointer" }} onClick={handleClick}>
          <MenuIcon />
        </Box>
        <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className="myprofile-popover"
              >
                <Box p={2} >
                { !_token ? ( 
                  <>
                  <Typography
                  sx={{ padding:"10px", cursor: "pointer",fontSize:"18px",fontWeight:"600" }}
                  onClick={() => {
                    handleClose();
                    navigate("/alllistings");
                  }}
                >
                 Login / Register
                </Typography>
                <hr />
                  </>
                ):( 
                  <>
                  <Typography
                  sx={{ padding:"10px", cursor: "pointer",fontSize:"18px",fontWeight:"600",textTransform:'capitalize' }}
                  // onClick={() => {
                  //   handleClose();
                  //   navigate("/alllistings");
                  // }}
                >
                 {_name}
                </Typography>
                <hr />
                  </>
                )}
                
                <Typography
                  sx={{ padding:"10px", cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                    navigate("/alllistings");
                  }}
                >
                  My Postings
                </Typography>
                <hr />
                <Typography
                  sx={{ padding:"10px", cursor: "pointer" }}
                  onClick={() => {
                    handleClose();
                    navigate("/savedproperty");
                  }}
                >
                  My Saved Properties
                </Typography>
                {_token && (
               <>
                <hr />
                <Typography sx={{ padding:"10px", cursor: "pointer" }}>
                 
                    <span
                      onClick={() => {
                        setLogoutModal(true);
                        setAnchorEl(null);
                      }}
                    >
                      Logout
                    </span>
                 
                </Typography>
               </>
              )}
                </Box>
              </Popover>
        </Box>
        </Container>
      </Box>

      {logoutModal && (
        <ConfirmModal
          modalClose={closeModal}
          heading={"Logout Confirmation"}
          subHeading={`Are you sure want to Logout`}
          callback={handleLogout}
        />
      )}
    </>
  );
}

export default PropertyHeader;
