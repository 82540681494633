import { Box, Button, Dialog, Typography } from "@mui/material";
import { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import classes from "../property/property.module.css";
import "./popup.css";
import { addDocumentPhoto } from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";
import { IoIosCloseCircleOutline } from "react-icons/io";

const videoConstraints = {
  width: 540,
  facingMode: "environment",
};

function WebcamPop(props) {
  const { open, handleClose, setProfileImage, profileImage, handleAddPhoto } =
    props;
    const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const onUserMedia = (e) => {
    
  };

  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  } 

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setProfileImage(imageSrc);
    let up_file = dataURLtoFile(imageSrc, "image.jpg");
    let my_file = new FormData();
    my_file.append("file", up_file);
    return  dispatch(addDocumentPhoto(my_file,"photo","image","image.jpg"));

  
  }, [webcamRef]);

  function handleRetake() {
    setProfileImage(null);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="webcam-pop"
      >
        <Box>
        <Box
            className="d-flex-between popup-header"
            flexWrap={"wrap"}
          >
            <Typography fontSize={"20px"}>Take a photo</Typography>
            <IoIosCloseCircleOutline
              className={"contact-close"}
              onClick={handleClose}
            />
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"10px"}
            justifyContent={"end"}
            mb={2}
            p={3}
          >
            {profileImage ? (
              <>
                <Button
                  size="small"
                  className={"retake-btn"}
                  onClick={handleRetake}
                >
                  Retake
                </Button>
                <Button
                  size="small"
                  className={"capture-btn"}
                  onClick={handleClose}
                >
                  Upload
                </Button>
              </>
            ) : (
              <Button
                size="small"
                className={"capture-btn"}
                onClick={capturePhoto}
              >
                Capture
              </Button>
            )}
          </Box>
          <Box width={"100%"} height={"300px"} textAlign={"center"}>
            {profileImage ? (
              <img
                src={profileImage}
                alt="image"
                width={"70%"}
                height={"85%"}
              />
            ) : (
              <Webcam
                ref={webcamRef}
                audio={true}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onUserMedia={onUserMedia}
                className="webcam"
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default WebcamPop;
