import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Popover,
  Tabs,
  Tab,
  Grid,
  Chip,
  Fab,
  useScrollTrigger,
  Fade,
} from "@mui/material";
import classes from "./home.module.css";
import classesOne from "../property/property.module.css";
import BuildingOne from "../../Assets/Images/building-1.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { ChatIcon, ContactIcon, HeartIcon, WhatsappIcon } from "../../Assets/Icons/icons";
import {
  allUserWishlistProperties,
  addWishlist,
  savePropertyCount,
} from "../../Redux/Actions/userActions";
import { compareDatesWithin24Hours, formatNumber, getDecryptedCookie } from "../../Utilities/commonFunctions";
import ContactPop from "../popup/ContactPop";
import ContactOwnerPop from "../popup/ContactOwnerPop";
import GuestUserPop from "../popup/GuestUserPop";

let highlights = ["Big Parking", "Playground", "Open Balcony"];
let dummy_arr = [
  "Apartment",
  "Flat",
  "House",
  "Independent Floor",
  "Plot",
  "Duplex",
  "Penthouse",
];
let arr = [1, 2, 3, 4, 5];

function SavedProperty() {
  const [heart, setHeart] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [myProperties, setMyProperties] = useState([]);
  const [myFullProperties, setMyFullProperties] = useState([]);
  const [contactPopup, setContactPopup] = useState(false);
  const [contactPopupDetail, setContactPopupDetails] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const [getContact, setGetContact] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const [whatsAppSuccess, setWhatsAppSuccess] = useState(false);
  const [guestPopup, setGuestPopup] = useState(false);
  const [guestVerified, setGuestVerified] = useState(false);
  const [media, setMedia] = useState("");
  const [whatsappURL, setWhatsappURL] = useState("");
  const [propertySingleStore, setPropertySingleStore] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let user_id = JSON.parse(localStorage.getItem("userToken"))?.id;
  const decryptedData = getDecryptedCookie('device-infos', process.env.REACT_APP_DEVICE_KEY);

  function handleWishList() {
    dispatch(
      allUserWishlistProperties(
        _token,
        setMyProperties,
        setMyFullProperties,
        user_id
      )
    );
  }

  function handleAddWishlist(property_id) {
    dispatch(addWishlist(heart, setHeart, property_id, handleWishList));
  }

  const handleContactPopClose = () => {
    setContactPopup(false);
    setContactPopupDetails({ phone: "", email: "", name: "" });
  };

  function handleSelectCover(photos) {
    let property_photos = photos;
    let cover_img = "";


    property_photos.forEach((val)=>{
      if (val?.is_cover_image) {
        cover_img = val?.image;
      } else {
        if (cover_img === "") {
          cover_img = val?.image;
        }
      }
    })


    return `${process.env.REACT_APP_API_URL}/${cover_img}`
  };

  const handleGetContact = (e)=>{
    const {name,value} = e.target;
    setGetContact({...getContact,[name]:value});
  };

  const handleCallClicked = (datas)=>{
    setPropertySingleStore(datas);
    setContactPopupDetails({
      phone: datas?.created_user?.phone,
      email: datas?.created_user?.email,
      name: datas?.created_user?.name,
    });
    if (_token) {
      
      let save_count_body ={
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      // setContactPopup(true);
      dispatch(savePropertyCount(save_count_body,setContactPopup))
    } else if( compareDatesWithin24Hours(decryptedData?.c_date) ) {
      let save_count_body ={
        property_id: datas?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      dispatch(savePropertyCount(save_count_body,setContactPopup))
    } 
    else {
      setMedia("call");
      setGuestPopup(true);
    }
  };

  async function handleWhatsAppClicked(user_detail) {
    setPropertySingleStore(user_detail);
    let _content = `Hai ${user_detail?.created_user?.name},`;
    const prefilledMessage = await encodeURIComponent(_content);
    setWhatsappURL(`https://wa.me/${user_detail?.created_user?.phone}?text=${prefilledMessage}`);
    if (_token) {
      
      let save_count_body ={
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      dispatch(savePropertyCount(save_count_body,setWhatsAppSuccess))



    }  else if( compareDatesWithin24Hours(decryptedData?.c_date) ) {
      let save_count_body ={
        property_id: user_detail?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };
      dispatch(savePropertyCount(save_count_body,setWhatsAppSuccess))
    } else {
      setMedia("whatsapp");
      setGuestPopup(true);
    }

  }

  useEffect(()=>{
    // console.log("decryptedData =",decryptedData)
  },[decryptedData])

  useEffect(()=>{
    if (whatsAppSuccess) {
      setWhatsAppSuccess(false);
      setTimeout(()=>{
        window.open(whatsappURL, '_blank');
      },1000)
    }
  },[whatsAppSuccess])

  useEffect(()=>{
    
    if (guestVerified && media == "call") {
      setGuestVerified(false);
        setMedia("");
        // setContactPopup(true);
        let save_count_body ={
          property_id: propertySingleStore?._id,
          device_id: decryptedData?.device_id,
          type: "contact"  //contact or view
        };

        dispatch(savePropertyCount(save_count_body,setContactPopup))
    } else if (guestVerified && media == "whatsapp") {
      setGuestVerified(false);
      setMedia("");

      let save_count_body ={
        property_id: propertySingleStore?._id,
        device_id: decryptedData?.device_id,
        type: "contact"  //contact or view
      };

      dispatch(savePropertyCount(save_count_body,setWhatsAppSuccess))
      // window.open(whatsappURL, '_blank');
  } 
  },[guestVerified])

  useEffect(() => {
    handleWishList();
  }, [heart]);

  useEffect(() => {
    let copy_prop = [...myFullProperties];
    if (activeTab === "all") {
      setMyProperties(copy_prop);
    } else {
      let new_prop = copy_prop.filter(
        (el) => el.kind_of_property === activeTab
      );
      setMyProperties(new_prop);
    }
  }, [activeTab]);

  useEffect(()=>{
    localStorage.removeItem("error");
  },[]);

  useEffect(() => {
    let Con_details = JSON.parse(localStorage.getItem("userToken"));
    if (Con_details) {
      setGetContact({...getContact,name:Con_details?.name,phone:Con_details?.phone,email:Con_details?.email})
    }
  }, []);

  return (
    <>
      <Container>
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}></Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Box className={classesOne.wrapper} my={3}>
                <Typography
                  className={
                    activeTab === "all"
                      ? classesOne.active_tab
                      : classesOne.inactive_tab
                  }
                  onClick={() => setActiveTab("all")}
                >
                  All
                </Typography>
                <Typography
                  className={
                    activeTab === "Plots/lands"
                      ? classesOne.active_tab
                      : classesOne.inactive_tab
                  }
                  onClick={() => setActiveTab("Plots/lands")}
                >
                  Plots/lands
                </Typography>
                <Typography
                  className={
                    activeTab === "Residential"
                      ? classesOne.active_tab
                      : classesOne.inactive_tab
                  }
                  onClick={() => setActiveTab("Residential")}
                >
                  Residencial
                </Typography>
                <Typography
                  className={
                    activeTab === "Commercial"
                      ? classesOne.active_tab
                      : classesOne.inactive_tab
                  }
                  onClick={() => setActiveTab("Commercial")}
                >
                  Commercial
                </Typography>
                <Typography
                  className={
                    activeTab === "Agricultural/Farmlands"
                      ? classesOne.active_tab
                      : classesOne.inactive_tab
                  }
                  onClick={() => setActiveTab("Agricultural/Farmlands")}
                >
                  Farmlands
                </Typography>
              </Box>
              {myProperties.length > 0 ? (
                myProperties.map((datas, i) => (
                  <Box
                    my={3}
                    boxShadow={"0 0 7px rgba(0,0,0,0.3)"}
                    borderRadius={"8px"}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={4}>
                        <img
                          src={
                            datas.photos[0]?.image
                              ? handleSelectCover(datas.photos)
                              : "https://png.pngtree.com/png-vector/20190820/ourmid/pngtree-no-image-vector-illustration-isolated-png-image_1694547.jpg"
                          }
                          alt="image"
                          width={"100%"}
                          height={"268px"}
                          style={{objectFit:'contain'}}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={8}>
                        <Box p={2}>
                          <Box className="d-flex-between">
                            <Box>
                              <Typography
                                fontWeight={"600"}
                                fontSize={"21px"}
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/singleproperty/${datas._id}`)
                                }
                              >
                                {datas.created_user?.name}
                              </Typography>
                              <span>Plot for sale in {datas?.location}</span>
                            </Box>
                            <Typography
                              className={`${classes.active_heart} ${classes.heart_icon}`}
                              onClick={() => handleAddWishlist(datas._id)}
                            >
                              <HeartIcon />
                            </Typography>
                          </Box>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-around"}
                            mt={1}
                          >
                            <Box>
                              <Typography fontWeight={"600"} fontSize={"18px"}>
                                ₹ {formatNumber(datas.price?.expected_price)}
                              </Typography>
                              <span>Price (Negotiable)</span>
                            </Box>
                            <Typography
                              width={"1px"}
                              height={"33px"}
                              bgcolor={"rgba(0,0,0,0.4)"}
                            ></Typography>
                            <Box>
                              <Typography fontWeight={"600"} fontSize={"18px"}>
                              ₹  {datas?.rate_per_sqft?.toFixed(2)}
                              </Typography>
                              <span>Per Sqft</span>
                            </Box>
                            <Typography
                              width={"1px"}
                              height={"33px"}
                              bgcolor={"rgba(0,0,0,0.4)"}
                            ></Typography>
                            <Box>
                              <Typography fontWeight={"600"} fontSize={"18px"}>
                                {datas?.area_of_property} Sqft
                              </Typography>
                              <span>Plot Area</span>
                            </Box>
                          </Box>
                          <Typography
                            className={classes.single_property_highlights}
                          >
                            Highlights :
                            {datas.other_features.slice(0, 2).map((el) => (
                              <Chip label={el} />
                            ))}
                            <span
                              className={classes.single_property_highlights}
                            >
                              {datas.other_features.length - 2 > 0
                                ? `+${datas.other_features.length - 2}`
                                : ""}
                            </span>
                          </Typography>
                          <span>
                            {datas.property_details?.length > 150
                              ? datas.property_details
                                  ?.slice(0, 150)
                                  .concat(" ...")
                              : datas.property_details}
                          </span>
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            gap={"15px"}
                            justifyContent={{
                              xs: "start",
                              sm: "end",
                              md: "end",
                            }}
                            mt={1}
                          >
                            <Button 
                            className={classes.search_res_btn}
                            // href= {`https://wa.me/+91${datas?.created_user?.phone}`}
                            //   url="https://web.whatsapp.com/"
                            //   target="_blank"
                            onClick={()=>handleWhatsAppClicked(datas)}
                            >
                              <WhatsappIcon /> WhatsApp
                            </Button>
                            <Button className={classes.search_res_btn}>
                              <ChatIcon /> SMS
                            </Button>
                            <Button
                              className={classes.search_res_btn}
                              onClick={() =>handleCallClicked(datas)}
                            >
                              <ContactIcon /> Call
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))
              ) : (
                <Box className={classesOne.no_data}>
                  <img
                    src={
                      "https://static.vecteezy.com/system/resources/thumbnails/009/007/126/small/document-file-not-found-search-no-result-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-icon-vector.jpg"
                    }
                    alt="no-data-found"
                  />
                  <span>No Record found</span>
                  {/* <span onClick={()=>navigate("/postproperty")} >
            Click to add your first property
            </span> */}
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* <ContactOwnerPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          handleGetContact={handleGetContact}
          getContact={getContact}
        /> */}
        <ContactPop
          open={contactPopup}
          handleClose={handleContactPopClose}
          contactPopupDetail={contactPopupDetail}
          // handleGetContact={handleGetContact}
          // getContact={getContact}
        />
                {guestPopup && <GuestUserPop
          open={guestPopup}
          handleClose={()=>setGuestPopup(false)}
          media={media}
          setGuestVerified={setGuestVerified}
        />}
      </Container>
    </>
  );
}

export default SavedProperty;
