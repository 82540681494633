import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Checkbox,
  InputAdornment,
  Container,
} from "@mui/material";
import "./popup.css";
import {
  CloseIconGreen,
  ContactIcon,
  PopperEditIcon,
  PopperProfileIcon,
} from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  Numeric,
  convertPhone,
  splitFirstLetter,
} from "../../Utilities/commonFunctions";
import classes from "../property/property.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect, useState } from "react";
import { updateProfile } from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";
import { country_codes } from "../../Utilities/commonConstants";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function ProfileDetailPop(props) {
  const { open, handleClose } = props;
  const[updateSuccess,setUpdateSuccess] = useState(false);
  const[updateFailure,setUpdateFailure] = useState(false);
  const[inputs,setInputs] = useState({
  name:"",
  phone:"",
  email:""
  });
  const [countryCode, setCountryCode] = useState("91");
  const dispatch = useDispatch();
  let _token = JSON.parse(localStorage.getItem("userToken"));

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  };

  function handleUpdateProfile() {
    let userEntry = {
      ...inputs,
      country_code:countryCode
    };
    dispatch(updateProfile(_token?.id,userEntry,setUpdateSuccess,setUpdateFailure))
  };

  useEffect(() => {
    if (updateSuccess) {
      setTimeout(() => {
        setUpdateSuccess(false);
        handleClose();
      }, 2500);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateFailure) {
      setTimeout(() => {
        setUpdateFailure(false)
      }, 2500);
    }
  }, [updateFailure]);

  useEffect(()=>{
    if (_token) {
      setInputs({...inputs,
        name:_token?.name ?? "",
        phone:_token?.phone ?? "",
        email:_token?.email ?? ""
      });
      setCountryCode(_token?.country_code ?? "91" );
    }
  },[open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="profile-detail-pop"
      >
        <Box mb={3}>
          <Box className="d-flex-between popup-header">
            <Typography fontSize={"20px"}>Profile Detail</Typography>
            <span onClick={handleClose}>
              <CloseIconGreen className={"contact-close"} />
            </span>
          </Box>
          <Box textAlign={"center"} mb={3}>
            <Typography className="edit-image" >
              <PopperProfileIcon />
              <Typography className="edit-image-icon">
                      <PopperEditIcon />
                    </Typography>
            </Typography>
            <Box></Box>
          </Box>
         <Container>
         <ValidatorForm
                  useref="form"
                    onSubmit={handleUpdateProfile}
                  className={classes.SignUpForm}
                >
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Name"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"name"}
                    value={inputs.name}
                    // size="normal"
                    onChange={handleChange}
                    style={{ margin: "8px 0" }}
                    // onKeyPress={(e) => {
                    //   Numeric(e);
                    // }}
                    validators={["required"]} // Add this line
                    errorMessages={["Name is required"]}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Email"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"email"}
                    value={inputs.email}
                    // size="normal"
                    onChange={handleChange}
                    style={{ margin: "8px 0" }}
                    validators={["required", "isEmail"]} // Add this line
                    errorMessages={[
                      "Email is required",
                      "Enter Valid email ID",
                    ]}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Primary mobile number"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"phone"}
                    value={inputs.phone}
                    // size="normal"
                    onChange={handleChange}
                    style={{ margin: "8px 0" }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                    validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                    errorMessages={[
                      "Primary Mobile Number is required",
                      "Enter 10 digit valid Mobile number",
                    ]}
                    InputProps={{
                      startAdornment:  ( // Conditionally render input adornment
                        <InputAdornment position="start">
                          <TextValidator
                                    variant="outlined"
                                    autoComplete="off"
                                    fullWidth
                                    select
                                    id="emailInput"
                                    placeholder="Expected by"
                                    className={` country-code`}
                                    name="expected_time_of_possession"
                                    value={countryCode}
                                    size="normal"
                                  >
                                    {country_codes.map((item, index) => {
                                      return (
                                        <MenuItem
                                          value={item}
                                          key={index}
                                          onClick={(e) =>
                                            setCountryCode(item)
                                          }
                                          style={{ textTransform: "capitalize" }}
                                          className={`${classes.code_list} all-codes`}
                                        >
                                          +{item}
                                        </MenuItem>
                                      );
                                    })}
                                  </TextValidator>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextValidator
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    id="emailInput"
                    placeholder="Secondary mobile number"
                    className={`${classes.formInputFeild} my-field-two`}
                    name={"phone"}
                    // value={getContact.phone}
                    // size="normal"
                    // onChange={handleGetContact}
                    style={{ margin: "8px 0" }}
                    onKeyPress={(e) => {
                      Numeric(e);
                    }}
                  />
                  <Box mt={2}>
                    <Button
                      fullWidth
                      className={classes.register_btn}
                      type="submit"
                    //   onClick={handleContactOwner}
                    >
                      Save
                    </Button>
                  </Box>
                  {updateSuccess && (
                  <Typography
                    className="feedback-success d-flex-center"
                    gap={"8px"}
                  >
                    <FaCircleCheck /> Profile Updated Successfully
                  </Typography>
                )}
                {updateFailure && (
                  <Typography
                    className="feedback-failure d-flex-center"
                    gap={"8px"}
                  >
                   <IoCloseCircle />  Something went wrong. Try again.
                  </Typography>
                )}
                </ValidatorForm>
         </Container>
        </Box>
      </Dialog>
    </>
  );
}

export default ProfileDetailPop;
