import {
  Box,
  Dialog,
  Typography,
  Button,
  Grid,
  MenuItem,
  Radio,
  InputAdornment,
} from "@mui/material";
import "./popup.css";
import { CloseIconGreen, ContactIcon } from "../../Assets/Icons/icons";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import classes from "../property/property.module.css";
import classesTwo from "../Auth/auth.module.css";
import classesThree from "./popups.module.css";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  addFeedback,
  guestUserSendOtp,
  guestUserVerifyOtp,
} from "../../Redux/Actions/userActions";
import { useDispatch } from "react-redux";
import { FaCircleCheck } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";
import OtpInput from "react-otp-input";
import { getDecryptedCookie, Numeric } from "../../Utilities/commonFunctions";
import { country_codes } from "../../Utilities/commonConstants";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

function GuestUserPop(props) {
  const { open, handleClose, media, setGuestVerified } = props;

  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState();
  const [page, setPage] = useState(1);
  const [guestFailure, setGuestFailure] = useState("");
  const [guestSuccess, setGuestSuccess] = useState(false);
  const [otpDetails, setOtpDetails] = useState("");
  const [otpTimer, setOtpTimer] = useState(30);
  const [isValid, setIsValid] = useState(true);
  const [countryCode, setCountryCode] = useState("1");
  const [selectedCountry, setSelectedCountry] = useState("us");
  const dispatch = useDispatch();
  console.log("selectedCountry =",selectedCountry)
  function handlePopupClose() {
    setPhone("");
    setOtp();
    setGuestFailure("");
    setGuestSuccess(false);
    setOtpDetails("");
    setPage(1);
    setGuestVerified(true);
    setCountryCode("1");
    handleClose();
  };

  function handleSendOtp() {
    if (phone?.length === 0) {
      setIsValid(false);
    }  else if (!isValid) {
      setIsValid(false);
    } else{
    const decryptedData = getDecryptedCookie(
      "device-infos",
      process.env.REACT_APP_DEVICE_KEY
    );
    let body = { phone, device_id: decryptedData?.device_id,country_code:countryCode };
    dispatch(
      guestUserSendOtp(body, setPage, setGuestFailure, setOtpDetails, "abc")
    );
   }
  }

  function handleVerifyOtp() {
    let body = {
      phone,
      otp,
      country_code:countryCode
    };
    dispatch(guestUserVerifyOtp(body, setGuestFailure, setGuestSuccess));
  }

  function handleBack() {
    setGuestFailure("");
    setPage(1);
  }

  function handleResendOtp() {
      const decryptedData = getDecryptedCookie(
        "device-infos",
        process.env.REACT_APP_DEVICE_KEY
      );
      let body = { phone,device_id: decryptedData?.device_id,country_code:countryCode };
      dispatch(
        guestUserSendOtp(
          body,
          setOtpTimer,
          setGuestFailure,
          setOtpDetails,
          "resend"
        )
      );
  }

  const handleFlagChange = (currentNumber, countryData, fullNumber, isValid) =>{
    setSelectedCountry(countryData?.iso2);
    setPhone("");
  }

  const handlePhoneNumberChange = (isValidNum, value, country) => {
    setIsValid(isValidNum);
    setCountryCode(country?.dialCode);
    setSelectedCountry(country?.iso2);
    setPhone(value);
  };

  useEffect(() => {
    if (guestFailure) {
      setTimeout(() => {
        setGuestFailure("");
      }, 2500);
    }
  }, [guestFailure]);

  useEffect(() => {
    if (guestSuccess) {
      setTimeout(() => {
        handlePopupClose();
      }, 2500);
    }
  }, [guestSuccess]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (otpTimer > 0 && page == 2) {
        setOtpTimer(otpTimer - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [otpTimer, page]);

  console.log("otpTimer =", otpTimer);
  return (
    <>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="guest-pop"
      >
        <Box>
          <Typography
            sx={{ cursor: "pointer" }}
            textAlign={"end"}
            onClick={handleClose}
          >
            <CloseIconGreen />
          </Typography>
          {page === 1 ? (
            <Box px={2}>
              <Typography
                fontSize={"23px"}
                fontWeight={"600"}
                sx={{ color: "var(--border-colour)" }}
                mb={1}
              >
                Verify User
              </Typography>
              <Typography>Please enter your mobile number</Typography>
              <Box mt={3}>
                <ValidatorForm
                  useref="form"
                  onSubmit={handleSendOtp}
                  className={classes.SignUpForm}
                >
                  <Box mb={2}>
                    {/* <TextValidator
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      id="emailInput"
                      placeholder="Your Phone"
                      className={`${classes.formInputFeild} my-field-two`}
                      name="phone"
                      value={phone}
                      size="normal"
                      onChange={(e) => {
                        let _val = e.target.value;
                        let _sliced = _val?.slice(0, 15);
                        setPhone(_sliced);
                      }}
                      onKeyPress={(e) => {
                        Numeric(e);
                      }}
                      validators={["required", "matchRegexp:[0-9]{10}"]} // Add this line
                      errorMessages={[
                        "Mobile Number is required",
                        "Enter valid Mobile number",
                      ]}
                      InputProps={{
                        startAdornment:  ( // Conditionally render input adornment
                          <InputAdornment position="start">
                            <TextValidator
                                      variant="outlined"
                                      autoComplete="off"
                                      fullWidth
                                      select
                                      id="emailInput"
                                      placeholder="Expected by"
                                      className={` country-code`}
                                      name="expected_time_of_possession"
                                      value={countryCode}
                                      size="normal"
                                    >
                                      {country_codes.map((item, index) => {
                                        return (
                                          <MenuItem
                                            value={item}
                                            key={index}
                                            onClick={(e) =>
                                              setCountryCode(item)
                                            }
                                            style={{ textTransform: "capitalize" }}
                                            className={`${classes.code_list} all-codes`}
                                          >
                                            +{item}
                                          </MenuItem>
                                        );
                                      })}
                                    </TextValidator>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                    {selectedCountry && <div className={`${classesThree.new_countrycode_field} ${!isValid && classesThree.new_countrycode_err}`} >
                    <IntlTelInput
                      containerClassName="intl-tel-input"
                      inputClassName="form-control custom-placeholder"
                      fieldName="phone"
                      onPhoneNumberChange={handlePhoneNumberChange}
                      onSelectFlag={handleFlagChange}
                      value={phone}
                      defaultCountry={selectedCountry}
                    />
                    {!isValid && <p className={classesThree.err_text}>Enter Valid number</p>}
                  </div>}
                  </Box>

                  <Box marginTop="50px">
                    <Button
                      fullWidth
                      className={"add-services-submit"}
                      type="submit"
                    >
                      Send OTP
                    </Button>
                  </Box>
                  {guestFailure && (
                    <Typography
                      className="feedback-failure d-flex-center"
                      gap={"8px"}
                    >
                      <IoCloseCircle /> {guestFailure}
                    </Typography>
                  )}
                </ValidatorForm>
              </Box>
            </Box>
          ) : (
            <Box px={2}>
              <Typography
                fontSize={"23px"}
                fontWeight={"600"}
                sx={{ color: "var(--border-colour)" }}
                mb={1}
              >
                Verify User
              </Typography>
              <Typography>
                We have sent you a <b>6 digit </b>OTP to <b>{phone}</b>. Please
                enter the OTP below. <br /> {otpDetails}{" "}
              </Typography>
              <Box mt={3}>
                <Box mb={2}>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    containerStyle={classesTwo.otpInputContainer}
                    inputStyle={classesTwo.otpInput}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </Box>
                <Box className="d-flex-between">
                  <Typography>
                    {otpTimer !== 0 && `Resend OTP in ${otpTimer} sec`}
                  </Typography>
                  <Typography
                    onClick={handleResendOtp}
                    sx={{ color: "var(--border-colour)", cursor: "pointer" }}
                  >
                    {otpTimer === 0 && "Resend OTP"}
                  </Typography>
                </Box>

                <Box marginTop="50px" className="guest-btns">
                  <Button
                    fullWidth
                    className={"guest-user-back"}
                    onClick={handleBack}
                  >
                    back
                  </Button>
                  <Button
                    fullWidth
                    className={"add-services-submit"}
                    disabled={otp?.length != 6}
                    onClick={handleVerifyOtp}
                  >
                    Verify OTP
                  </Button>
                </Box>
                {guestFailure && (
                  <Typography
                    className="feedback-failure d-flex-center"
                    gap={"8px"}
                  >
                    <IoCloseCircle /> {guestFailure}
                  </Typography>
                )}
                {guestSuccess && (
                  <Typography
                    className="feedback-success d-flex-center"
                    gap={"8px"}
                  >
                    <FaCircleCheck /> Verified Successfully
                  </Typography>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
}

export default GuestUserPop;
