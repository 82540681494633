import { BackIcon, WhatsAppIcon } from "../../Assets/Icons/icons";
import AddImage from "../../Assets/Images/add-image.png";
import PropertyLayout from "./index";
import classes from "./property.module.css";
import classes1 from "../Auth/auth.module.css";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function PropertyAddPhotos() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const AddPhotosDiv = () => {
    const navigate = useNavigate();
    const [images, setImages] = useState([]);
    const [payloadImgs, setPayloadImgs] = useState([]);

    function handleNext() {
      navigate("/propertyuploadphotos",{state:{images}});
    }

    function handleImage(e) {
      let my_image = e?.target?.files[0];
      setPayloadImgs([...payloadImgs, my_image]);
      // Preview the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setImages([...images, reader.result]);
      };
      reader.readAsDataURL(my_image);
    }
   
    return (
      <>
        <Box className={classes.photos_box}>
          <Typography className={classes.role_box_header} mb={"25px"}>
            <span onClick={() => navigate(-1)}>
              <BackIcon />
            </span>{" "}
            Add Property Details
          </Typography>
          <Box>
            <Container>
              <Typography className={classes.my_role}>
                Add property photos
              </Typography>
              <Box className={classes.add_photo_wrapper}>
                <img src={AddImage} alt="AddImage" />
                <Typography
                  sx={{ color: "var(--border-colour)", margin: "7px 0" }}
                >
                  + Add atleast 5 photos
                </Typography>
                <Typography
                  className={classes1.SecondsText}
                  textAlign={"center"}
                  mb={4}
                >
                  click from camera or browse to upload <br />
                  Upload 50 Photos with max size 10 mb in format jpg, jpeg and
                  png
                </Typography>
                <input
                  type="file"
                  id="actual-btn"
                  onChange={(e) => handleImage(e)}
                  hidden
                />
                <label for="actual-btn" className={classes.upload_btn}>
                  Choose File
                </label>
                {/* <Button  >Upload Photos</Button> */}
              </Box>
              <Box className={classes.radio_wrapper}>
                <Radio
                  //   checked={selectedValue === "b"}
                  //   onChange={handleChange}
                  //   value="b"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                />
                I will send photo over whatsapp <WhatsAppIcon />
              </Box>
              <Button
                fullWidth
                className={classes.continue}
                onClick={handleNext}
              >
                Next
              </Button>
            </Container>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <PropertyLayout children={<AddPhotosDiv />} stepper={1} />
    </>
  );
}

export default PropertyAddPhotos;
