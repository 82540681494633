import {
  Box,
  Container,
  Typography,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  Popover,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ClickAwayListener,
  Chip,
} from "@mui/material";
import Logo from "../../Assets/Images/gazamLogo.png";
import classes from "./home.module.css";
import "./home.css";
import {
  ExpandMoreIcon,
  LocationIcon,
  MenuIcon,
  NotificationIcon,
  PopperCategoryIcon,
  PopperEditIcon,
  PopperHelpFiveIcon,
  PopperHelpFourIcon,
  PopperHelpIcon,
  PopperHelpOneIcon,
  PopperHelpSixIcon,
  PopperHelpThreeIcon,
  PopperHelpTwoIcon,
  PopperLogoutIcon,
  PopperNewProjectIcon,
  PopperNewsIcon,
  PopperNriIcon,
  PopperPlanFiveIcon,
  PopperPlanFourIcon,
  PopperPlanOneIcon,
  PopperPlanThreeIcon,
  PopperPlanTwoIcon,
  PopperPlansIcon,
  PopperPostAdIcon,
  PopperProfileIcon,
  PopperSearchIcon,
  PopperServiceFiveIcon,
  PopperServiceFourIcon,
  PopperServiceOneIcon,
  PopperServiceSixIcon,
  PopperServiceThreeIcon,
  PopperServiceTwoIcon,
  PopperServicesIcon,
  PopperSettingIcon,
  PopperSettingsFourIcon,
  PopperSettingsOneIcon,
  PopperSettingsThreeIcon,
  PopperSettingsTwoIcon,
  PopperWatsAppIcon,
  SearchIcon,
} from "../../Assets/Icons/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  getAllValues,
  deleteUserNotifications,
  userNotifications,
} from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../Components/confirmModel";
import { RiMenu2Line } from "react-icons/ri";
import NotificationPop from "../popup/NotificationPop";
import { IoIosCloseCircleOutline } from "react-icons/io";
import {
  LoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
  Autocomplete as GoogleAutocomplete,
} from "@react-google-maps/api";
import { FILTER_DETAILS, INITIAL_LOC } from "../../Redux/Constants/userConstants";
import ProfileDetailPop from "../popup/ProfileDetailPop";
import ChangeLanguagePop from "../popup/ChangeLanguagePop";
import FeedbackPop from "../popup/FeedbackPop";
import { IoIosCloseCircle } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Cookies from 'js-cookie';

let initial_locs = [
  { loc: "Hyderabad", lat: 17.406498, lng: 78.47724389999999 },
  { loc: "Bengaluru", lat: 12.9715987, lng: 77.5945627},
];

let services_icons = [
  <PopperServiceOneIcon />,
  <PopperServiceTwoIcon />,
  <PopperServiceThreeIcon />,
  <PopperServiceFourIcon />,
  <PopperServiceFiveIcon />,
  <PopperServiceSixIcon />,
  <PopperServiceOneIcon />,
  <PopperNriIcon />
];

function Navbar({userStatus}) {
  const [notificationPopup, setNotificationPopup] = useState(false);
  const [profileDetailPopup, setProfileDetailPopup] = useState(false);
  const [languagePopup, setLanguagePopup] = useState(false);
  const [feedbackPopup, setFeedbackPopup] = useState(false);

  const [sidebar, setSidebar] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isInitialLoc, setIsInitialLoc] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let _token = JSON.parse(localStorage.getItem("userToken"))?.token;
  let _name = JSON.parse(localStorage.getItem("userToken"))?.name;
  let _email = JSON.parse(localStorage.getItem("userToken"))?.email;
  let _phone = JSON.parse(localStorage.getItem("userToken"))?.phone;
  let user_id = JSON.parse(localStorage.getItem("userToken"))?.id;
  const [expanded, setExpanded] = useState("");
  const [logoutModal, setLogoutModal] = useState(false);
  const [location, setLocation] = useState("Hyderabad");
  const [userCoordinates, setUserCoordinates] = useState(null);
  const { ln, servicesList } = useSelector((state) => state.userLogin);
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const [inputValueTwo, setInputValueTwo] = useState("");
  const [autocompleteTwo, setAutocompleteTwo] = useState(null);
  const [searchCoordinatesTwo, setSearchCoordinatesTwo] = useState({ lat:17.406498, lng:78.47724389999999 });
  const inputRefTwo = useRef(null);
  const [searchCoordinates, setSearchCoordinates] = useState(null);
  const my_params = useParams();
  const containerRef = useRef(null);
  const suggestionContainerRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpanded("");
  };

  const handleProfileDetailClose = () => {
    setProfileDetailPopup(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function handleLogout() {
    handleClose();
    localStorage.clear();
    Cookies.remove("device-infos");
    navigate("/login");
  }

  const closeModal = () => {
    setLogoutModal(false);
  };

  function handleClodeFeedback() {
    setFeedbackPopup(false);
  }
  console.log("location 164 =",location)
  const getLocationName = (latitude, longitude) => {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log("data = 171 = ",data)
        const state = data.address.state_district || data.address.state;
        setLocation(state);
        setUserCoordinates({ lat: latitude, lng: longitude });
        dispatch({
          type: INITIAL_LOC,
          payload: {location:state,lat:latitude,lng:longitude}
      })
      })
      .catch((error) => {
        console.log(`Error getting location: ${error.message}`);
      });
  };

  const DrawerList = (
    <Box p={5} pl={2} position={"relative"}>
      <Box width={{ xs: "150px", sm: "120px", md: "150px" }}>
        <img
          src={Logo}
          alt="logo"
          width={"100%"}
          onClick={() => navigate("/homepage")}
        />
      </Box>
      {/* <Typography fontSize={"20px"} mt={3}>Welcome <b style={{fontSize:"25px"}}>Prakash</b></Typography> */}
      <Typography fontSize={"22px"} fontWeight={"600"} my={2}>
        NRI Services
      </Typography>
      <Typography
        fontSize={"22px"}
        fontWeight={"600"}
        my={2}
        onClick={() => {
          setSidebar(false);
          navigate("/plans");
        }}
      >
        Plans
      </Typography>
      <Typography
        className={`${classes.post_property} ${classes.post_property_animi}`}
        onClick={() => {
          setSidebar(false);
          dispatch(getAllValues());
          navigate("/postproperty");
        }}
      >
        Post Property <b>Free</b>
      </Typography>
      <Button className={classes.download} sx={{ marginTop: "12px" }}>
        Download App
      </Button>
      <Button
        className={classes.download}
        sx={{ marginTop: "12px" }}
        onClick={() => setLanguagePopup(true)}
      >
        Change Language
      </Button>
      {/* <TextField
        select
        value={location}
        className="location-select"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LocationIcon />
            </InputAdornment>
          ),
        }}
      >
        <MenuItem value="select">Select Location</MenuItem>
        <MenuItem value="coimbatore">Coimbatore</MenuItem>
        <MenuItem value="erode">Erode</MenuItem>
        <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
      </TextField> */}
      <IoIosCloseCircleOutline
        style={{
          position: "absolute",
          top: "20px",
          right: "30px",
          fontSize: "33px",
          cursor: "pointer",
        }}
        onClick={() => setSidebar(false)}
      />
    </Box>
  );

  function handleUserNotificatios() {
    if (user_id) {
      dispatch(userNotifications(user_id, _token, setNotifications));
    }
  }

  function handleUpdateNotification(_id) {
    let userEntry = {
      seen: true,
    };
    dispatch(deleteUserNotifications(_id, handleUserNotificatios));
  }

  function handleLocChipClick(el) {
    setInputValueTwo(el?.loc)
    getLocationName(el?.lat,el?.lng);
  }

  const handleLoadTwo = (autocompleteInstance) => {
    console.log("autocompleteInstance =",autocompleteInstance?.gm_accessors_?.place)
    setAutocompleteTwo(autocompleteInstance);

    const container = document.querySelector('.pac-container');
    if (container) {
      suggestionContainerRef.current = container; // Store the reference
      console.log('Suggestion Container:', container);
    }
  };

  function handlePlaceChangedTwo() {
    if (autocompleteTwo !== null) {
      const place = autocompleteTwo?.getPlace();
      if (place && place.formatted_address) {
        setInputValueTwo(place.formatted_address);
        const location = place?.geometry?.location;
        if (location) {
          const lat = location?.lat();
          const lng = location?.lng();
          setSearchCoordinatesTwo({ lat, lng });
          getLocationName(lat,lng);
        } else {
          alert("Try again");
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  function handlePlaceChanged() {
    if (autocomplete !== null) {
      const place = autocomplete?.getPlace();
      if (place && place.formatted_address) {
        setIsInitialLoc(true);
        setInputValue(place.formatted_address);
        const location = place?.geometry?.location;
        if (location) {
          const lat = location?.lat();
          const lng = location?.lng();
          setSearchCoordinates({ lat, lng });
        } else {
          alert("Try again");
        }
      }
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  }

  const handleAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleLocSearchClose() {
    setIsInitialLoc(false);
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target) ) {
      setIsInitialLoc(false);
    }
  };
  
  function handleNotificationNums() {
    if (notifications) {
      let all_notifications = [...notifications];

      let unseen_notifications = all_notifications?.filter((el)=>el.seen === false);

      return unseen_notifications?.length;
      
    } else {
      return 0;
    }

  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (autocompleteTwo && inputRefTwo.current) {
      autocompleteTwo.addListener("place_changed", handlePlaceChangedTwo);
    }

    return () => {
      if (autocompleteTwo && inputRefTwo.current) {
        window?.google.maps.event.clearInstanceListeners(autocompleteTwo);
      }
    };
  }, [autocompleteTwo]);

  useEffect(() => {
    if (autocomplete && inputRef.current) {
      autocomplete.addListener("place_changed", handlePlaceChanged);
    }

    return () => {
      if (autocomplete && inputRef.current) {
        window?.google.maps.event.clearInstanceListeners(autocomplete);
      }
    };
  }, [autocomplete]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      getLocationName(
        17.406498,
        78.47724389999999
      );
      navigator.geolocation.getCurrentPosition(function (position) {
      console.log("test12345 =")

        getLocationName(
          position?.coords?.latitude,
          position?.coords?.longitude
        );
      });
    } else {
      getLocationName(
        17.406498,
        78.47724389999999
      );
      alert("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    if (window.location.pathname.includes("results")) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    let searched_val = localStorage.getItem("search-input");
    setInputValue(searched_val ?? "");
    handleUserNotificatios();
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      window.location.pathname.includes("results") ||
      window.location.pathname.includes("singleproperty")
    ) {
    } else {
      localStorage.removeItem("search-input");
      dispatch({
        type: FILTER_DETAILS,
        payload: null,
      });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (inputValue.length === 0) {
      // setSearchCoordinates(null);
    }
  }, [inputValue]);

  useEffect(() => {
    if (
      my_params?.id !== "allproperties" &&
      !window.location.pathname.includes("results") &&
      !window.location.pathname.includes("singleproperty")
    ) {
      let my_lat = my_params?.id?.split(",");

      if (my_lat) {
        setSearchCoordinates({ lat: my_lat[0], lng: my_lat[1] });
      }
    } else {
      setSearchCoordinates(null);
    }
  }, []);

  return (
    <Box position={"sticky"} zIndex={"1"} top={"0"} bgcolor={"#fff"}>
      <Box
        py={1}
        boxShadow={"0 0 12px rgba(0,0,0,0.3)"}
        position={"relative"}
        zIndex={"1"}
      >
        <Container>
          <Box className="d-flex-between">
            <Box className="d-flex">
              <Typography
                onClick={() => setSidebar(true)}
                className={classes.nav_menubar}
                mr={2}
                display={{ xs: "block", sm: "block", md: "none" }}
              >
                <RiMenu2Line />
              </Typography>
              <Box width={{ xs: "106px", sm: "120px", md: "150px" }}>
                <img
                  src={Logo}
                  alt="logo"
                  width={"100%"}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate("/homepage")}
                />
              </Box>
              <Box display={{ xs: "none", sm: "none", md: "block" }} ml={2}>
                {!isSearch && location && (
                  <Box position={"relative"} py={1}>
                    <Typography
                      sx={{
                        fontSize: "17px",
                        gap: "4px",
                        cursor:"pointer"
                      }}
                      className="d-flex"
                      onClick={()=>setIsInitialLoc(!isInitialLoc)}
                      // onMouseEnter={() => setIsInitialLoc(true)}
                      // onMouseLeave={() => setIsInitialLoc(false)}
                    >
                      <LocationIcon /> {location} {isInitialLoc? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </Typography>
                    {isInitialLoc && (
                      <Box className={classes.search_initail_loc} data-aos="fade-up" ref={containerRef}>
                       <h5>Explore properties in...</h5>
                        <IoIosCloseCircle className={classes.loc_search_close} onClick={handleLocSearchClose} />
                        <GoogleAutocomplete
                          onLoad={handleLoadTwo}
                          onPlaceChanged={handlePlaceChangedTwo}
                        >
                          <TextField
                            inputRef={inputRefTwo}
                            value={inputValueTwo}
                            onChange={(e) => setInputValueTwo(e.target.value)}
                            placeholder="Search location"
                            className="search-field-loc"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </GoogleAutocomplete>
                        <hr />
                        <Box className={classes.filter_preview} >
                {initial_locs?.map((el,i)=>(
                  
                <Chip
                  label={el.loc}
                  onClick={()=>handleLocChipClick(el)}
                  // onDelete={()=>handleDeletePreview(el)}
                  key={i}
                />
               
                ))}

                </Box>
                      </Box>
                    )}
                  </Box>

                  // <TextField
                  //   select
                  //   value={location}
                  //   className="location-select"
                  //   InputProps={{
                  //     startAdornment: (
                  //       <InputAdornment position="start">
                  //         <LocationIcon />
                  //       </InputAdornment>
                  //     ),
                  //   }}
                  // >
                  //   <MenuItem value="select">Select Location</MenuItem>
                  //   <MenuItem value="coimbatore">Coimbatore</MenuItem>
                  //   <MenuItem value="erode">Erode</MenuItem>
                  //   <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                  // </TextField>
                )}
              </Box>
            </Box>
            {isSearch && (
              <Box
                className={classes.navbar_search_part}
                display={{ xs: "none", sm: "none", md: "flex" }}
              >
                <GoogleAutocomplete
                  onLoad={handleLoad}
                  onPlaceChanged={handlePlaceChanged}
                >
                  <TextField
                    inputRef={inputRef}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    placeholder="Search for locality, landmark, project, or builder "
                    className="search-field"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GoogleAutocomplete>
                <Button
                  size="small"
                  disabled={!searchCoordinates}
                  onClick={() => {
                    if (inputValue.length < 1) {
                      alert("Please enter location");
                    } else {
                      localStorage.setItem("search-input", inputValue);
                      navigate(
                        `/search-results/${searchCoordinates?.lat},${searchCoordinates?.lng}`
                      );
                    }
                  }}
                  className={classes.search_btn_small}
                >
                  Search
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    localStorage.removeItem("search-input");
                    if (my_params?.id !== "allproperties") {
                      navigate(`/search-results/allproperties`);
                    }
                  }}
                  className={classes.clear_btn_small}
                >
                  Reset
                </Button>
              </Box>
            )}
            <Box className="d-flex">
              <Typography
                className={classes.post_property}
                onClick={() => {
                  dispatch(getAllValues());
                  navigate("/postproperty");
                }}
                display={{ xs: "none", sm: "none", md: "block" }}
              >
                {ln && ln["post_property"]
                  ? ln["post_property"]
                  : "Post Property"}{" "}
                <b> {ln && ln["free"] ? ln["free"] : "Free"}</b>
              </Typography>
              {!isSearch && (
                <Button
                  className={classes.download}
                  sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                >
                  {ln && ln["download_app"]
                    ? ln["download_app"]
                    : "Download App"}
                </Button>
              )}
              <Box
                sx={{ cursor: "pointer" }}
                ml={2}
                position={"relative"}
                onClick={() => setNotificationPopup(true)}
              >
                <NotificationIcon />
                <span className={`${classes.notification_count}`}>
                  {handleNotificationNums() > 0 ? handleNotificationNums() : ""}
                </span>
              </Box>
              <Box
                ml={2}
                sx={{ cursor: "pointer" }}
                onClick={handleClick}
                display={{ xs: "block", sm: "block", md: "block" }}
              >
                <MenuIcon />
              </Box>

              {_token ? (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className="myprofile-popover"
                >
                  <Box py={2} px={"10px"}>
                    <Box className={classes.popper_profile_details}>
                      <Box className="d-flex" gap="10px">
                        <Typography>
                          <PopperProfileIcon />
                        </Typography>
                        <Box>
                          <Typography>
                            <b>{_name}</b>
                          </Typography>
                          <Typography>{_phone}</Typography>
                          <Typography>{_email}</Typography>
                        </Box>
                      </Box>
                      <Typography
                        sx={{ cursor: "pointer" }}
                        onClick={() => setProfileDetailPopup(true)}
                      >
                        <PopperEditIcon />
                      </Typography>
                    </Box>
                    <Box className={classes.popper_count}>
                      <Box
                        borderRight={"1px solid #fff"}
                        className="d-flex-center"
                      >
                        {userStatus?.viewPropertyCount}
                        <span>Viewed</span>
                      </Box>
                      <Box
                        borderRight={"1px solid #fff"}
                        className="d-flex-center"
                      >
                        {userStatus?.wishlistPropertyCount}
                        <span>Shortlisted</span>
                      </Box>
                      <Box className="d-flex-center">
                      {userStatus?.contactPropertyCount}
                        <span>Contacted</span>
                      </Box>
                    </Box>
                    <Box p={2} className="popper-accordian">
                      <Typography
                        className={classes.popper_fields}
                        onClick={() => {
                          handleClose();
                          navigate("/alllistings");
                        }}
                      >
                        <PopperPostAdIcon />
                        <span>My Properties</span>
                      </Typography>
                      <hr />
                      <Typography
                        className={classes.popper_fields}
                        onClick={() => {
                          handleClose();
                          navigate("/savedproperty");
                        }}
                      >
                        <PopperPostAdIcon />
                        <span>Saved Properties</span>
                      </Typography>
                      <hr />
                      <Typography
                        className={classes.popper_fields}
                        onClick={() => {
                          handleClose();
                          navigate("/postproperty");
                        }}
                      >
                        <PopperPostAdIcon />
                        <span>Post Property</span>
                      </Typography>
                      <hr />
                      <Typography className={classes.popper_fields}>
                        <PopperWatsAppIcon />
                        <span>Post property via whatsapp</span>
                      </Typography>
                      <hr />
                      <Typography
                        className={classes.popper_fields}
                        onClick={() => {
                          handleClose();
                          navigate("/search-results/allproperties");
                        }}
                      >
                        <PopperSearchIcon />
                        <span>Search Properties</span>
                      </Typography>
                      <hr />
                      <Typography className={classes.popper_fields}>
                        <PopperNewProjectIcon />
                        <span>New Project</span>
                      </Typography>
                      <hr />
                      <Typography className={classes.popper_fields}>
                        <PopperCategoryIcon />
                        <span>Property by Category</span>
                      </Typography>
                      <hr />
                      <Typography className={classes.popper_fields}>
                        <PopperNriIcon />
                        <span>NRI Services</span>
                      </Typography>
                      <hr />
                      <Typography
                        className={classes.popper_fields}
                        onClick={() => {
                          handleClose();
                          navigate("/news");
                        }}
                      >
                        <PopperNewsIcon />
                        <span>Articles & News</span>
                      </Typography>
                      <hr />
                      <Typography
                        className={classes.popper_fields}
                        onClick={() => {
                          handleClose();
                          navigate("/plans");
                        }}
                      >
                        <PopperPlansIcon />
                        <span>Plans</span>
                      </Typography>
                      <hr />
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleAccordian("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1d-content"
                          id="panel1d-header"
                          style={{
                            minHeight: "40px",
                            height: "56px",
                            padding: "0px",
                          }}
                        >
                          <Typography className={classes.popper_fields}>
                            <PopperServicesIcon />
                            <span>Services</span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {Array.isArray(servicesList) &&
                            servicesList?.map((datas, i) => (
                              <Typography
                                className={classes.popper_fields}
                                onClick={() => {
                                  navigate(`/nri-services/${datas?._id}`);
                                }}
                              >
                                {services_icons[i]}
                                <span>{datas?.name}</span>
                              </Typography>
                            ))}
                          {/* <Typography className={classes.popper_fields}>
                            <PopperServiceOneIcon />
                            <span>Legal Opinion</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperServiceTwoIcon />
                            <span>Land Survey</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperServiceThreeIcon />
                            <span>Building Permissions</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperServiceFourIcon />
                            <span>Land monitoring services</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperServiceFiveIcon />
                            <span>Layout Approvals</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperServiceSixIcon />
                            <span>Land Development services</span>
                          </Typography> */}
                        </AccordionDetails>
                      </Accordion>
                      <hr style={{ height: "2px" }} />
                      {/* <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleAccordian("panel2")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                          style={{
                            minHeight: "40px",
                            height: "56px",
                            padding: "0px",
                          }}
                        >
                          <Typography className={classes.popper_fields}>
                            <PopperPlansIcon />
                            <span>Plans</span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className={classes.popper_fields}>
                            <PopperPlanOneIcon />
                            <span>Land/plot</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperPlanTwoIcon />
                            <span>Residential Plans</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperPlanThreeIcon />
                            <span>Commercial Plans</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperPlanFourIcon />
                            <span>Agricultural/Farmland</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperPlanFiveIcon />
                            <span>New Project</span>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <hr style={{ height: "2px" }} /> */}
                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={handleAccordian("panel3")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3d-content"
                          id="panel3d-header"
                          style={{
                            minHeight: "40px",
                            height: "56px",
                            padding: "0px",
                          }}
                        >
                          <Typography className={classes.popper_fields}>
                            <PopperHelpIcon />
                            <span>Help & Support</span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            className={classes.popper_fields}
                            onClick={() => {
                              handleClose();
                              navigate("/terms-of-use");
                            }}
                          >
                            <PopperHelpOneIcon />
                            <span>Terms of use</span>
                          </Typography>
                          <Typography
                            className={classes.popper_fields}
                            onClick={() => {
                              handleClose();
                              navigate("/privacy-policy");
                            }}
                          >
                            <PopperHelpTwoIcon />
                            <span>Privacy Policy</span>
                          </Typography>
                          <Typography
                            className={classes.popper_fields}
                            onClick={() => {
                              handleClose();
                              navigate("/about");
                            }}
                          >
                            <PopperHelpThreeIcon />
                            <span>About Us</span>
                          </Typography>
                          <Typography
                            className={classes.popper_fields}
                            onClick={() => {
                              handleClose();
                              navigate("/connect-withus");
                            }}
                          >
                            <PopperHelpFourIcon />
                            <span>Connect with us</span>
                          </Typography>
                          <Typography
                            className={classes.popper_fields}
                            onClick={() => {
                              setFeedbackPopup(true);
                              handleClose();
                            }}
                          >
                            <PopperHelpFiveIcon />
                            <span>Share feedback</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperHelpSixIcon />
                            <span>Rate our App</span>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <hr style={{ height: "2px" }} />
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={handleAccordian("panel4")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel4d-content"
                          id="panel4d-header"
                          style={{
                            minHeight: "40px",
                            height: "56px",
                            padding: "0px",
                          }}
                        >
                          <Typography className={classes.popper_fields}>
                            <PopperSettingIcon />
                            <span>Settings</span>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography className={classes.popper_fields}>
                            <PopperSettingsOneIcon />
                            <span>Manage Notifications</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperSettingsTwoIcon />
                            <span>Clear search History</span>
                          </Typography>
                          <Typography className={classes.popper_fields}>
                            <PopperSettingsThreeIcon />
                            <span>Clear your Favourites</span>
                          </Typography>
                          <Typography
                            className={classes.popper_fields}
                            onClick={() => setLanguagePopup(true)}
                          >
                            <PopperSettingsFourIcon />
                            <span>Change Language</span>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <hr style={{ height: "2px" }} />
                      <Typography
                        className={classes.popper_fields}
                        onClick={() => {
                          setLogoutModal(true);
                          setAnchorEl(null);
                        }}
                      >
                        <PopperLogoutIcon />
                        <span>Log Out</span>
                      </Typography>
                    </Box>
                  </Box>
                </Popover>
              ) : (
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className="myprofile-popover"
                >
                  <Box p={2}>
                    {!_token ? (
                      <>
                        <Typography
                          sx={{
                            padding: "10px",
                            cursor: "pointer",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                          onClick={() => {
                            handleClose();
                            navigate("/login");
                          }}
                        >
                          Login / Register
                        </Typography>
                        <hr />
                      </>
                    ) : (
                      <>
                        <Typography
                          sx={{
                            padding: "10px",
                            cursor: "pointer",
                            fontSize: "18px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                          // onClick={() => {
                          //   handleClose();
                          //   navigate("/alllistings");
                          // }}
                        >
                          {_name}
                        </Typography>
                        <hr />
                      </>
                    )}

                    {/* <Typography
                      sx={{ padding: "10px", cursor: "pointer" }}
                      onClick={() => {
                        handleClose();
                        navigate("/login");
                      }}
                    >
                      My Postings
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ padding: "10px", cursor: "pointer" }}
                      onClick={() => {
                        handleClose();
                        navigate("/login");
                      }}
                    >
                      My Saved Properties
                    </Typography>
                    <hr /> */}
                    <Typography
                      sx={{ padding: "10px", cursor: "pointer" }}
                      onClick={() => {
                        handleClose();
                        navigate("/plans");
                      }}
                    >
                      Plans
                    </Typography>
                    <hr />
                    <Typography
                      sx={{ padding: "10px", cursor: "pointer" }}
                      onClick={() => {
                        handleClose();
                        navigate("/language-select");
                      }}
                    >
                      Change Language
                    </Typography>
                    {_token && (
                      <>
                        <hr />
                        <Typography sx={{ padding: "10px", cursor: "pointer" }}>
                          <span
                            onClick={() => {
                              setLogoutModal(true);
                              setAnchorEl(null);
                            }}
                          >
                            Logout
                          </span>
                        </Typography>
                      </>
                    )}
                  </Box>
                </Popover>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      {logoutModal && (
        <ConfirmModal
          modalClose={closeModal}
          heading={"Logout Confirmation"}
          subHeading={`Are you sure want to Logout`}
          callback={handleLogout}
        />
      )}
      <Drawer open={sidebar} onClose={() => setSidebar(false)}>
        {DrawerList}
      </Drawer>
      <NotificationPop
        open={notificationPopup}
        handleClose={() => setNotificationPopup(false)}
        notifications={notifications}
        handleUpdateNotification={handleUpdateNotification}
        handleUserNotificatios={handleUserNotificatios}
      />
      <ProfileDetailPop
        open={profileDetailPopup}
        handleClose={handleProfileDetailClose}
      />
      <ChangeLanguagePop
        open={languagePopup}
        handleClose={() => setLanguagePopup(false)}
      />
      <FeedbackPop open={feedbackPopup} handleClose={handleClodeFeedback} />
    </Box>
  );
}

export default Navbar;
